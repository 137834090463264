import createStore from './store/'

document.addEventListener('DOMContentLoaded', function() {
    const store = createStore({})

    if (module.hot) {
        module.hot.accept(['./app'], () => {
            require('./app').default(store);
        });
    }
    require('./app').default(store);
});
