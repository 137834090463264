<template>
<v-dialog v-model="dialog" width="600">
    <v-card>
        <v-card-title class="headline primary lighten-1" primary-title>
            <v-icon large style="color: white" class="pr-3">
                add
            </v-icon>
            <span style="color: white">
              Kita auswählen
          </span>
            <v-spacer></v-spacer>
            <Button large icon @click="close">
                <v-icon color="white">
                    clear
                </v-icon>
            </Button>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="model"
            :items="einrichtungenFiltered"
            item-text="name"
            item-value="id"
            persistent-hint
            prepend-icon="search"
            no-data-text="Keine Einrichtung mit diesem Namen gefunden"
            class="ma-5"
          />
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <Button
              raised
              color="primary"
              @click="confirm"
              :disabled="!model">
                An diese Kita zuteilen
            </Button>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "add-kita-dialog",
    components: {
      Button
    },
    data() {
        return {
            dialog: false,
            einrichtungen: this.$select('bedarfsAnmeldung.einrichtungen as einrichtungen'),
            einrichtungenFiltered: [],
            model: null
        }
    },
    methods: {
      open(priorities) {
        if(priorities){
          let c = [];
          priorities.forEach(p => {
              c.push(p.id);
          })
          this.einrichtungenFiltered = this.einrichtungen.filter(x => !c.includes(x.id));
        };
        this.dialog = true;
      },
      close() {
          this.dialog = false;
      },
      confirm() {
          let index = this.einrichtungen.findIndex(x => x.id === this.model);
          if(index >= 0){
            this.$emit('kitaSelected', this.einrichtungen[index]);
          }
          this.close();
      }
    }
}
</script>
