import {
  noop,
  compareLexicographical
        } from '../../util/helper'
import {
  GETepicFactory,
  POSTepicFactory,
  DELETEepicFactory,
  PUTepicFactory,
  mergeEpicFactory
} from '../../util/epicFactories';

const prefix = `IMAGE`;

export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const RESET_STORE = `${prefix}/RESET_STORE`;
export const FETCH_IMAGES = `${prefix}/FETCH_IMAGES`;
export const REMOVE_IMAGE = `${prefix}/REMOVE_IMAGE`;
export const ADD_IMAGE = `${prefix}/ADD_IMAGE`;
export const DELETE_IMAGE = `${prefix}/DELETE_IMAGE`;
export const CHANGE_IMAGE = `${prefix}/CHANGE_IMAGE`;
export const SAVE_IMAGE = `${prefix}/SAVE_IMAGE`;


export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/names`
})
export const fetchImageEpic = GETepicFactory(`${prefix}/FETCH_IMAGES`, {
    url: (action) => `/api/v1/kita/einrichtungen/${action.payload.id}/bilder`
})
export const deleteImageEpic = DELETEepicFactory(`${prefix}/DELETE_IMAGE`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/${action.payload.einrichtung}/bilder/${action.payload.id}`
}, () => true, 204);
export const changeImageEpic = PUTepicFactory(`${prefix}/CHANGE_IMAGE`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/${action.payload.payload.einrichtung}/bilder`,
    body: (action) => action.payload.image
}, () => true, 204);
export const saveImageEpic = POSTepicFactory(`${prefix}/SAVE_IMAGE`, {
    url: (action) => {
      return `/api/v1/kita/admin/einrichtungen/${action.payload.payload.einrichtung}/bilder`;
    },
    body: (action) => action.payload.image
}, () => true, 201);


export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});
export const resetStore = () => dispatch => {
  dispatch({
    type: RESET_STORE
  });
  return Promise.resolve();
};
export const fetchImages = (id, success = noop, error = noop) => fetchImageEpic.run({
    id: id
}, {
    success,
    error
});
export const removeImage = (index, id = null) => dispatch => {
    dispatch({
        type: REMOVE_IMAGE,
        payload: {
            index: index,
            id: id
        }
    });
    return Promise.resolve();
}
export const addImage = (mimeType, imageBytes, alt, license) => dispatch => {
    dispatch({
        type: ADD_IMAGE,
        payload: {
            mimeType: mimeType,
            imageBytes: imageBytes,
            alt: alt,
            license: license
        }
    });
    return Promise.resolve();
}
export const deleteImage = (einrichtung, id, headerKey, headerVal, success = noop, error = noop) => deleteImageEpic.run(
  {
    id: id,
    einrichtung: einrichtung,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const changeImage = (einrichtung, image, headerKey, headerVal, success = noop, error = noop) => changeImageEpic.run(
  {
    payload: {
      einrichtung: einrichtung,
      image: image
    },
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const saveImage = (payload, headerKey, headerVal, success = noop, error = noop) => saveImageEpic.run(
  {
    payload: {
      ...payload
    },
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});


const ACTION_HANDLERS = {
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: false
      }
  },
  [RESET_STORE]: (state, action) => {
      return {
        ...initialState,
        einrichtungen: state.einrichtungen
      }
  },
  [changeImageEpic.START]: (state, action) => {
    let newImages = JSON.parse(JSON.stringify(state.images));
    let index = newImages.findIndex(x => x.id === action.payload.payload.image.id);
    if(index >= 0){
      newImages[index].alt = action.payload.payload.image.alt;
      newImages[index].license = action.payload.payload.image.license;
    }
    return {
      ...state,
      isFetching: false,
      imagesChanging: true,
      images: newImages
    }
  },
  [changeImageEpic.SUCCESS]: (state, action) => {
    return {
      ...state,
      imagesChanging: false,
      isFetching: false
    }
  },
  [changeImageEpic.FAIL]: (state, action) => {
    return {
      ...state,
      imagesChanging: false,
      isFetching: false
    }
  },
  [fetchImageEpic.START]: (state, action) => {
    return {
      ...state,
      images: [],
      fetched: true,
      imagesFetching: true
    }
  },
  [fetchImageEpic.SUCCESS]: (state, action) => {
    return {
      ...state,
      images: action.payload.response,
      fetched: true,
      imagesFetching: false
    }
  },
  [fetchImageEpic.FAIL]: (state, action) => {
    return {
      ...state,
      images: [],
      fetched: true,
      imagesFetching: false
    }
  },
  [REMOVE_IMAGE]: (state, action) => {
    let newImages = state.images.filter((val, index, array) => index !== action.payload.index);
    return {
      ...state,
      images: newImages
    }
  },
  [ADD_IMAGE]: (state, action) => {
    let newImages = state.images
    newImages.push({
      mimeType: action.payload.mimeType,
      alt: action.payload.alt,
      license: action.payload.license,
      imageBytes: action.payload.imageBytes
    })
      return {
        ...state,
        images: newImages
      }
  },
  [deleteImageEpic.START]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: true
    }
  },
  [deleteImageEpic.SUCCESS]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: false
    }
  },
  [deleteImageEpic.FAIL]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: false
    }
  },
  [saveImageEpic.START]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: false,
      imagesSaving: true
    }
  },
  [saveImageEpic.SUCCESS]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: false,
      imagesSaving: false
    }
  },
  [saveImageEpic.FAIL]: (state, action) => {
    return{
      ...state,
      isFetching: false,
      isDeleting: false,
      imagesSaving: false
    }
  },
}

export const actions = {
    fetchEinrichtungen,
    resetStore,
    fetchImages,
    removeImage,
    addImage,
    deleteImage,
    changeImage,
    saveImage
};

export const epics = [
  fetchEinrichtungenEpic.epic,
  fetchImageEpic.epic,
  deleteImageEpic.epic,
  changeImageEpic.epic,
  saveImageEpic.epic]

const initialState = {
    einrichtungen: [],
    isFetching: false,
    fetched: false,
    images: [],
    isDeleting: false,
    imagesFetching: false,
    imagesChanging: false,
    imagesSaving: false
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
