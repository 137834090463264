import {
    noop
} from '../../util/helper'
import {
    GETepicFactory
} from '../../util/epicFactories';

const prefix = `KENNZAHLEN`;

export const GET_KENNZAHLEN = `${prefix}/GET_KENNZAHLEN`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;

export const clearStore = () => dispatch => {
  dispatch({
      type: CLEAR_STORE
  });
  return Promise.resolve();
};

export const fetchKennzahlenEpic = GETepicFactory(`${prefix}/GET_KENNZAHLEN`, {
    url: (action) => {
        if(action.payload.ags && action.payload.ags.length === 8){
            return `/api/v1/kita/admin/statistics/count/all?ags=${action.payload.ags}`;
        } else {
            return `/api/v1/kita/admin/statistics/count/all`
        }
    }
})

export const fetchKennzahlen = (ags, success = noop, error = noop) => fetchKennzahlenEpic.run({
    ags: ags
  }, {success, error});

const ACTION_HANDLERS = {
    ...fetchKennzahlenEpic.ACTION_HANDLERS,
    [fetchKennzahlenEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            kennzahlen: {
                ...action.payload.response
            }
        };
    },
    [CLEAR_STORE]: (state, action) => {
        return {
            ...initialState
        }
    },

}

export const actions = {
    clearStore,
    fetchKennzahlen
};

export const epics = [
    fetchKennzahlenEpic.epic
]

const initialState = {
    isFetching: false,
    kennzahlen: { 
        "bedarfsanmeldung": 0, 
        "archivBedarfsanmeldung": 0, 
        "teilnahmeAnPortalloesungEinrichtung": 0, 
        "noTeilnahmeAnPortalloesungEinrichtung": 0, 
        "traeger": 0, 
        "mandant": 0 
    }
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
