import {noop} from './helper';
import { from, of, throwError } from 'rxjs';
import { delay, map, tap, filter, catchError, mergeMap, materialize, dematerialize } from 'rxjs/operators';
import { ofType } from 'redux-observable';
require('es6-promise').polyfill();
require('isomorphic-fetch');

const defaultCondition = () => true

function fetchEpicFactory(prefix, opts, condition = defaultCondition, method = 'GET', status = 200, json = true) {
    const START = `${prefix}_START`;
    const SUCCESS = `${prefix}_SUCCESS`;
    const FAIL = `${prefix}_FAIL`;

    const run = (payload, callbacks = {success: noop, error: noop}) => ({type: START, payload, callbacks});
    const success = (payload, response) => ({type: SUCCESS, payload: { ...payload, response }});
    const fail = (payload, error) => ({ type: FAIL, payload: { ...payload, error}, error: true });

    const fetchResponseHandler = r => {
        if(r.status === status) {
            if (json) {
                return r.json()
            }
            return;
        } else {
          throw { status: r.status, statusText: r.statusText }
        }
    }

    let fetchOptions = (action) => {

      if(action.payload && action.payload.headerKey && action.payload.headerVal){
        let k = action.payload.headerKey;
        let v = action.payload.headerVal;
        return {
          method,
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              [k]: v
          },
          credentials: 'same-origin'
        }
      } else {
        return {
          method,
          headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
          },
          credentials: 'same-origin'
        }
      }
    };
    if (method === 'POST' || method === 'PUT') {
        fetchOptions = (action) => {
          let k = action.payload.headerKey;
          let v = action.payload.headerVal;
          return {
            method,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json',
                [k]: v
            },
            credentials: 'same-origin',
            body: JSON.stringify(opts.body(action.payload))
        }}
    }


    const epic = (action$, state$) => action$.pipe(
      ofType(START),
      filter((action) => condition(action, state$.value)),
      mergeMap(action =>
        from(fetch(opts.url(action), fetchOptions(action)).then(fetchResponseHandler)).pipe(
          map(response => {
              if (action.callbacks && typeof action.callbacks.success === 'function') {
                  action.callbacks.success()
              }
              return success(action.payload, response)
          }),
          catchError(error => {
              if (action.callbacks && typeof action.callbacks.error === 'function') {
                  action.callbacks.error(error)
              }
              return of(fail(action.payload, error))
          })
        )
      )
    );

    const successActionHandler = method === 'GET' ? (state, action) => ({
        ...state,
        isFetching: false,
        fetched: true,
        value: action.payload.response
    }) : (state, action) => ({
        ...state,
        isFetching: false,
        fetched: true,
        value: action.payload.response
    })

    return {
        START,
        SUCCESS,
        FAIL,
        run,
        success,
        fail,
        epic,
        ACTION_HANDLERS: {
            [START]: (state, action) => ({
                ...state,
                isFetching: true
            }),
            [SUCCESS]: successActionHandler,
            [FAIL]: (state, action) => ({
                ...state,
                isFetching: false,
                fetched: true
            }),
        },
    }
}

export function GETepicFactory(prefix, opts, condition = defaultCondition) {
    return fetchEpicFactory(prefix, opts, condition)
}

export function PUTepicFactory(prefix, opts, condition = defaultCondition) {
    return fetchEpicFactory(prefix, opts, condition, 'PUT', 204, false)
}

export function POSTepicFactory(prefix, opts, condition = defaultCondition, status = 204, json = false) {
    return fetchEpicFactory(prefix, opts, condition, 'POST', status, json)
}

export function DELETEepicFactory(prefix, opts, condition = defaultCondition) {
    return fetchEpicFactory(prefix, opts, condition, 'DELETE', 204, false)
}

export function mergeEpicFactory(type, toType) {
    return {
        epic: (action$, state$) => action$.pipe(
            ofType(type),
            mergeMap(action => of(toType(action)))
        )
    }
}
