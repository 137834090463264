<template>
  <div>
    <div>
      <v-list-item
        class="list-hover"
        v-if="status !== 'ZURUECKGESTELLT'"
        >
        <v-list-item-action class="pointer" @click="reclineAllowed ? emitRecline() : noop()" :disabled="reclineAllowed" >
          <Tooltip top>
              <v-icon
                :disabled="!reclineAllowed"
                medium
                color="error darken-2">
                  block
              </v-icon>
            <template #content>
              <span>
                Zurückstellen
              </span>
            </template>
          </Tooltip>
        </v-list-item-action>
        <v-list-item-content class="pointer" @click="reclineAllowed ? emitRecline() : noop()" :disabled="reclineAllowed">
          <v-list-item-title>
            Zurückstellung
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="reclineAllowed">
                Zurückstellen der Bedarfsanmeldung
            </span>
            <span v-else>
                - Keine Zurückstellungsrechte -
            </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle >
            <span v-if="!reclineAllowed">
                Um eine Bedarfsanmeldung zurücktzustellen, melden Sie sich bei Ihrer Kommune.
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
        <Button icon ripple @click="emitExplanation(`Zurückstellung`, `Bei einer Zurückstellung wird das
          Kind aus dem Prozess der Zuteilungen entfernt. Es sollte nur aus bestimmten
          Gründen zurückgestellt werden. Z.B.: Die Eltern haben den Bedarf zurückgezogen. `)">
          <v-icon color="grey darken-1">info</v-icon>
        </Button>
      </v-list-item-action>
      </v-list-item>
      <v-list-item
        @click="emitUndoRecline"
        v-else
        :disabled="!reclineAllowed"
        >
        <v-list-item-action>
          <Tooltip top>
              <v-icon
                medium
                color="primary"
                :disabled="!reclineAllowed">
                  undo
              </v-icon>
            <template #content>
              <span>
                  Undo
              </span>
            </template>
          </Tooltip>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Zurückstellung rückgängig machen
          </v-list-item-title>
          <v-list-item-subtitle>
            <span v-if="!reclineAllowed">
                - Keine Zurückstellungsrechte -
            </span>
            <span v-else>
                Erneutes Öffnen der Bedarfsanmeldung
            </span>
          </v-list-item-subtitle>
            <span v-if="!reclineAllowed">
                Wenden Sie sich an Ihre Kommune, um die Bedarfsanmeldung erneut zu öffnen.
            </span>
          <v-list-item-subtitle>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>
    <v-divider light></v-divider>
    <v-list-item class="list-hover">
      <v-list-item-action @click="emitComment" class="pointer">
        <Tooltip top>
            <v-icon medium color="primary">
                create
            </v-icon>
          <template #content>
            <span>
              Zusätzliche Bemerkung erstellen
            </span>
          </template>
        </Tooltip>
      </v-list-item-action>
      <v-list-item-content @click="emitComment" class="pointer">
        <v-list-item-title>
          Interne Bemerkung
        </v-list-item-title>
        <v-list-item-subtitle>
            Verfassen sie eine Bemerkung für diese BA
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <Button icon ripple @click="emitExplanation(`Bemerkung`, `Es ist möglich
          eine Bemerkung für diese Bedarfsanmeldung zu erstellen. Diese Bemerkung
          wird zusammen mit der Bedarfsanmeldung gespeichert, wird den anderen
          Einrichtungen angezeigt und sollte beim Zuteilungsprozess
          beachtet werden. Diese Bemerkung wird nicht nacht außen kommuniziert.`)">
          <v-icon color="grey darken-1">info</v-icon>
        </Button>
      </v-list-item-action>
    </v-list-item>
    <v-divider light></v-divider>
    <v-list-item class="list-hover" v-if="archiveAllowed">
      <v-list-item-action @click="emitArchive" class="pointer">
        <Tooltip top>
            <v-icon medium color="primary">
                history
            </v-icon>
          <template #content>
            <span>
              Anmeldung Archivieren
            </span>
          </template>
        </Tooltip>
      </v-list-item-action>
      <v-list-item-content @click="emitArchive" class="pointer">
        <v-list-item-title>
          Archivierung
        </v-list-item-title>
        <v-list-item-subtitle>
            Archivieren dieser Anmeldung
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <Button icon ripple @click="emitExplanation(`Archivierung`,
          `Bei der Archivierung wird die Bedarfsanmeldung in ein spezielles
          Archiv verschoben und somit aus dem Zuteilungsprozess entfernt,
          nutzen Sie die Archivierung, um die Anmeldungen der vergangenen
          Saison zu archivieren oder um eine Bedarfsanmeldung aus der aktuellen
          Saison zu entfernen und somit eine ggf. erneute Anmeldung für das betroffene
          Kind möglich zu machen.`)">
          <v-icon color="grey darken-1">info</v-icon>
        </Button>
      </v-list-item-action>
    </v-list-item>
    <v-divider light></v-divider>
    <v-list-item class="list-hover">
      <v-list-item-action @click="() => {}" class="pointer">
        <Tooltip top>
            <v-icon medium color="primary">
                filter_5
            </v-icon>
          <template #content>
            <span>
              Interne Dringlichkeit einer Anmeldung
            </span>
          </template>
        </Tooltip>
      </v-list-item-action>
      <v-list-item-content  class="pointer">
        <v-list-item-title>
          Interne Dringlichkeit: 
          <internal-priority :internalPriority="counter" :show_null="true" style="display: inline" />
        </v-list-item-title>
        <v-list-item-subtitle>
            <Button 
              icon 
              small 
              @click.stop="--counter"
              :disabled="counter==0">
              <v-icon small color="primary">exposure_neg_1</v-icon>
            </Button>
            <Button @click="emitInternalPriority" color="primary" small>Dringlichkeit vergeben</Button>
            <Button 
              icon 
              small 
              @click.stop="++counter"
              :disabled="counter==5 ">
              <v-icon small color="primary">exposure_plus_1</v-icon>
            </Button>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <Button icon ripple @click="emitExplanation(`Interne Dringlichkeit`,
          `Mit der Vergabe einer internen Dringlichkeit können Sie eine 
          besondere Dringlichkeit für die Bearbeitung einer 
          Bedarfsanmeldung ausdrücken. Wobei 1 eine sehr niedrige Dringlichkeit darstellt und 
          5 eine sehr hohe Dringlichkeit ausdrückt. `)">
          <v-icon color="grey darken-1">info</v-icon>
        </Button>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
import InternalPriority from './InternalPriority.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

/**
 * The additional kita component is able to control the additional actions
 * a user can take on a registration besides assigment. These are: Recline (Zurückstellen),
 * Comment (Bemerkung hinzufügen) and Archive (Archivieren)
 */
export default {
    name: "additional-action-component",
    data() {
      return {
        counter: 0
      }
    },
    props: {
    /**
     * The assigment status of a registration. If it is 'ZURÜCKGESTELLT' the
     * according list tile will display the undo option for recline.
     */
      status: {
        type: String
      },
    /**
     * Wether the recline actio is allowed or not. Only KV user is allowed to
     * recline.
     */
      reclineAllowed: {
        type: Boolean,
        default: true
      },
    /**
     * Wether the archive is allowed or not. Only KV user is allowed to archive.
     */
      archiveAllowed: {
        type: Boolean,
        default: false
      },
      internalPriority: {
        type: Number,
        required: false
      }
    },
    methods: {
      emitRecline(){
      /**
        * Triggers when the recline registration tile was clicked.
        */
        this.$emit("emitRecline");
      },
      emitUndoRecline(){
      /**
        * Triggers when the undo recline registration tile was clicked.
        */
        this.$emit("emitUndoRecline");
      },
      emitComment(){
      /**
        * Triggers when the comment registration tile was clicked.
        * In German: "Eine Bemerkung hinzufügen"
        */
        this.$emit("emitComment");
      },
      emitExplanation(title, explanation){
      /**
        * Triggers when the explanation button on the right side of the tiles
        * was was clicked.
        */
        this.$emit("emitExplanation", title, explanation)
      },
      emitArchive(){
      /**
        * Triggers when the archive registration tile was clicked.
        * In German: "Archivieren"
        */
        this.$emit("emitArchive");
      },
      emitInternalPriority(){
        if(this.counter === 0){
      /**
        * Triggers when the emit internal priority button was clicked
        * In German: "Prio vergeben"
        */
          this.$emit("emitInternalPriority", null);
        } else {
          this.$emit("emitInternalPriority", this.counter);
        }
      },
      noop(){}
    },
    mounted() {
      if(this.internalPriority === null){
        this.counter = 0;
      } else {
        this.counter = this.internalPriority;
      }
    },
    components: {
      InternalPriority,
      Tooltip,
      Button
    }
}
</script>

<style lang="css">

.list-hover:hover{
  background-color: #F5F5F5;
}

.red {
  color: red;
}

.green {
  color: green;
}
</style>
