var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"90vw"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.bedarfsAnmeldung !== null)?_c('v-card',[_c('v-card-title',{staticClass:"headline primary",attrs:{"primary-title":""}},[_c('v-icon',{staticClass:"pr-3",staticStyle:{"color":"white"},attrs:{"meidum":""}},[_vm._v(" info ")]),_vm._v(" "),_c('span',{staticStyle:{"color":"white"}},[_vm._v("\n                Bedarfsanmeldung -\n                "+_vm._s(_vm.bedarfsAnmeldung.kindesdaten[0].vorname)+"\n                "+_vm._s(_vm.bedarfsAnmeldung.kindesdaten[0].name)+"\n                (geb.\n                "+_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung.kindesdaten[0].geburtsdatum))+")\n            ")]),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('Button',{attrs:{"large":"","icon":""},on:{"click":_vm.closeDialog}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" clear ")])],1)],1),_vm._v(" "),_c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"ma-4",attrs:{"xs12":"","md12":"","lg12":"","xl12":""}},[_c('div',{staticClass:"mb-12 mt-4"},[_c('div',{staticClass:"mx-12"},[(_vm.bedarfsAnmeldung.internalPriority)?_c('div',{staticClass:"mt-0 mb-2 mx-1 d-flex align-center"},[_c('span',{staticClass:"mx-2",attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" info ")])],1),_vm._v(" "),_c('span',{staticClass:"d-flex"},[_c('b',{staticClass:"pr-2"},[_vm._v("Interne Dringlichkeit:\n                                    ")]),_vm._v(" "),_c('InternalPriority',{attrs:{"internalPriority":_vm.bedarfsAnmeldung.internalPriority}})],1)]):_vm._e(),_vm._v(" "),(
                                    _vm.bedarfsAnmeldung.kindesdaten[0]
                                        ?.bemerkungen
                                )?_c('v-alert',{staticStyle:{"margin-left":"15px","margin-right":"15px"},attrs:{"value":true,"type":"info","outlined":""}},[_c('h3',[_vm._v("Interne Bemerkung:")]),_vm._v("\n                                "+_vm._s(_vm.bedarfsAnmeldung.kindesdaten[0]
                                        ?.bemerkungen)+"\n                            ")]):_vm._e(),_vm._v(" "),(_vm.bedarfsAnmeldung.bemerkung)?_c('v-alert',{staticStyle:{"margin-left":"15px","margin-right":"15px"},attrs:{"value":true,"type":"error","outlined":""}},[_c('h3',[_vm._v("Grund der Zurückstellung:")]),_vm._v("\n                                "+_vm._s(_vm.bedarfsAnmeldung.bemerkung)+"\n                            ")]):_vm._e(),_vm._v(" "),_c('v-row',{staticClass:"mt-4 mb-4"},[_c('v-col',{staticClass:"subtitle-2",attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"width":"100%","border":"none","box-shadow":"none"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Status:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.status !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.status !==
                                                                ''
                                                        )?[_c('span',[_vm._v(_vm._s(_vm.bedarfsAnmeldung.status))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Zugeteilte Einrichtung:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.zugeteilteEinrichtung !==
                                                            null
                                                        )?[_c('span',[_vm._v(_vm._s(_vm.mapEinrichtung(
                                                                _vm.bedarfsAnmeldung.zugeteilteEinrichtung
                                                            )))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Benachrichtigungsstatus:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.benachrichtigungsstatus !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.benachrichtigungsstatus !==
                                                                ''
                                                        )?[_c('span',[_vm._v(_vm._s(_vm.bedarfsAnmeldung.benachrichtigungsstatus))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Aufnahmedatum:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.aufnahmedatum !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.aufnahmedatum !==
                                                                '' &&
                                                            _vm.bedarfsAnmeldung
                                                                .aufnahmedatum
                                                                .von !==
                                                                null
                                                        )?[(
                                                                _vm.bedarfsAnmeldung
                                                                    .aufnahmedatum
                                                                    .von !==
                                                                null
                                                            )?_c('span',[_vm._v(_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung
                                                                    .aufnahmedatum
                                                                    .von))+"\n                                                            "),(
                                                                    _vm.bedarfsAnmeldung
                                                                        .aufnahmedatum
                                                                        .bis !==
                                                                    null
                                                                )?_c('span',[_vm._v("\n                                                                bis\n                                                                "+_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung
                                                                        .aufnahmedatum
                                                                        .bis)))]):_vm._e()]):_vm._e()]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Alternatives\n                                                    Aufnahmedatum:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.alternativesAufnahmedatum !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.alternativesAufnahmedatum !==
                                                                ''
                                                        )?[(
                                                                _vm.bedarfsAnmeldung
                                                                    .alternativesAufnahmedatum
                                                                    .von !==
                                                                null
                                                            )?_c('span',[_vm._v(_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung
                                                                    .alternativesAufnahmedatum
                                                                    .von)))]):_vm._e(),(
                                                                _vm.bedarfsAnmeldung
                                                                    .alternativesAufnahmedatum
                                                                    .bis !==
                                                                null
                                                            )?_c('span',[_vm._v("bis\n                                                            "+_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung
                                                                    .alternativesAufnahmedatum
                                                                    .bis)))]):_vm._e()]:[_vm._v("\n                                                        -\n                                                    ")]],2)])])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"subtitle-1",attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"width":"100%","border":"none","box-shadow":"none"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Eingang der Anmeldung:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.erzeugungsDatum !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.erzeugungsDatum !==
                                                                ''
                                                        )?[_c('span',[_vm._v(_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung.erzeugungsDatum)))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Letzte Änderung:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.aenderungsDatum !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.aenderungsDatum !==
                                                                ''
                                                        )?[_c('span',[_vm._v(_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung.aenderungsDatum)))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Letzte Benachrichtigung:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.lastNotificationDate !==
                                                            null
                                                        )?[_c('span',[_vm._v(_vm._s(_vm._f("transformDate")(_vm.bedarfsAnmeldung.lastNotificationDate)))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                    Einrichtungsart:\n                                                ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                            _vm.bedarfsAnmeldung.einrichtungsart !==
                                                                null &&
                                                            _vm.bedarfsAnmeldung.einrichtungsart !==
                                                                ''
                                                        )?[_c('span',[_vm._v(_vm._s(_vm.mapEinrichtungsart(
                                                                _vm.bedarfsAnmeldung.einrichtungsart
                                                            )))])]:[_vm._v("\n                                                        -\n                                                    ")]],2)])])],1)],1),_vm._v(" "),(
                                        _vm.bedarfsAnmeldung.bemerkungAlternativesAufnahmedatum
                                    )?_c('v-col',{staticClass:"mb-4",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mx-4 border rounded-lg lighten-1",staticStyle:{"border":"1px solid lightgrey","box-shadow":"none","flex":"0.475","display":"flex","align-items":"center","flex-direction":"column"}},[_c('v-card-text',{staticClass:"d-flex align-center"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("\n                                                        Diese Anmerkung wird\n                                                        den Eltern in der\n                                                        Benachrichtigung\n                                                        mitgeteilt.\n                                                    ")])]},proxy:true}],null,false,4126995455)},[_c('Button',{staticClass:"mr-4",attrs:{"medium":"","icon":""}},[_c('v-icon',{staticStyle:{"color":"lightgray"}},[_vm._v("mail_outline")])],1)],1),_c('span',[_c('b',[_vm._v("Alternatives\n                                                    Aufnahmedatum\n                                                    Anmerkung")]),_c('br'),_vm._v("\n                                                "+_vm._s(_vm.bedarfsAnmeldung.bemerkungAlternativesAufnahmedatum)+"\n                                            ")])],1)],1)],1):_vm._e()],1)],1),_vm._v(" "),_c('v-divider',{staticClass:"mb-10",staticStyle:{"margin-left":"64px","margin-right":"64px"}}),_vm._v(" "),[_c('div',{staticClass:"mx-12 mb-10"},[_c('div',{staticClass:"subtitle-1 font-weight-bold grey--text lighten-2 mx-4 mb-4"},[_vm._v("\n                                    Betreuungszeiten\n                                ")]),_vm._v(" "),_c('div',{staticClass:"d-flex w-100 mx-2"},_vm._l(([
                                            'Montag',
                                            'Dienstag',
                                            'Mittwoch',
                                            'Donnerstag',
                                            'Freitag',
                                        ]),function(key){return _c('v-card',{key:key,staticClass:"border rounded-lg mx-2",staticStyle:{"border":"1px solid lightgray","box-shadow":"none","flex":"1","display":"flex","align-items":"center","flex-direction":"column"}},[_c('v-card-text',{staticClass:"text-center"},[_c('div',{staticClass:"pb-2 pt-0 primary--text font-weight-bold"},[_vm._v("\n                                                "+_vm._s(key)+"\n                                            ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),(
                                                    _vm.bedarfsAnmeldung.betreuungszeit !==
                                                        null &&
                                                    _vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ] !== null
                                                )?_c('div',{staticClass:"pt-2"},[_vm._v("\n                                                "+_vm._s(_vm._f("cutSeconds")(_vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ].von))+"\n                                                -\n                                                "+_vm._s(_vm._f("cutSeconds")(_vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ].bis))+"\n                                            ")]):_c('div',{staticClass:"pt-2"},[_vm._v("\n                                                keine Betreuung\n                                            ")]),_vm._v(" "),(
                                                    _vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ] !== null &&
                                                    _vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ].mittagessen !==
                                                        null &&
                                                    _vm.bedarfsAnmeldung
                                                        .betreuungszeit[
                                                        key.toLowerCase()
                                                    ].mittagessen
                                                )?_c('div',{staticClass:"pt-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Mittagessen\n                                                            gebucht")])]},proxy:true}],null,true)},[_c('v-icon',{staticStyle:{"color":"lightgray"}},[_vm._v("restaurant_menu")])],1)],1):_c('div',{staticClass:"pt-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("kein\n                                                            Mittagessen\n                                                            gebucht")])]},proxy:true}],null,true)},[_c('v-icon',{staticStyle:{"color":"lightgray"}},[_vm._v("remove")])],1)],1)],1)],1)}),1)])],_vm._v(" "),_c('v-divider',{staticClass:"mb-10 mt-12",staticStyle:{"margin-left":"64px","margin-right":"64px"}}),_vm._v(" "),[_c('div',{staticClass:"mx-12 mb-6"},[_c('div',{staticClass:"subtitle-1 font-weight-bold grey--text lighten-2 mx-4 mb-6"},[_vm._v("\n                                    Priorisierte Einrichtungen\n                                ")]),_vm._v(" "),_c('v-row',{staticClass:"mx-4",attrs:{"no-gutters":""}},_vm._l((_vm.prioLength),function(i){return _c('v-col',{key:`prio_${i}`,attrs:{"cols":12 / _vm.prioLength}},[_c('v-col',{staticStyle:{"height":"100%"}},[_c('v-row',{class:i % 2 === 0
                                                        ? 'odd-col'
                                                        : 'odd-col'},[_c('v-col',{class:{
                                                        'primary lighten-1 white--text font-weight-bold':
                                                            _vm.bedarfsAnmeldung
                                                                .priorities[
                                                                i - 1
                                                            ].status ===
                                                            'ANGENOMMEN',
                                                    },staticStyle:{"height":"100%","display":"flex","flex-direction":"column","justify-content":"center"}},[_c('v-row',{staticClass:"flex-column"},[_c('v-col',{staticClass:"text-center pt-4 pb-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Priorität\n                                                                "+_vm._s(i))]),_vm._v(" "),_c('br'),_vm._v(" "),_c('span',{staticClass:"subtitle-2"},[_vm._v("\n                                                                "+_vm._s(_vm.bedarfsAnmeldung
                                                                        .priorities[
                                                                        i -
                                                                            1
                                                                    ]
                                                                        .status ||
                                                                    'keine Handlung')+"\n                                                            ")])]),_vm._v(" "),_c('v-col',{staticClass:"text-center pb-4 pt-4",class:{
                                                                'primary lighten-2 white--text font-weight-bold':
                                                                    _vm.bedarfsAnmeldung
                                                                        .priorities[
                                                                        i -
                                                                            1
                                                                    ]
                                                                        .status ===
                                                                    'ANGENOMMEN',
                                                                'even-col':
                                                                    i %
                                                                        2 ===
                                                                    0,
                                                            },staticStyle:{"background-color":"white","padding":"10px"}},[_vm._v("\n                                                            "+_vm._s(_vm.mapEinrichtung(
                                                                    _vm.bedarfsAnmeldung
                                                                        .priorities[
                                                                        i -
                                                                            1
                                                                    ].id
                                                                ))+"\n                                                        ")])],1)],1)],1)],1)],1)}),1)],1)]],2),_vm._v(" "),_vm._l((_vm.bedarfsAnmeldung.kindesdaten),function(children,index){return _c('Kindesdaten',{key:`child_${index}`,staticClass:"my-4",attrs:{"kindesdaten":children,"geschwister":_vm.bedarfsAnmeldung.geschwister,"calculatedAge":_vm.calculatedAge}})}),_vm._v(" "),_vm._l((_vm.bedarfsAnmeldung.vertreter),function(vertreter,index){return _c('Vertreter',{key:`vertreter_${index}`,staticClass:"my-4",attrs:{"vertreter":vertreter,"index":index}})}),_vm._v(" "),_c('Sonstiges',{staticClass:"my-4",attrs:{"sonstigeDaten":_vm.getSonstigeDaten,"hinweise":_vm.bedarfsAnmeldung.hinweise,"internalPriority":_vm.bedarfsAnmeldung.internalPriority,"nachzuegler":_vm.bedarfsAnmeldung.nachzuegler,"unterjaehrig":_vm.bedarfsAnmeldung.quereinsteiger}})],2)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }