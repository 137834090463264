<template>
<v-content>
    <v-container pa-0 fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex v-if="trustlevel !== 0 && fetched" xs12 sm8 md4>
                <welcome />
            </v-flex>
            <v-flex v-else xs12 sm8 md4>
                <login />
            </v-flex>
        </v-layout>
    </v-container>
</v-content>
</template>
<script>
import Login from './Login.vue'
import Welcome from './Welcome.vue'
export default {
    name: 'index-page',
    data() {
        return {
            login: {
                user: '',
                pw: '',
            },
            trustlevel: this.$select('auth.trustlevel as trustlevel'),
            fetched: this.$select('user.fetched as fetched')
        }
    },
    components: {
        Login,
        Welcome,
    }
}
</script>
