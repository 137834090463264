<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header color="primary">
                <span class="title white--text">Sonstiges </span>
                <template v-slot:actions>
                    <v-icon color="white">keyboard_arrow_down</v-icon>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="mt-6">
                <div class="mb-10 mx-10">
                    <div
                        class="mt-0"
                        v-if="hinweise !== null && hinweise.length > 0"
                    >
                        <div
                            v-for="(hinweis, index) in hinweise"
                            :key="index"
                            class="mb-2"
                        >
                            <span medium icon>
                                <v-icon color="primary">info</v-icon>
                            </span>

                            <b>Hinweis vorhanden: </b>{{ hinweis }}<br />
                        </div>
                        <div class="mt-0 mb-2" v-if="nachzuegler">
                            <span medium icon>
                                <v-icon color="primary"> info </v-icon>
                            </span>

                            <span><b>Nachzügler</b></span>
                        </div>
                        <div class="mt-0 mb-2" v-if="unterjaehrig">
                            <span medium icon>
                                <v-icon color="primary"> info </v-icon>
                            </span>

                            <span><b>unterjährig</b></span>
                        </div>
                    </div>
                </div>

                <v-row class="mb-6">
                    <v-col cols="6">
                        <v-card
                            class="ml-6"
                            style="width: 100%; border: none; box-shadow: none"
                        >
                            <v-card-text class="pt-0">
                                <div class="d-flex mb-2">
                                    <div
                                        class="subtitle-1 font-weight-bold"
                                        style="flex: 1"
                                    >
                                        Bankkonto
                                    </div>
                                </div>

                                <template>
                                    <div>
                                        <template
                                            v-for="(key, index) in [
                                                'bankverbindungKontoinhaber',
                                                'bankverbindungIBAN',
                                                'bankverbindungBIC',
                                            ]"
                                        >
                                            <div
                                                :key="key"
                                                :class="{
                                                    'd-flex': true,
                                                    'mb-2': true,
                                                    'even-row': index % 2 === 0,
                                                }"
                                            >
                                                <div
                                                    class="subtitle-1 gray--text"
                                                    style="flex: 1"
                                                >
                                                    {{ formatKey(key) }}
                                                </div>
                                                <div
                                                    class="subtitle-1 font-weight-bold"
                                                    style="
                                                        flex: 1;
                                                        margin-left: 10px;
                                                    "
                                                >
                                                    <template
                                                        v-if="
                                                            sonstigeDaten[
                                                                key
                                                            ] !== null &&
                                                            sonstigeDaten[
                                                                key
                                                            ] !== ''
                                                        "
                                                    >
                                                        <span>{{
                                                            sonstigeDaten[key]
                                                        }}</span>
                                                    </template>
                                                    <template v-else
                                                        >-</template
                                                    >
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6" class="pr-6">
                        <v-card
                            style="width: 100%; border: none; box-shadow: none"
                        >
                            <v-card-text class="pt-0">
                                <div class="d-flex mb-2">
                                    <div
                                        class="subtitle-1 font-weight-bold"
                                        style="flex: 1"
                                    >
                                        Zugestimmt
                                    </div>
                                </div>

                                <template>
                                    <div>
                                        <template
                                            v-for="(key, index) in [
                                                'datenschutzZustimmung',
                                                'datenverwendungZustimmung',
                                                'zustimmungZustimmung',
                                                'gelesenUndGeprueft',
                                            ]"
                                        >
                                            <div
                                                :key="key"
                                                :class="{
                                                    'd-flex': true,
                                                    'mb-2': true,
                                                    'even-row': index % 2 === 0,
                                                }"
                                            >
                                                <div
                                                    class="subtitle-1 gray--text"
                                                    style="flex: 1"
                                                >
                                                    {{ formatKey(key) }}
                                                </div>
                                                <div
                                                    class="subtitle-1 font-weight-bold"
                                                    style="
                                                        flex: 1;
                                                        margin-left: 10px;
                                                    "
                                                >
                                                    <div
                                                        v-if="
                                                            sonstigeDaten[
                                                                key
                                                            ] !== null
                                                        "
                                                    >
                                                        <span
                                                            v-if="
                                                                sonstigeDaten[
                                                                    key
                                                                ] === true
                                                            "
                                                            >ja</span
                                                        >

                                                        <span v-else>nein</span>
                                                    </div>
                                                    <div v-else>nein</div>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import Tooltip from '@s/views/general_components/components/Tooltip';

export default {
    data() {
        return {};
    },
    components: {
        Tooltip,
    },
    props: {
        sonstigeDaten: {
            type: Object,
            default: null,
        },
        hinweise: {
            type: Array,
            default: null,
        },
        internalPriority: {
            type: Number,
            default: null,
        },
        nachzuegler: {
            type: Boolean,
            required: false,
        },
        unterjaehrig: {
            type: Boolean,
            required: false,
        },
    },
    methods: {
        formatKey(key) {
            switch (key) {
                case 'datenschutzZustimmung':
                    return 'Datenschutz';
                case 'datenverwendungZustimmung':
                    return 'Datenwerwendung';
                case 'gelesenUndGeprueft':
                    return 'Gelesen und geprüft';
                case 'zustimmungZustimmung':
                    return 'Von allen Vertretern';
                case 'bankverbindungKontoinhaber':
                    return 'Kontoinhaber';
                case 'bankverbindungIBAN':
                    return 'IBAN';
                case 'bankverbindungBIC':
                    return 'BIC';

                default:
                    return key;
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
i
  font-weight: bold


.even-row
  background-color: #f0f0f0
</style>
