<template>
<div>

    <all-information-dialog ref="allInformationDialog" />

    <filter-dialog ref="filterDialog" @returnSearchString="addFilter" />

    <ExplanationDialog
      ref="explanationDialog"
      :title="explanationDialog.title"
      :text="explanationDialog.text"
    />

    <refuse-dialog
      ref="refuseDialog"
      :title="refuseDialog.title"
      :action="refuseDialog.action"
      :hint="refuseDialog.hint"
      :icon="refuseDialog.icon"
      :color="refuseDialog.color"
      :confirmEvent="refuseDialog.confirmEvent"
      @reclineConfirm="reclineRegistration"
      @refuseConfirm="refuseRegistration"
      @commentConfirm="addComment"
    />

    <v-data-table
      ref="refTable"
      :headers="headers"
      :items="registrations"
      :loading="loading"
      :loading-text="tableLoadingText"
      :no-results-text="status"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': rowsPerPageItems,
        itemsPerPageText: rowsPerPageText
      }"
    >
      <template v-slot:top>
        <v-container fluid>
          <v-layout row wrap>
            <v-flex xs6 class="px-2">

              <autocomplete-names 
                :names="names"
                :loading="loading"
                @select="selectName"
                @openInfo="openInfo"
                @openExplain="openExplain"
              />

            </v-flex>
            <v-flex xs6 class="px-2" v-if="!(einrichtungen.length === 1)">
              <v-select
                v-model="einrichtungenSelected"
                :items="einrichtungen"
                item-text="name"
                item-value="id"
                label="Nach Einrichtungen filtern"
                multiple
                clearable
                append-outer-icon="info"
                @click:append-outer="openExplanationDialog('Filter', `Nutzen Sie die Filter, um
                nach Bedarfsanmeldungen zu filtern, die bestimmte Kriterien erfüllen.
                Um nach Einrichtungen zu filtern, wählen Sie die entsprechenden Einrichtungen aus
                und klicken auf 'FILTERN'.`)"
                :disabled="loading"
                v-if="!(einrichtungen.length === 1)"
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0" color="primary" small>
                    <span class="white--text">{{ item.name }}</span>
                  </v-chip>
                  <v-chip v-if="index === 1" color="primary" small>
                    <span class="white--text">{{ item.name }}</span>
                  </v-chip>
                  <span
                    v-if="index === 2"
                    class="grey--text caption"
                  >(+{{ einrichtungenSelected.length - 2 }} andere)</span>
                </template>
              </v-select>
            </v-flex>
            <v-flex class="py-2" :xs6="einrichtungen.length === 1" :xs12="einrichtungen.length !== 1">
              <div align="right">
                <Button
                  class="mr-2"
                  color="primary"
                  rounded
                  @click="openFilterDialog"
                  :disabled="loading">
                  <span v-if="!filterActive">Filtern</span>
                  <span v-else>Filter ändern</span>
                  <v-icon class="pl-2">
                      filter_list
                  </v-icon>
                </Button>
                <Tooltip top v-if="filterActive">
                    <Button
                      class="mr-2"
                      color="primary"
                      rounded
                      @click="undoFilter">
                        <span>Filter entfernen</span>
                        <v-icon class="pl-2">clear</v-icon>
                    </Button>
                  <template #content>
                    <span>Filter entfernen</span>
                  </template>
                </Tooltip>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

        </template>
        <template v-slot:item="{ item }">
          <tr :style="item.benachrichtigungsstatus === 'ABGELEHNT' ? 'background-color: rgb(255, 165, 142, 0.6)'
              : item.status.value === 'GENEHMIGT' ? 'background-color: rgb(0, 107, 3, 0.175)'
              : item.status.value === 'ZURUECKGESTELLT' ? 'background-color: rgb(142, 0, 0, 0.2)'
              : item.allRejected  ? 'background-color: rgb(255, 255, 0, 0.2)' : ''">
            <td class="text-xs-left" v-if="editable" :key="item.id">
              <div v-if="!Object.keys(permissions).includes('Postkorbnachricht.only') || (Object.keys(permissions).includes('Postkorbnachricht.only') && !permissions['Postkorbnachricht.only'].includes('lesen / anzeigen'))">
              
                <menu-component
                  v-if="permissions.Bedarfsanmeldung.includes('ändern') && !archive.includes(item.id)"
                  :bedarfsAnmeldung="item"
                  :einrichtungen="einrichtungen"
                  :role="role"
                  style="display: inline"
                  @assignRegistration="assignRegistration"
                  @declineRegistration="declineRegistration"
                  @recline="openReclineDialog"
                  @undoRecline="undoRecline"
                  @refuse ="openRefuseDialog"
                  @comment="openCommentDialog"
                  @emitExplanation="openExplanationDialog"
                  @emitArchive="emitArchive"
                  @emitInternalPriority="emitInternalPriority"> 
                </menu-component>
              </div>
             <Tooltip top v-else class="pointer">
                  <v-icon>
                      not_interested
                  </v-icon>
                  <template #content>
                    <span> Keine Rechte, um Änderungen durchzuführen </span>
                  </template>
              </Tooltip> 

            <Tooltip top v-if="!permissions.Bedarfsanmeldung.includes('ändern')" class="pointer">
                <v-icon>
                    not_interested
                </v-icon>
              <template #content>
                <span> Keine Rechte, um Änderungen durchzuführen </span>
              </template>
            </Tooltip>
            <Tooltip top v-if="archive.includes(item.id)" class="pointer">
                <v-icon>
                    history
                </v-icon>
              <template #content>
                <span> Anmeldung wurde archiviert </span>
              </template>
            </Tooltip>
            </td>
            <td v-if="isArchive">
              <Tooltip top v-if="undidRegistrations.includes(item.id)">
                  <v-icon class="pointer" color="primary">check</v-icon>
                <template #content>
                  <span>Anmeldung wurde wiedereingesetzt</span>
                </template>
              </Tooltip>
              <Tooltip top v-else>
                  <Button
                    icon
                    @click="emitUndoArchive(item.id)"
                    :disabled="archiveIsFetching">
                    <v-icon color="primary">undo</v-icon>
                  </Button>
                <template #content>
                  <span>Anmeldung wieder einsetzen</span>
                </template>
              </Tooltip>
            </td>
            <td v-if="isFinal">
              <Tooltip top>
                  <Button
                    icon
                    :disabled="archive.includes(item.id) "
                    @click="emitReopenRegistration(item)">
                    <v-icon color="primary">lock_open</v-icon>
                  </Button>
                <template #content>
                  <span>Wieder-Öffnen der Anmeldung</span>
                </template>
              </Tooltip>
              <Tooltip top>
                  <Button
                    icon
                    :disabled="archive.includes(item.id)"
                    @click="emitArchive(item)">
                    <v-icon color="primary">restore</v-icon>
                  </Button>
                <template #content>
                  <span>Archivierung der Anmeldung</span>
                </template>
              </Tooltip>
            </td>
            <td class="text-center" v-for="(it, index) in cols" :key="index">
                <TableCol
                  :content="item[it]">
                </TableCol>
            </td>
            <td class="text-right">
              <internal-priority :internalPriority="item.internalPriority" style="display: inline" />
              <Tooltip top>
                  <Button
                    small
                    icon
                    @click="openAllInformationDialog(getRegistration(item.id))">
                    <v-icon
                      color="primary">
                        info
                    </v-icon>
                  </Button>
                <template #content>
                  <span>Vollständige Information</span>
                </template>
              </Tooltip>
            </td>
          </tr>
      </template>
        <template slot="no-data">
        <v-alert
        :value="true"
        outlined
        :color="error ? 'error' : 'primary'"
        :icon="error ? 'warning' : 'info'"
        class="mt-4 mb-4">
          {{ status }}
        </v-alert>
      </template>
    </v-data-table>
</div>
</template>
<script>
import AllInformationDialog from './dialogs/NewAllInformationDialog.vue'
import RefuseDialog from './dialogs/RefuseDialog.vue'
import MenuComponent from './table-components/MenuComponent.vue'
import TableCol from './table-components/TableCol.vue'
import tableHeaders from './table-components/TableHeaders.js'
import FilterDialog from './dialogs/FilterDialog.vue'
import InternalPriority from './table-components/InternalPriority.vue'
import {
  transformDate,
  calculateAddedHours } from '../../../../util/helper.js'
import ExplanationDialog from '../../../general_components/dialogs/ExplanationDialog.vue'
import AutocompleteNames from '../../../general_components/components/AutocompleteNames.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

import { ROWS_PER_PAGE_ITEMS, ROWS_PER_PAGE_TEXT, TABLE_LOADING_TEXT } from '../../../const.js'


/**
 * The Table component contains all the functionality of user interaction.
 * Containing menu component, additional action component, einrichtung action component
 * and additional kita component.
 */
export default {
    name: "table-vue",
    data() {
        return {
            role: this.$select('user.role as role'),
            inc: this.$select('bedarfsAnmeldung.increaseId as inc'),
            dec: this.$select('bedarfsAnmeldung.decreaseId as dec'),
            permissions: this.$select('user.permissions as permissions'),
            registrationsComplete: this.$select('bedarfsAnmeldung.bedarfsAnmeldungen as registrationsComplete'),
            archive: this.$select('archive.archivedRegistrations as archive'),
            undidRegistrations: this.$select('archive.undidRegistrations as undidRegistrations'),
            archiveIsFetching: this.$select('archive.isFetching as archiveIsFetching'),
            headers: tableHeaders,
            allInformationDialog: null,
            filterDialog: null,
            refuseDialog: null,
            headersEditable: [],
            status: '',
            search: null,
            refuseDialog: {
              title: 'Bedarfsanmeldung - Zurückstellung',
              action: 'zurückstellen',
              hint: `Bei einer Zurückstellung wird diese Bedarfsanmeldung aus
              dem Prozess der Zuteilungen entfernt. Nur auf ausdrücklichen
              Wundsch der Eltern zurückstellen.`,
              icon: 'block',
              color: 'error',
              confirmEvent: 'reclineConfirm'
            },
            rowsPerPageItems: ROWS_PER_PAGE_ITEMS,
            rowsPerPageText: ROWS_PER_PAGE_TEXT,
            tableLoadingText: TABLE_LOADING_TEXT,
            einrichtungenSelected: [],
            cols: ["zugeteilteEinrichtung", "status", "name", "geburtsdatum",
            "wohnort", "aufnahmedatum", "iStatus"],
            explanationDialog: null,
            explanationDialog: {
              title: '',
              text: ''
            },
            filterActive: false,
            refTable: null,
            nameSelected: null,
            names: this.$select('bedarfsAnmeldung.names as names'),
        }
    },
    props: {
        /**
         * The registrations of a user.
         */
        registrations: {
            type: Array
        },
        /**
         * The facilities of a user.
         */
        einrichtungen: {
            type: Array
        },
        /**
         * Controls if the table component displays the action components.
         * True -> Action component will get displayed so the user is able to
         * make changes.
         */
        editable: {
            type: Boolean
        },
        /**
         * Controls the state of the data fetching process.
         * True -> Certain loading animation will get displayed.
         */
        loading: {
            type: Boolean
        },
        /**
         * Controls if an error is thrown during data fetching.
         * True -> A 4xx error code was thrown.
         */
        error: {
            type: Boolean
        },
        /**
         * Both archive and assignment view work with the table component.
         * True -> Certain actions which are exclusive for archive
         * (reinstate registration) will get displayed.
         */
        isArchive: {
          type: Boolean,
          default: false
        },
        isFinal: {
          type: Boolean,
          default: false
        }
    },
    watch: {
        loading: function(loading) {
            if (loading) {
                this.status = 'Daten werden geladen ...'
            } else {
                if (this.registrations && this.registrations.length === 0) {
                    this.status = "Keine Bedarfsanmeldungen vorhanden."
                } else {
                    this.status = "Kein Treffer. Keine Bedarfsanmeldung gefunden."
                }
                if (this.error) {
                    this.status = 'Fehler. Die Daten konnten nicht geladen werden'
                }
            }

        },
        einrichtungenSelected: function(newVal) {
          this.filterActive = false;
          if(newVal.length === 0){
            this.$dispatch(this.$actions().bedarfsAnmeldung.calcProjection());
          }
        },
        einrichtungen: function(newVal){
          if(newVal.length === 1){
            this.einrichtungenSelected.push(newVal[0].id);
          }
        },
        nameSelected: function(newVal){

          if(newVal === null || newVal.length === 0){
            if(!this.filterActive){
              this.$dispatch(this.$actions().bedarfsAnmeldung.calcProjection());
            }
          } else {
            if(this.filterActive){
              this.filterActive = false;
              this.einrichtungenSelected = [];
              if(this.einrichtungen.length === 1){
                this.einrichtungenSelected.push(this.einrichtungen[0].id);
              }
              this.$dispatch(this.$actions().bedarfsAnmeldung.calcProjection())
              .then(() =>
                {
                  // Single name
                  //this.$dispatch(this.$actions().bedarfsAnmeldung.selectOneByIndex(this.names[newVal].index));
                  let indices = [];
                  for(let i = 0; i < newVal.length; ++i){
                    if(!indices.includes(this.names[newVal[i]].index)){
                      indices.push(this.names[newVal[i]].index)
                    }
                  }
                  this.$dispatch(this.$actions().bedarfsAnmeldung.selectMultipleByIndex(indices));
                });
            } else {
              //this.$dispatch(this.$actions().bedarfsAnmeldung.selectOneByIndex(this.names[newVal].index));
              let indices = [];
              for(let i = 0; i < newVal.length; ++i){
                if(!indices.includes(this.names[newVal[i]].index)){
                  indices.push(this.names[newVal[i]].index)
                }
              }
              this.$dispatch(this.$actions().bedarfsAnmeldung.selectMultipleByIndex(indices));
            }
          }
        }
    },
    mounted() {
        this.headersEditable = this.headers.slice(1);
    },
    methods: {
        openAllInformationDialog(bedarfsAnmeldung) {
            this.$nextTick(() => {
                this.$refs.allInformationDialog.open(bedarfsAnmeldung, this.einrichtungen, this.role);
            });
        },
        openReclineDialog(bedarfsAnmeldung) {
          this.refuseDialog.title = 'Bedarfsanmeldung - Zurückstellung';
          this.refuseDialog.action = 'zurückstellen';
          this.refuseDialog.hint =  `Bei einer Zurückstellung wird diese Bedarfsanmeldung aus
          dem Prozess der Zuteilungen entfernt. Sind Sie sich sicher dass diese Bedarfsanmeldung
          zurückgestellt werden soll?`;
          this.refuseDialog.icon = 'block';
          this.refuseDialog.color= 'error'
          this.refuseDialog.confirmEvent = 'reclineConfirm';
            this.$nextTick(() => {
                this.$refs.refuseDialog.open(bedarfsAnmeldung);
            });
        },
        openCommentDialog(bedarfsAnmeldung) {
          this.refuseDialog.title = 'Bedarfsanmeldung - Bemerkung hinzufügen';
          this.refuseDialog.action = 'Bemerkung hinzufügen';
          this.refuseDialog.hint =  `Hinzufügen einer Bemerkung für diese
          Bedarfsanmeldung, die beim Prozess der Verteilung berücksichtigt
          werden soll.`;
          this.refuseDialog.icon = 'create';
          this.refuseDialog.color= 'primary'
          this.refuseDialog.confirmEvent = 'commentConfirm';
            this.$nextTick(() => {
                this.$refs.refuseDialog.open(bedarfsAnmeldung);
            });
        },
        openRefuseDialog(bedarfsAnmeldung) {
          this.refuseDialog.title = 'Bedarfsanmeldung - Ablehnung';
          this.refuseDialog.action = 'ablehnen';
          this.refuseDialog.hint = `Bei einer Ablehnung wird diese Bedarfsanmeldung
          nicht Ihrer Betreuungseinrichtung zugewiesen. Die Betreuungseinrichtung, die
          von den Eltern als nächstes priorisiert wurde, erhält nun die Möglichkeit
          diese Kind aufzunehmen oder abzulehnen.`;
          this.refuseDialog.icon = 'block';
          this.refuseDialog.color= 'error'
          this.refuseDialog.confirmEvent = 'refuseConfirm';
            this.$nextTick(() => {
                this.$refs.refuseDialog.open(bedarfsAnmeldung);
            });
        },
        openFilterDialog(){
          let selected = [];
          this.einrichtungenSelected.forEach(item => {
            let index = this.einrichtungen.findIndex(x => x.id === item);
            if(index >= 0){
              selected.push({
                id: this.einrichtungen[index].id,
                name: this.einrichtungen[index].name
              })
            }
          })
          this.$refs.filterDialog.open(selected, !this.filterActive);
        },
        reclineRegistration(reason) {
          /**
            * Triggers when the recline registration was clicked.
            *
            * @property {object} reason exact doc on kita-bedarfsanmeldungen component
            */
            this.$emit('reclineRegistration', reason);
        },
        undoRecline(bedarfsanmeldung) {
          /**
            * Triggers when the undo recline registration was clicked.
            *
            * @property {string} id The id of the registration.
            * @property {string} numberOfChildren The number of children a registration has.
            * @property {string} benachrichtigungsstatus The send status of the registration.
            */
            this.$emit('undoRecline', bedarfsanmeldung.id, bedarfsanmeldung.name.length, bedarfsanmeldung.benachrichtigungsstatus);
        },
        refuseRegistration(reason){
          /**
            * Triggers when the refuse registration was clicked.
            *
            * @property {object} reason exact doc on kita-bedarfsanmeldungen component
            */
            this.$emit('refuseRegistration', reason);
        },
        assignRegistration(item) {
          /**
            * Triggers when the assign registration was clicked.
            *
            * @property {object} item exact doc on kita-bedarfsanmeldungen component
            */
            this.$emit('assignRegistration', {
                registrationId: item.bedarfsAnmeldung.id,
                numberOfChildren: item.bedarfsAnmeldung.name.length,
                assignTo: item.einrichtung,
                noPrio: item.noPrio,
                sendStatus: item.bedarfsAnmeldung.benachrichtigungsstatus,
                undo: item.undo
            });
        },
        declineRegistration(item) {
          /**
            * Triggers when the decline registration was clicked.
            *
            * @property {object} item exact doc on kita-bedarfsanmeldungen component
            */
            this.$emit('declineRegistration', {
                registrationId: item.bedarfsAnmeldung.id,
                numberOfChildren: item.bedarfsAnmeldung.name.length,
                assignTo: item.einrichtung,
                sendStatus: item.bedarfsAnmeldung.benachrichtigungsstatus
            });
        },
        addComment(item){
        /**
          * Triggers when the add comment was clicked.
          *
          * @property {object} item exact doc on kita-bedarfsanmeldungen component
          */
          this.$emit('addComment', item);
        },
        addFilter(s){
          if(s !== ''){
            this.filterActive = true;
            this.nameSelected = null;
            this.searchString(s);
          } else {
            if(this.filterActive){
              this.undoFilter();
            }
          }
        },
        undoFilter(){
          this.filterActive = false;
          this.einrichtungenSelected = [];
          if(this.einrichtungen.length === 1){
            this.einrichtungenSelected.push(this.einrichtungen[0].id);
          }
          this.$dispatch(this.$actions().bedarfsAnmeldung.calcProjection());
        },
        searchString(s){
          //Workaround vuetify data table bug where the total items in pagination
          // would not get updated when on page > 1 and applied filter
          //this.$refs.refTable.defaultPagination.page = 1;

          this.$dispatch(this.$actions().bedarfsAnmeldung.startSearch(s));
        },
        mapEinrichtung(id) {
            let item = this.einrichtungen.findIndex(i => i.id === id);
            if (item >= 0) {
                return this.einrichtungen[item];
            } else {
                return {
                    id: 'Undefiened',
                    name: 'Anderer Träger/Komune',
                    freiePlaetze: 0,
                    gesamtPlaetze: 0
                }
            }
        },
        getRegistration(id){
          let index = this.registrationsComplete.findIndex(x => x.id === id);
          if(index > -1){
            return this.registrationsComplete[index];
          }
          return null;
        },
        openExplanationDialog(title, text){
          this.explanationDialog.title = title;
          this.explanationDialog.text = text;
          this.$nextTick(() => {
            this.$refs.explanationDialog.open();
          });
        },
        emitArchive(registration){
        /**
          * Triggers when the archive registration was clicked.
          *
          * @property {object} registration The registration to archive.
          */
          this.$emit('emitArchive', registration);
        },
        emitUndoArchive(id){
          /**
            * Triggers when the undo archive registration was clicked.
            *
            * @property {string} id The id of the registration to undo archive.
            */
          this.$emit('emitUndoArchive', id);
        },
        emitReopenRegistration(obj){
          /**
            * Triggers when the undo archive registration was clicked.
            *
            * @property {string} id The id of the registration to undo archive.
            */
          this.$emit('emitReopenRegistration', obj);
        },
        emitInternalPriority(id, prio){
        /**
          * Triggers when the emit internal priority button was clicked
          *
          * @property {string} id The id of the registration to assign the priority to.
          * @property {number} prio The score of the internal priority (1 - 5 and null)
          */
          this.$emit('emitInternalPriority', id, prio);
        },
        openConfirmationDialog(title, text, confirmationAction = null){
          this.confirmationDialog.title = title;
          this.confirmationDialog.text = text;
          this.$nextTick(() => {
              this.$refs.confirmationDialog.open(confirmationAction);
          });
        },
        selectName(name){
          this.nameSelected = name;
        },
        openInfo(id){
          this.openAllInformationDialog(this.getRegistration(id));
        },
        openExplain(item){
          this.openExplanationDialog(item.title, item.text);
        },
        transformDate,
        calculateAddedHours
    },
    components: {
        AllInformationDialog,
        RefuseDialog,
        MenuComponent,
        TableCol,
        FilterDialog,
        ExplanationDialog,
        InternalPriority,
        AutocompleteNames,
        Tooltip,
        Button
    }
}
</script>
<style lang="css" scoped>
.noHover:hover{
  cursor: default !important;
  background-color: #ffffff !important;
}
</style>
