<template>
    <div class="dashboard">
        <v-snackbar v-model="snackbar" :color="snackbarColor">
            <v-icon color="white"> warning </v-icon>
            {{ snackbarText }}
            <Button color="white" @click="snackbar = false" icon>
                <v-icon> clear </v-icon>
            </Button>
        </v-snackbar>
        <v-navigation-drawer v-model="drawer" fixed temporary>
            <v-list dense>
                <v-list-item exact to="/dashboard">
                    <v-list-item-action>
                        <v-icon>help</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Kurzbeschreibung</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item exact to="/dashboard/aenderungs-uebersicht">
                    <v-list-item-action>
                        <v-icon>fiber_new</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Neuigkeiten</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader class="pt-4 pb-2">
                    <h3>Zuteilung</h3>
                </v-subheader>
                <v-list-item to="/dashboard/kita-bedarfsanmeldungen">
                    <v-list-item-action>
                        <v-icon>list_alt</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Bedarfsanmeldungen</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/anmeldungen/statistik">
                    <v-list-item-action>
                        <v-icon>assessment</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Statistik</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader class="pt-4 pb-2">
                    <h3>Post Zuteilung</h3>
                </v-subheader>
                <v-list-item
                    to="/dashboard/eltern-benachrichtigungen"
                    v-if="
                        permissions['Postkorbnachricht.send'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-action>
                        <v-icon>email</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Benachrichtigungen</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/bestaetigen"
                    v-if="
                        permissions['Bestaetigung.save'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-action>
                        <v-icon>check_circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Rücklauf</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/zugeteilte-bedarfsanmeldungen-export"
                >
                    <v-list-item-action>
                        <v-icon>cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Export</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/abgeschlossene-anmeldungen">
                    <v-list-item-action>
                        <v-icon>playlist_add_check</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Abgeschlossen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <h3>Archiv Funktionalitäten</h3>
                </v-subheader>
                <v-list-item
                    to="/dashboard/archiv"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>restore</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Archiv einsehen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/archiv-einleiten"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>playlist_add</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Archivierung einleiten</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/archiv-wiedereinsetzen"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>loop</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Archivierung wiedereinsetzen</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader class="pt-4 pb-2">
                    <h3>Admin Bereich</h3>
                </v-subheader>
                <v-list-item
                    to="/dashboard/traeger"
                    v-if="permissions.Traeger.includes('lesen / anzeigen')"
                >
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Träger</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/einrichtungsarten"
                    v-if="
                        permissions.Einrichtungsart.includes('lesen / anzeigen')
                    "
                >
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Einrichtungsarten
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/be-detailpflege"
                    v-if="permissions.Einrichtung.includes('lesen / anzeigen')"
                >
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Einrichtungen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/einrichtungen-bilder"
                    v-if="
                        permissions['Einrichtung.images'].includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-action>
                        <v-icon>add_photo_alternate</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Einrichtungen - Bilder</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/saisonparameter"
                    v-if="
                        permissions.Saisonparameter.includes('lesen / anzeigen')
                    "
                >
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Saisonparameter</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/mandantparameter"
                    v-if="
                        permissions.Mandantparameter.includes(
                            'lesen / anzeigen'
                        )
                    "
                >
                    <v-list-item-action>
                        <v-icon>settings</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Mandantparameter</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/dashboard/export">
                    <v-list-item-action>
                        <v-icon>cloud_download</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Admin - Export</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item
                    to="/dashboard/kennzahlen"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>assessment</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title> Kennzahlen </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader class="pt-4 pb-2">
                    <h3>Benutzerkonto</h3>
                </v-subheader>
                <v-list-item to="/dashboard/benutzerkonto">
                    <v-list-item-action>
                        <v-icon>account_circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Kontoinformation</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="navigateUserManagement">
                    <v-list-item-action>
                        <v-icon>reply</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Zur Kontoverwaltung</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <h3>Verschiedenes</h3>
                </v-subheader>
                <v-list-item
                    to="/dashboard/change-log"
                    v-if="['Superadmin', 'KV'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>repeat</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Change Log</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-subheader
                    class="pt-4 pb-2"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <h3>Testbereich</h3>
                </v-subheader>
                <v-list-item
                    to="/dashboard/bedarfsanmeldung-erstellung"
                    v-if="['Superadmin'].includes(user.role)"
                >
                    <v-list-item-action>
                        <v-icon>add_circle</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Anmeldung Erstellung</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item @click="logout">
                    <v-list-item-action>
                        <v-icon>exit_to_app</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Ausloggen</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            :clipped-left="$vuetify.breakpoint.lgAndUp"
            color="primary"
            dark
            fixed
        >
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"
                ><v-icon>menu</v-icon></v-app-bar-nav-icon
            >
            <v-app-bar-title
                ><img :src="logoKP" class="kp-logo"
            /></v-app-bar-title>
            <v-spacer></v-spacer>
            <Tooltip bottom>
                <template #default="attrs">
                    <span v-bind="attrs" class="pointer pr-2">
                        <v-icon small>timer</v-icon>
                        {{ timer | displayTimer }}
                    </span>
                </template>
                <template #content>
                    <span>Zeit bis zum Logout</span>
                </template>
            </Tooltip>
            <div>
                <span> {{ user.value.username }} </span> -
                <span>{{ date }}</span>
                <Tooltip bottom>
                    <template #default="attrs">
                        <Button v-bind="attrs" icon @click="logout">
                            <v-icon>exit_to_app</v-icon>
                        </Button>
                    </template>
                    <template #content>
                        <span>Abmelden</span>
                    </template>
                </Tooltip>
            </div>
        </v-app-bar>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
                <v-flex text-xs-center>
                    <router-view class="ma-15"></router-view>
                </v-flex>
            </v-layout>
        </v-container>
        <v-footer color="primary" app class="fullwidth text-center">
            <v-flex class="white--text"
                >All rights reserved &copy; 2023 | Version: v2.3.0</v-flex
            >
        </v-footer>
    </div>
</template>
<script>
import { transformDate } from '../../util/helper';
import Tooltip from '@s/views/general_components/components/Tooltip';
import Button from '@s/views/general_components/components/Button';

export default {
    name: 'dashboard',
    components: {
        Tooltip,
        Button,
    },
    data() {
        return {
            drawer: false,
            fullname: this.$select('user.value.fullname as fullname'),
            userIsFetching: this.$select('user.isFetching as userIsFetching'),
            userIsFetched: this.$select('user.fetched as userIsFetched'),
            user: this.$select('user as user'),
            date: null,
            permissions: this.$select('user.permissions as permissions'),
            loggedIn: this.$select('user.loggedIn as loggedIn'),
            snackbar: false,
            snackbarText: null,
            snackbarColor: 'error',
            preLink: 'https://kpba-pre.akdb.de/openam/',
            prodLink: 'https://kpba.akdb.de/openam/',
            timer: 7200,
            logoKP: require('../../assets/kp-logo-white.svg'),
        };
    },
    watch: {
        loggedIn: function (val) {
            if (!val) {
                this.snackbarText = `Session abgelaufen`;
                this.snackbar = true;
                this.$dispatch(this.$actions().auth.newLogout()).then(() => {
                    this.$dispatch(this.$actions().user.clearStore()).then(
                        () => {
                            setTimeout(() => {
                                this.$router.push('/');
                            }, 3000);
                        }
                    );
                });
            }
        },
    },
    methods: {
        logout() {
            localStorage.removeItem('timer');
            localStorage.removeItem('date');
            this.$dispatch(this.$actions().auth.newLogout()).then(() => {
                let x = this.user.value.csrfToken.parameterName;
                this.postwith('/user/logout', {
                    [x]: this.user.value.csrfToken.token,
                });
            });
        },
        postwith(to, p) {
            let myForm = document.createElement('form');
            myForm.method = 'post';
            myForm.action = to;
            for (var k in p) {
                var myInput = document.createElement('input');
                myInput.setAttribute('name', k);
                myInput.setAttribute('value', p[k]);
                myForm.appendChild(myInput);
            }
            document.body.appendChild(myForm);
            myForm.submit();
            document.body.removeChild(myForm);
        },
        navigateUserManagement() {
            let currentLocation = window.location.href;

            if (currentLocation.includes('-pre.akdb')) {
                const new_win = window.open(this.preLink, '_blank');
                new_win.focus();
            } else {
                const new_win = window.open(this.prodLink, '_blank');
                new_win.focus();
            }
        },
        saveTimer(timer) {
            const seconds = new Date().getTime() / 1000;
            localStorage.setItem('date', seconds);
            localStorage.setItem('timer', timer);
        },
        transformDate,
    },
    created() {
        let date = localStorage.getItem('date');
        let timer = localStorage.getItem('timer');

        if (date !== null && timer !== null) {
            let dateNow = new Date().getTime() / 1000;
            let difference = dateNow - date;
            timer = Math.round(timer - difference);
            if (timer > 0) {
                this.timer = timer;
            }
        }

        this.$dispatch(
            this.$actions().general.fetchEinrichtungsarten(
                () => {},
                (error) => {
                    if (error.status === 401) {
                        this.$dispatch(this.$actions().user.changeLogin());
                    }
                }
            )
        );
        this.date = this.transformDate(new Date().toISOString());

        // Does not work --> Request is not guaranteed to reach server
        //window.addEventListener('beforeunload', this.logout);
        window.setInterval(() => {
            this.timer--;
            if (this.timer <= 0) {
                this.logout();
            }
        }, 1000);
        this.saveTimer(this.timer);
    },
};
</script>

<style lang="stylus" scoped>
.kp-logo
    width 90px
    border-radius 5px
    border 2px solid transparent
    align-items center
    justify-content center
    margin-top 8px
</style>
