import {noop} from '../../util/helper'
import {
  GETepicFactory,
  POSTepicFactory,
  PUTepicFactory,
  DELETEepicFactory,
  mergeEpicFactory
} from '../../util/epicFactories';

let saisonparameterFields = {
  saisonid: '',
  ags: '',
  maxEinrichtungen: '',
  stichtagKind: '',
  anmeldezeitraum: {
    von: null,
    bis: null,
  },
  zuteilungszeitraum: {
    von: null,
    bis: null
  },
  benachrichtigungszeitraum: {
    von: null,
    bis: null
  },
  saisonzeitraum: {
    von: null,
    bis: null
  }
};

const prefix = `SAISONPARAMETER`;

export const FETCH_SAISONPARAMETER = `${prefix}/FETCH_SAISONPARAMETER`;
export const SEND_SAISONPARAMETER = `${prefix}/SEND_SAISONPARAMETER`;
export const CHANGE_SAISONPARAMETER = `${prefix}/CHANGE_SAISONPARAMETER`;
export const DELETE_SAISONPARAMETER = `${prefix}/DELETE_SAISONPARAMETER`;
export const CLEAR_SAISONPARAMETER = `${prefix}/CLEAR_SAISONPARAMETER`;

export const fetchSaisonparameterEpic = GETepicFactory(`${prefix}/FETCH_SAISONPARAMETER`, {
    url: (action) => `/api/v1/kita/saisonparameter?page=0&size=2147483647&ags=99101000`
})

export const sendSaisonparameterEpic = POSTepicFactory(`${prefix}/SEND_SAISONPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/saisonparameter`,
    body: (action) => action.payload
}, () => true, 201)

export const changeSaisonparameterEpic = PUTepicFactory(`${prefix}/CHANGE_SAISONPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/saisonparameter`,
    body: (action) => action.payload
});

export const deleteSaisonparameterEpic = DELETEepicFactory(`${prefix}/DELETE_SAISONPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/saisonparameter/${action.payload.id}`
})

export const fetchSaisonparameter = (success = noop, error = noop) => fetchSaisonparameterEpic.run({}, {success, error});

export const sendSaisonparameter = (traeger, headerKey, headerVal, success = noop, error = noop) => sendSaisonparameterEpic.run(
  {
    payload: traeger,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const changeSaisonparameter = (traeger, headerKey, headerVal, success = noop, error = noop) => changeSaisonparameterEpic.run(
  {
    payload: traeger,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const deleteSaisonparameter = (id, headerKey, headerVal, success = noop, error = noop) => deleteSaisonparameterEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const clearSaisonparameter = () => ({
    type: CLEAR_SAISONPARAMETER
});

const sendReloadEpic = mergeEpicFactory(sendSaisonparameterEpic.SUCCESS, () => fetchSaisonparameterEpic.run())
const changeReloadEpic = mergeEpicFactory(changeSaisonparameterEpic.SUCCESS, () => fetchSaisonparameterEpic.run())
const deleteReloadEpic = mergeEpicFactory(deleteSaisonparameterEpic.SUCCESS, () => fetchSaisonparameterEpic.run())

const ACTION_HANDLERS = {
  ...sendSaisonparameterEpic.ACTION_HANDLERS,
  ...changeSaisonparameterEpic.ACTION_HANDLERS,
  ...deleteSaisonparameterEpic.ACTION_HANDLERS,
  ...fetchSaisonparameterEpic.ACTION_HANDLERS,
  [fetchSaisonparameterEpic.SUCCESS]: (state, action) => {
    for(let i = 0; i < action.payload.response.length; ++i){
      Object.keys(saisonparameterFields).forEach(key => {
        if(typeof saisonparameterFields[key] === 'object' && action.payload.response[i][key] === null){
          action.payload.response[i][key] = saisonparameterFields[key];
        }
    })
  }
      return {
          ...state,
          saisonparameter: action.payload.response,
          isFetching: false
      }
  },
  [CLEAR_SAISONPARAMETER]: (state, action) => {
      return {
          ...initialState
      }
  },

}

export const actions = {
    sendSaisonparameter,
    changeSaisonparameter,
    deleteSaisonparameter,
    fetchSaisonparameter,
    clearSaisonparameter
};

export const epics = [
  sendSaisonparameterEpic.epic,
  changeSaisonparameterEpic.epic,
  deleteSaisonparameterEpic.epic,
  fetchSaisonparameterEpic.epic,
  sendReloadEpic.epic,
  changeReloadEpic.epic,
  deleteReloadEpic.epic
]

const initialState = {
  isFetching: false,
  saisonparameter: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
