<template>
    <v-dialog v-model="dialog" max-width="50vw">
        <v-card>
            <v-card-title class="primary headline" primary-title>
                <v-icon large style="color: white" class="pr-3">
                    help_outline
                </v-icon>
                <span style="color: white"> Erklärungsdialog - Zuteilung </span>
                <v-spacer></v-spacer>
                <Button large icon @click="close">
                    <v-icon color="white"> clear </v-icon>
                </Button>
            </v-card-title>
            <v-container class="mt-4">
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <v-alert :value="true" type="info" outlined>
                            Alle von Ihnen vorgenommenen Änderungen werden erst
                            aktiv und permanent gespeichert wenn Sie den
                            Speichern-Button drücken. Vorher sind alle
                            Änderungen nur lokal auf Ihrem Rechner vorhanden.
                            Die einizge Ausnahme bildet die Archivierung einer
                            Anmeldung. Diese wird sofort archiviert.
                        </v-alert>
                    </v-flex>
                </v-layout>
                <br />
                <br />
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="title primary--text darken-2">
                                        <b>Merkmale</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Merkmal</th>
                                <th>Bedeutung</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>a</b></span>
                                </td>
                                <td>
                                    Ein Vertreter des Kindes ist
                                    <b>alleinerziehend</b>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>ad</b></span>
                                </td>
                                <td>
                                    Ein <b>alternatives Aufnahmedatum</b> wurde
                                    gesetzt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>b</b></span>
                                </td>
                                <td>
                                    Eine <b>Bemerkung</b> wurde während des
                                    Zuteilungsprozesses hinzugefügt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>i</b></span>
                                </td>
                                <td>Es liegt <b>integrationsbedarf</b> vor.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>gk</b></span>
                                </td>
                                <td>Das Kind hat <b>Geschwisterkinder</b>.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>u</b></span>
                                </td>
                                <td>
                                    Es handelt sich um eine
                                    <b>unterjährige</b> Anmeldung.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>BT</b></span>
                                </td>
                                <td>
                                    <b>Beide</b> Vertreter sind
                                    <b>berufstätig</b>.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>bt</b></span>
                                </td>
                                <td>Ein Vertreter ist <b>berufstätig</b>.</td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text"><b>nh</b></span>
                                </td>
                                <td>
                                    Es liegt ein <b>neuer Hauptwohnsitz</b> vor.
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
                <br />
                <br />
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="title primary--text darken-2">
                                        <b>Farben</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Hintergrundfarbe</th>
                                <th>Bedeutung</th>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(0, 107, 3, 0.175);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    Die Anmeldung wurde einer Einrichtung
                                    <b>zugeteilt</b>.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(142, 0, 0, 0.4);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    Die Bedarfsanmeldung wurde
                                    <b>zurückgestellt</b>.
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="
                                        background-color: rgb(255, 255, 0, 0.2);
                                    "
                                >
                                    Bedarfsanmeldung
                                </td>
                                <td>
                                    <b>Alle</b> priorisierten Einrichtungen
                                    dieser Anmeldung haben <b>abgelehnt</b>.
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
                <br />
                <br />
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="title primary--text darken-2">
                                        <b>Aktionen</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Aktion</th>
                                <th>Auswirkung</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text">
                                        <b>Zurückstellung</b>
                                    </span>
                                </td>
                                <td>
                                    Die Anmeldung wird <b>zurückgestellt</b>. Es
                                    sollte nur aus bestimmten Gründen
                                    zurückgestellt werden. Z.B.: Die Eltern
                                    haben den Bedarf zurückgezogen.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text">
                                        <b>Bemerkung</b>
                                    </span>
                                </td>
                                <td>
                                    Eine <b>Bemerkung verfassen</b>. Diese
                                    Bemerkung wird zusammen mit der
                                    Bedarfsanmeldung gespeichert, wird den
                                    anderen Einrichtungen angezeigt und sollte
                                    beim Zuteilungsprozess beachtet werden. Sie
                                    wird nicht nacht außen kommuniziert.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text">
                                        <b>Archivierung</b>
                                    </span>
                                </td>
                                <td>
                                    Bei der Archivierung wird die
                                    Bedarfsanmeldung in ein spezielles
                                    <b>Archiv verschoben</b>
                                    und somit aus dem Zuteilungsprozess
                                    entfernt, nutzen Sie die Archivierung, um
                                    die Anmeldungen der vergangenen Saison zu
                                    archivieren oder um eine Bedarfsanmeldung
                                    aus der aktuellen Saison zu entfernen und
                                    somit eine ggf. erneute Anmeldung für das
                                    betroffene Kind möglich zu machen.
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
                <br />
                <br />
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="title primary--text darken-2">
                                        <b>Mögliche Nebeneffekte</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th>Aktion</th>
                                <th>Nebeneffekt</th>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text">
                                        <b>Zuteilung</b>
                                    </span>
                                </td>
                                <td>
                                    Sollte eine bereits
                                    <b> zugeteilte und benachrichtigte</b>
                                    Anmeldung neu zugeteilt werden, so wird ihr
                                    <b>Benachrichtigungsstatus</b> wieder auf
                                    <b>offen</b> gesetzt.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="primary--text">
                                        <b>Zuteilung</b>
                                    </span>
                                </td>
                                <td>
                                    Beim Speichern der Zuteilungen werden die
                                    <b>freien Plätze</b>
                                    der Einrichtungen dementsprechend
                                    verringert.
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
                <br />
                <br />
                <v-layout row wrap justify-center>
                    <v-flex xs12 md10>
                        <table style="width: 100%">
                            <tr>
                                <th colspan="6">
                                    <span class="title primary--text darken-2">
                                        <b>Interne Priorität</b>
                                    </span>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-xs-center">
                                    <v-icon color="success">looks_one</v-icon>
                                </th>
                                <td>
                                    <b>Sehr niedrige Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-xs-center">
                                    <v-icon color="success">looks_two</v-icon>
                                </th>
                                <td>
                                    <b>Niedrige Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-xs-center">
                                    <v-icon color="warning">looks_3</v-icon>
                                </th>
                                <td>
                                    <b>Mittlere Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-xs-center">
                                    <v-icon color="error">looks_4</v-icon>
                                </th>
                                <td>
                                    <b>Hohe Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                            <tr>
                                <th class="text-xs-center">
                                    <v-icon color="error darken-2"
                                        >looks_5</v-icon
                                    >
                                </th>
                                <td>
                                    <b>Sehr hohe Dringlichkeit</b> für eine
                                    Bearbeitung der Anmeldung
                                </td>
                            </tr>
                        </table>
                    </v-flex>
                </v-layout>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <Button raised color="primary" @click="close">
                    Schließen
                </Button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button';

export default {
    name: 'helper-dialog',
    components: {
        Button,
    },
    data() {
        return {
            dialog: false,
        };
    },
    methods: {
        open() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
    },
};
</script>
<style lang="stylus" scoped>
i
  font-weight: bold

td, th
  border: 1px solid #dddddd
  text-align: left
  padding: 8px

tr:nth-child(even)
  background-color: #dddddd
</style>
