import {
  noop,
  flattenRegistration,
  getEntity,
  dateToSeconds
} from '../../util/helper'
import {
  GETepicFactory,
} from '../../util/epicFactories';

const prefix = `CHANGE_LOG`;

export const FETCH_CHANGES = `${prefix}/FETCH_CHANGES`;
export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;

export const fetchChangeLogEpic = GETepicFactory(`${prefix}/FETCH_CHANGES`, {
    url: (action) => {

      let objektart = '';
      let aenderungsart = '';
      let username = '';
      let vorname = '';
      let nachname = '';

      if(action.payload.objektart !== null && action.payload.objektart !== ''){
        objektart = `&objektart=${action.payload.objektart}`
      }
      if(action.payload.aenderungsart !== null && action.payload.aenderungsart !== ''){
        aenderungsart = `&aenderungsart=${action.payload.aenderungsart}`
      }
      if(action.payload.username !== null && action.payload.username !== ''){
        username = `&username=${action.payload.username}`
      }
      if(action.payload.vorname !== null && action.payload.vorname !== ''){
        vorname = `&vorname=${action.payload.vorname}`
      }
      if(action.payload.nachname !== null && action.payload.nachname !== ''){
        nachname = `&nachname=${action.payload.nachname}`
      }

      return `/api/v1/kita/changelogs?page=0&size=2147483647&sort=CREATED&dir=DESC&von=${action.payload.start}&bis=${action.payload.end}${objektart}${aenderungsart}${username}${vorname}${nachname}`
    }
})
export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/names`
})

export const fetchChangeLog = (start, end, objektart, aenderungsart, username, vorname, nachname, success = noop, error = noop) => fetchChangeLogEpic.run({
  start: start,
  end: end,
  objektart: objektart,
  aenderungsart: aenderungsart,
  username: username,
  vorname: vorname,
  nachname: nachname
}, {success, error});

export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});

export const clearStore = () => dispatch => {
  dispatch({
      type: CLEAR_STORE,
    })
    return Promise.resolve();
};

const ACTION_HANDLERS = {
  ...fetchChangeLogEpic.ACTION_HANDLERS,
  [fetchChangeLogEpic.SUCCESS]: (state, action) => {

    let changes = [];

    action.payload.response.forEach((change, index) => {
      
      let flattened_new = flattenRegistration(state.einrichtungen, [], change.newValue);
      let flattened_old = flattenRegistration(state.einrichtungen, [], change.previousValue);
      let diff = {};
      Object.keys(flattened_new).forEach(key => {
        if(flattened_new[key] !== flattened_old[key]){
          diff[key] = {
            oldValue: typeof flattened_old[key] !== 'undefined' ? flattened_old[key] : null,
            newValue: typeof flattened_new[key] !== 'undefined' ? flattened_new[key] : null
          }
        }
      })
      changes.push({
        type: change.type,
        username: change.username,
        created: change.created,
        createdSeconds: dateToSeconds(change.created),
        entity: getEntity(change.newValue),
        diff: diff,
        registration: change.newValue,
        id: index
      })
    })

    return {
        ...state,
        isFetching: false,
        changes: changes
    }
  },
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: false
      }
  },
  [CLEAR_STORE]: (state, action) => {
      return {
          ...initialState
      }
  }
}

export const actions = {
    fetchChangeLog,
    clearStore,
    fetchEinrichtungen
};

export const epics = [
  fetchChangeLogEpic.epic,
  fetchEinrichtungenEpic.epic
]

const initialState = {
  isFetching: false,
  changes: [],
  einrichtungen: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
