import {combineReducers} from 'redux';
import {combineEpics} from 'redux-observable';
import {reducers as syncReducers, epics as syncEpics} from '../reducer/';

export const makeRootEpic = (asyncEpics) => {
    return combineEpics(...[...asyncEpics, ...syncEpics])
}

export const makeRootReducer = (asyncReducers) => {
    return combineReducers({
        ...syncReducers,
        ...asyncReducers
    })
}

export const injectReducer = (store, {key, reducer}) => {

    if (Object.hasOwnProperty.call(store.asyncReducers, key)) return

    store.asyncReducers[key] = reducer
    store.replaceReducer(makeRootReducer(store.asyncReducers))
    return store;
}

export default makeRootReducer
