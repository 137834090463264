import {
    noop,
    prepareRegistrations
} from '../../util/helper'
import {
    GETepicFactory,
    PUTepicFactory,
    mergeEpicFactory
} from '../../util/epicFactories';

const prefix = `BEDARFSANMELDUNG`;
export const FETCH_BEDARFSANMELDUNG = `${prefix}/FETCH_BEDARFSANMELDUNG`;
export const SEND_BEDARFSANMELDUNG = `${prefix}/SEND_BEDARFSANMELDUNG`;
export const SEND_CAPACITY = `${prefix}/SEND_CAPACITY`;
export const REFUSE_BEDARFSANMELDUNG = `${prefix}/REFUSE_BEDARFSANMELDUNG`;
export const ADD_COMMENT = `${prefix}/ADD_COMMENT`;
export const ASSIGN_BEDARFSANMELDUNG = `${prefix}/ASSIGN_BEDARFSANMELDUNG`;
export const DECLINE_BEDARFSANMELDUNG = `${prefix}/DECLINE_BEDARFSANMELDUNG`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const PROJECTION = `${prefix}/PROJECTION`;
export const SEARCH = `${prefix}/SEARCH`;
export const FETCH_SIZE = `${prefix}/FETCH_SIZE`;
export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const UPDATE_SEACH_INDEX = `${prefix}/UPDATE_SEACH_INDEX`;
export const CALC_CHANGED = `${prefix}/CALC_CHANGED`;
export const FETCH_ARCHIVE = `${prefix}/FETCH_ARCHIVE`;
export const SELECT_ONE_INDEX = `${prefix}/SELECT_ONE_INDEX`;
export const SELECT_MULTIPLE_BY_INDEX = `${prefix}/SELECT_MULTIPLE_BY_INDEX`;
export const FETCH_FINAL = `${prefix}/FETCH_FINAL`;
export const ASSIGN_INTERNAL_PRIORITY = `${prefix}/ASSIGN_INTERNAL_PRIORITY`;
export const SEND_BEDARFSANMELDUNG_FINISHED = `${prefix}/SEND_BEDARFSANMELDUNG_FINISHED`;
export const SEND_TYPE_CAPACITY = `${prefix}/SEND_TYPE_CAPACITY`;
export const RESET_REGISTRATION = `${prefix}/RESET_REGISTRATION`;

export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/einrichtungen?page=0&size=2147483647&sort=NAME&dir=ASC`
})
export const fetchBedarfsanmeldungEpic = GETepicFactory(`${prefix}/FETCH_BEDARFSANMELDUNG`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC&benachrichtigungsstatus=null,OFFEN,BENACHRICHTIGT,ZUBENACHRICHTIGEN,BENACHRICHTIGUNGSFEHLER`
})
export const fetchSizeEpic = GETepicFactory(`${prefix}/FETCH_SIZE`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen/count/Test`
})
export const sendBedarfsanmeldungEpic = PUTepicFactory(`${prefix}/SEND_BEDARFSANMELDUNG`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen/updateAll`,
    body: (action) => action.payload
})
export const sendCapacityEpic = PUTepicFactory(`${prefix}/SEND_CAPACITY`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/open-slots`,
    body: (action) => action.payload
})
export const fetchArchiveEpic = GETepicFactory(`${prefix}/FETCH_ARCHIVE`, {
    url: (action) => `/api/v1/kita/admin/archiv/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC`
})
export const fetchFinalEpic = GETepicFactory(`${prefix}/FETCH_FINAL`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC&benachrichtigungsstatus=ABGELEHNT,BESTAETIGT`
})
export const sendBedarfsanmeldungFinishedEpic = PUTepicFactory(`${prefix}/SEND_BEDARFSANMELDUNG_FINISHED`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen/updateAll`,
    body: (action) => action.payload
})
export const sendTypeCapacityEpic = PUTepicFactory(`${prefix}/SEND_TYPE_CAPACITY`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/open-slots-by-type`,
    body: (action) => action.payload
})


const sendReloadFinishedEpic = mergeEpicFactory(sendBedarfsanmeldungFinishedEpic.SUCCESS, () => fetchFinalEpic.run(0, 2147483647));

export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({}, {
    success,
    error
});
export const fetchSize = (success = noop, error = noop) => fetchSizeEpic.run({}, {
    success,
    error
});
export const fetchBedarfsanmeldung = (page, size, user_role = null, success = noop, error = noop) => fetchBedarfsanmeldungEpic.run({
    page: page,
    size: size,
    user_role: user_role
}, {
    success,
    error
});
export const sendBedarfsanmeldungen = (bedarfsAnmeldungen, headerKey, headerVal, success = noop, error = noop) => sendBedarfsanmeldungEpic.run(
  {
    payload: bedarfsAnmeldungen,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const sendCapacity = (capacity, headerKey, headerVal, success = noop, error = noop) => sendCapacityEpic.run(
  {
    payload: capacity,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const refuseBedarfsanmeldung = (registrationId, refuseReason, by = 1) => dispatch => {
    dispatch({
        type: REFUSE_BEDARFSANMELDUNG,
        payload: {
            registrationId: registrationId,
            refuseReason: refuseReason,
            by: by
        }
    });
    return Promise.resolve();
}
export const addComment = (registrationId, comment) => dispatch => {
    dispatch({
        type: ADD_COMMENT,
        payload: {
            registrationId: registrationId,
            comment: comment
        }
    });
    return Promise.resolve();
}
export const assignBedarfsanmeldung = (registrationId, einrichtung, by = 1, undo) => dispatch => {
    dispatch({
        type: ASSIGN_BEDARFSANMELDUNG,
        payload: {
            registrationId: registrationId,
            einrichtung: einrichtung,
            by: by,
            undo: undo
        }
    });
    return Promise.resolve();
};
export const declineBedarfsanmeldung = (registrationId, einrichtung, by = 1) => dispatch => {
    dispatch({
        type: DECLINE_BEDARFSANMELDUNG,
        payload: {
            registrationId: registrationId,
            einrichtung: einrichtung,
            by: by
        }
    });
    return Promise.resolve();
};
export const clearStore = () => ({
    type: CLEAR_STORE
});
export const startSearch = (input, and = false) => ({
    type: SEARCH,
    payload: {
        input: input,
        and: and
    }
});
export const calcProjection = () => dispatch => {
    dispatch({
        type: PROJECTION
    });
    return Promise.resolve();
};
export const searchRegistration = () => ({
    type: SEARCH
});
export const updateSearchIndex = (id, einrichtung) => dispatch => {
    dispatch({
        type: UPDATE_SEACH_INDEX,
        payload: {
            id: id,
            einrichtung: einrichtung
        }
    });
    return Promise.resolve();
};
export const calcChanged = () => dispatch => {
    dispatch({
        type: CALC_CHANGED,
        payload: {}
    });
    return Promise.resolve();
};
export const fetchArchive = (page = 0, size = 2147483647, success = noop, error = noop) => fetchArchiveEpic.run({
    page: page,
    size: size
}, {
    success,
    error
});
export const selectOneByIndex = (index) => ({
    type: SELECT_ONE_INDEX,
    payload: {
        index: index
    }
});
export const selectMultipleByIndex = (indices) => ({
    type: SELECT_MULTIPLE_BY_INDEX,
    payload: {
        indices: indices
    }
});
export const fetchFinal = (page = 0, size = 2147483647, success = noop, error = noop) => fetchFinalEpic.run({
    page: page,
    size: size
}, {
    success,
    error
});
export const assignInternalPriority = (id, prio) => dispatch => {
    dispatch({
        type: ASSIGN_INTERNAL_PRIORITY,
        payload: {
            id: id,
            prio: prio
        }
    });
    return Promise.resolve();
};
export const sendBedarfsanmeldungenFinished = (bedarfsAnmeldungen, headerKey, headerVal, success = noop, error = noop) => sendBedarfsanmeldungFinishedEpic.run(
    {
      payload: bedarfsAnmeldungen,
      headerKey: headerKey,
      headerVal: headerVal
    }, {success, error});
export const sendTypeCapacity = (capacity, headerKey, headerVal, success = noop, error = noop) => sendTypeCapacityEpic.run(
    {
        payload: capacity,
        headerKey: headerKey,
        headerVal: headerVal
    }, {success, error});
export const resetRegistration = (registration_id) => dispatch => {
    dispatch({
        type: RESET_REGISTRATION,
        payload: {
            registration_id: registration_id
        }
    });
    return Promise.resolve();
};
    

const ACTION_HANDLERS = {
    ...fetchBedarfsanmeldungEpic.ACTION_HANDLERS,
    [fetchBedarfsanmeldungEpic.SUCCESS]: (state, action) => {
      return prepareRegistrations(state, action);
    },
    [fetchBedarfsanmeldungEpic.FAIL]: (state, action) => {
        return {
            ...state,
            hasError: true,
            isFetching: false
        }
    },
    [REFUSE_BEDARFSANMELDUNG]: (state, action) => {
        let index = state.bedarfsAnmeldungen.findIndex(x => {
            return x.id === action.payload.registrationId;
        })
        if (index >= 0) {
            let newState = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
            let newStateEinrichtungen = JSON.parse(JSON.stringify(state.einrichtungen));
            let proj = JSON.parse(JSON.stringify(state.tableProjection));
            let projComplete = JSON.parse(JSON.stringify(state.tableFull));
            let decreaseId = state.decreaseId;
            let increaseId = state.increaseId;
            let type_capacaties_changed = state.type_capacaties_changed;

            // Registration was already assigned => decrement count
            if (newState[index].zugeteilteEinrichtung) {
                let i = newStateEinrichtungen.findIndex(x => {
                    return x.id === newState[index].zugeteilteEinrichtung
                })
                if (i >= 0) {
                    newStateEinrichtungen[i].freiePlaetze += action.payload.by;
                    let decreaseCounter = 0;
                    decreaseId.forEach(elem => {
                        if (elem === newState[index].zugeteilteEinrichtung) {
                            decreaseCounter++;
                        }
                    });
                    if (decreaseCounter >= action.payload.by) {
                        let r = 0;
                        for (let y = 0; y < decreaseId.length && r < action.payload.by; ++y) {
                            if (decreaseId[y] === newState[index].zugeteilteEinrichtung) {
                                decreaseId.splice(y, 1);
                                ++r;
                            }
                        }
                    } else {
                        for (let y = 0; y < action.payload.by; ++y) {
                            increaseId.push(newStateEinrichtungen[i].id);
                        }
                    }
                }

                if(newState[index].einrichtungsart !== null && newStateEinrichtungen[i].plaetzeProEinrichtungsart !== null
                && newStateEinrichtungen[i].plaetzeProEinrichtungsart.length > 0){
                    let ci = newStateEinrichtungen[i].plaetzeProEinrichtungsart.findIndex(x => x.einrichtungsart === newState[index].einrichtungsart);
                    if (ci >= 0){
                        newStateEinrichtungen[i].plaetzeProEinrichtungsart[ci].plaetze.freiePlaetze++;
                        type_capacaties_changed = type_capacaties_changed.filter(x => x !== newStateEinrichtungen[i].id);
                        type_capacaties_changed.push(newStateEinrichtungen[i].id);
                    } 
                }
            }

            if (newState[index].status === "ZURUECKGESTELLT") {
              newState[index].bemerkung = null;
              newState[index].status = "OFFEN";
              newState[index].zugeteilteEinrichtung = null;

              projComplete[index].bemerkung = null
              projComplete[index].status.value = "OFFEN";
              projComplete[index].zugeteilteEinrichtung = {
                  value: null,
                  id: null,
                  tooltip: 'Keine Zuteilung'
              };

              let indexProj = state.tableProjection.findIndex(x => {
                  return x.id === action.payload.registrationId;
              })
              if (indexProj >= 0) {
                  proj[indexProj].bemerkung = null;
                  proj[indexProj].status.value = "OFFEN";
                  proj[indexProj].zugeteilteEinrichtung = {
                      value: null,
                      id: null,
                      tooltip: 'Keine Zuteilung'
                  };
                  proj[indexProj].benachrichtigungsstatus = "OFFEN";
              }
            } else {
              newState[index].bemerkung = action.payload.refuseReason;
              newState[index].status = "ZURUECKGESTELLT";
              newState[index].zugeteilteEinrichtung = null;

              projComplete[index].bemerkung = action.payload.refuseReason;
              projComplete[index].status.value = "ZURUECKGESTELLT";
              projComplete[index].zugeteilteEinrichtung = {
                  value: null,
                  id: null,
                  tooltip: 'Keine Zuteilung'
              };

              let indexProj = state.tableProjection.findIndex(x => {
                  return x.id === action.payload.registrationId;
              })
              if (indexProj >= 0) {
                  proj[indexProj].bemerkung = action.payload.refuseReason;
                  proj[indexProj].status.value = "ZURUECKGESTELLT";
                  proj[indexProj].zugeteilteEinrichtung = {
                      value: null,
                      id: null,
                      tooltip: 'Keine Zuteilung'
                  };
                  proj[indexProj].benachrichtigungsstatus = "OFFEN";
              }
            }
            newState[index].benachrichtigungsstatus = "OFFEN";
            projComplete[index].benachrichtigungsstatus = "OFFEN"

            let newSearchFlat = state.searchIndex[index].searchFlat.filter(x => x !== 'OFFEN' && x !== 'GENEHMIGT' && x !== 'ZURUECKGESTELLT');
            newSearchFlat.push(newState[index].status);
            let newSearchIndex = state.searchIndex;
            newSearchIndex[index].searchFlat = newSearchFlat;

            let newChanged = state.changed.filter(x => x !== index);
            newChanged.push(index);
            return {
                ...state,
                bedarfsAnmeldungen: newState,
                tableProjection: proj,
                tableFull: projComplete,
                searchIndex: newSearchIndex,
                einrichtungen: newStateEinrichtungen,
                changed: newChanged,
                increaseId: increaseId,
                decreaseId: decreaseId,
                type_capacaties_changed: type_capacaties_changed
            }
        } else {
            return {
                ...state
            }
        }
    },
    [ASSIGN_BEDARFSANMELDUNG]: (state, action) => {

        // Search for a registration with the desired ID
        let index = state.bedarfsAnmeldungen.findIndex(x => {
            return x.id === action.payload.registrationId;
        })
        if (index >= 0 && action.payload.einrichtung !== null && action.payload.einrichtung.id !== null) {
            let newState = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
            let newStateEinrichtungen = JSON.parse(JSON.stringify(state.einrichtungen));
            let proj = JSON.parse(JSON.stringify(state.tableProjection));
            let projComplete = JSON.parse(JSON.stringify(state.tableFull));

            let increaseId = Array.from(state.increaseId);
            let decreaseId = Array.from(state.decreaseId);
            let type_capacaties_changed = Array.from(state.type_capacaties_changed);

            // Registration was already assigned
            if (newState[index].zugeteilteEinrichtung) {
                let i = newStateEinrichtungen.findIndex(x => {
                    return x.id === newState[index].zugeteilteEinrichtung
                })
                if (i >= 0) {
                    newStateEinrichtungen[i].freiePlaetze += action.payload.by;

                    // Calculate decreaseId Array for the bac
                    let decreaseCounter = 0;
                    decreaseId.forEach(elem => {
                        if (elem === newState[index].zugeteilteEinrichtung) {
                            decreaseCounter++;
                        }
                    });
                    if (decreaseCounter >= action.payload.by) {
                        let r = 0;
                        for (let y = 0; y < decreaseId.length && r < action.payload.by; ++y) {
                            if (decreaseId[y] === newState[index].zugeteilteEinrichtung) {
                                decreaseId.splice(y, 1);
                                ++r;
                                --y;
                            }
                        }
                    } else {
                        for (let y = 0; y < action.payload.by; ++y) {
                            increaseId.push(newState[index].zugeteilteEinrichtung);
                        }
                    }
                    if(newState[index].einrichtungsart !== null && newStateEinrichtungen[i].plaetzeProEinrichtungsart !== null
                    && newStateEinrichtungen[i].plaetzeProEinrichtungsart.length > 0){
                        let ci = newStateEinrichtungen[i].plaetzeProEinrichtungsart.findIndex(x => x.einrichtungsart === newState[index].einrichtungsart);
                        if (ci >= 0){
                            newStateEinrichtungen[i].plaetzeProEinrichtungsart[ci].plaetze.freiePlaetze++;
                            type_capacaties_changed = type_capacaties_changed.filter(x => x !== newStateEinrichtungen[i].id);
                            type_capacaties_changed.push(newStateEinrichtungen[i].id);
                        } 
                    }
                }

                // Assigned registration is the same as the new registration
                // Undo registration
                if (newState[index].zugeteilteEinrichtung === action.payload.einrichtung.id) {
                    newState[index].zugeteilteEinrichtung = null;
                    newState[index].status = "OFFEN";
                    newState[index].benachrichtigungsstatus = "OFFEN";
                    if(action.payload.undo === 'KV'){
                      newState[index].priorities.forEach(p => {
                          p.status = null;
                      })
                      projComplete[index].priorities.forEach(p => {
                        p.status = null;
                      })
                    }

                    if(action.payload.undo === 'ER'){
                      let i = newState[index].priorities.findIndex(x => x.id === action.payload.einrichtung.id);
                      for(let y = 0; y < newState[index].priorities.length; ++y){
                        if(y < i){
                          newState[index].priorities[y].status = "ABGELEHNT";
                          projComplete[index].priorities[y].status = "ABGELEHNT";
                        }
                        if(y >= i){
                          newState[index].priorities[y].status = null;
                          projComplete[index].priorities[y].status =null;
                        }
                      }
                    }

                    projComplete[index].zugeteilteEinrichtung = {
                        value: null,
                        id: null,
                        tooltip: 'Keine Zuteilung'
                    };
                    projComplete[index].status.value = "OFFEN";
                    projComplete[index].benachrichtigungsstatus = "OFFEN";

                    let indexProj = proj.findIndex(x => {
                        return x.id === action.payload.registrationId;
                    })
                    if (indexProj >= 0) {
                        proj[indexProj].zugeteilteEinrichtung = {
                            value: null,
                            id: null,
                            tooltip: 'Keine Zuteilung'
                        };
                        proj[indexProj].status.value = "OFFEN";
                        proj[indexProj].benachrichtigungsstatus = "OFFEN";
                        if(action.payload.undo === 'KV'){
                          proj[indexProj].priorities.forEach(p => {
                              p.status = null;
                          })
                        }

                        if(action.payload.undo === 'ER'){
                          let i = newState[index].priorities.findIndex(x => x.id === action.payload.einrichtung.id);
                          for(let y = 0; y < newState[index].priorities.length; ++y){
                            if(y < i){
                              proj[indexProj].priorities[y].status = "ABGELEHNT";
                            }
                            if(y >= i){
                              proj[indexProj].priorities[y].status = null;
                            }
                          }
                        }
                    }
                    let newSearchFlat = state.searchIndex[index].searchFlat.filter(x => x !== 'OFFEN' && x !== 'GENEHMIGT' && x !== 'ZURUECKGESTELLT');
                    newSearchFlat.push(newState[index].status);
                    let newSearchIndex = state.searchIndex;
                    newSearchIndex[index].searchFlat = newSearchFlat;

                    let newChanged = state.changed.filter(x => x !== index);
                    newChanged.push(index);
                    return {
                        ...state,
                        bedarfsAnmeldungen: newState,
                        tableProjection: proj,
                        tableFull: projComplete,
                        searchIndex: newSearchIndex,
                        einrichtungen: newStateEinrichtungen,
                        changed: newChanged,
                        increaseId: increaseId,
                        decreaseId: decreaseId,
                        type_capacaties_changed: type_capacaties_changed
                    }
                }
            }
            newState[index] = JSON.parse(JSON.stringify(newState[index]));
            newState[index].zugeteilteEinrichtung = action.payload.einrichtung.id;
            newState[index].status = "GENEHMIGT";
            newState[index].benachrichtigungsstatus = "OFFEN";
            newState[index].priorities.forEach(p => {
              if(p.id === action.payload.einrichtung.id){
                p.status = "ANGENOMMEN"
              } else {
                p.status = "ABGELEHNT"
              }
            })

            // Decrement count in the new assigned einrichtung
            let einrichtungName = null;
            let i = newStateEinrichtungen.findIndex(x => {
                return x.id === action.payload.einrichtung.id;
            })
            if (i >= 0) {
                newStateEinrichtungen[i] = JSON.parse(JSON.stringify(newStateEinrichtungen[i]));
                newStateEinrichtungen[i].freiePlaetze -= action.payload.by;

                let increaseCounter = 0;
                increaseId.forEach(elem => {
                    if (elem === newStateEinrichtungen[i].id) increaseCounter++;
                })
                if (increaseCounter >= action.payload.by) {
                    let r = 0;
                    for (let y = 0; y < increaseId.length && r < action.payload.by; ++y) {
                        if (increaseId[y] === newStateEinrichtungen[i].id) {
                            increaseId.splice(y, 1);
                            ++r;
                            --y;
                        }
                    }
                } else {
                    for (let y = 0; y < action.payload.by; ++y) {
                        decreaseId.push(newStateEinrichtungen[i].id)
                    }
                }
                einrichtungName = newStateEinrichtungen[i].name;

                if(newState[index].einrichtungsart !== null && newStateEinrichtungen[i].plaetzeProEinrichtungsart !== null
                && newStateEinrichtungen[i].plaetzeProEinrichtungsart.length > 0){
                    let ci = newStateEinrichtungen[i].plaetzeProEinrichtungsart.findIndex(x => x.einrichtungsart === newState[index].einrichtungsart);
                    if (ci >= 0){
                        newStateEinrichtungen[i].plaetzeProEinrichtungsart[ci].plaetze.freiePlaetze--;
                        type_capacaties_changed = type_capacaties_changed.filter(x => x !== newStateEinrichtungen[i].id);
                        type_capacaties_changed.push(newStateEinrichtungen[i].id);
                    } 
                }
            }

            projComplete[index].zugeteilteEinrichtung = {
                value: einrichtungName,
                id: action.payload.einrichtung.id,
                tooltip: `Zugeteilt an ${einrichtungName}`
            };
            projComplete[index].status.value = "GENEHMIGT";
            projComplete[index].benachrichtigungsstatus = "OFFEN";
            projComplete[index].priorities.forEach(p => {
              if(p.id === action.payload.einrichtung.id){
                p.status = "ANGENOMMEN"
              } else {
                p.status = "ABGELEHNT"
              }
            })

            let indexProj = proj.findIndex(x => {
                return x.id === action.payload.registrationId;
            })
            if (indexProj >= 0) {
                proj[indexProj].zugeteilteEinrichtung = {
                    value: einrichtungName,
                    id: action.payload.einrichtung.id,
                    tooltip: `Zugeteilt an ${einrichtungName}`
                };
                proj[indexProj].status.value = "GENEHMIGT";
                proj[indexProj].benachrichtigungsstatus = "OFFEN";
                proj[indexProj].priorities.forEach(p => {
                  if(p.id === action.payload.einrichtung.id){
                    p.status = "ANGENOMMEN"
                  } else {
                    p.status = "ABGELEHNT"
                  }
                })
            }
            let newSearchFlat = state.searchIndex[index].searchFlat.filter(x => x !== 'OFFEN' && x !== 'GENEHMIGT' && x !== 'ZURUECKGESTELLT');
            newSearchFlat.push(newState[index].status);
            let newSearchIndex = state.searchIndex;
            newSearchIndex[index].searchFlat = newSearchFlat;

            let newChanged = state.changed.filter(x => x !== index);
            newChanged.push(index);
            return {
                ...state,
                bedarfsAnmeldungen: newState,
                tableProjection: proj,
                tableFull: projComplete,
                searchIndex: newSearchIndex,
                einrichtungen: newStateEinrichtungen,
                changed: newChanged,
                increaseId: increaseId,
                decreaseId: decreaseId,
                type_capacaties_changed: type_capacaties_changed
            }
        }
        return {
            ...state
        }
    },
    [DECLINE_BEDARFSANMELDUNG]: (state, action) => {
      let index = state.bedarfsAnmeldungen.findIndex(x => {
          return x.id === action.payload.registrationId;
      })
      if (index >= 0) {
          let newState = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
          let proj = JSON.parse(JSON.stringify(state.tableProjection));
          let projComplete = JSON.parse(JSON.stringify(state.tableFull));

          newState[index].priorities.forEach(p => {
            if(p.id === action.payload.einrichtung.id){
              if(p.status === 'ABGELEHNT'){
                p.status = null;
              } else {
                p.status = "ABGELEHNT";
              }
            }
          })
          projComplete[index].priorities.forEach(p => {
            if(p.id === action.payload.einrichtung.id){
              if(p.status === 'ABGELEHNT'){
                p.status = null;
              } else {
                p.status = "ABGELEHNT";
              }
            }
          })

          let indexProj = state.tableProjection.findIndex(x => {
              return x.id === action.payload.registrationId;
          })
          if(indexProj >= 0){
            proj[indexProj].priorities.forEach(p => {
              if(p.id === action.payload.einrichtung.id){
                if(p.status === 'ABGELEHNT'){
                  p.status = null;
                } else {
                  p.status = "ABGELEHNT";
                }
              }
            })
            let newChanged = state.changed.filter(x => x !== index);
            newChanged.push(index);
            return {
              ...state,
              bedarfsAnmeldungen: newState,
              tableProjection: proj,
              tableFull: projComplete,
              changed: newChanged
            }
          }

      }
        return {
            ...state
        }
    },
    [sendBedarfsanmeldungEpic.START]: (state, action) => {
        return {
            ...state,
            isSending: true
        }
    },
    [sendBedarfsanmeldungEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            changed: [],
            bedarfsanmeldungenChanged: [],
            isSending: false,
            isFetching: false
        }
    },
    [sendBedarfsanmeldungEpic.FAIL]: (state, action) => {
        return {
            ...state,
            isSending: false
        }
    },
    [sendBedarfsanmeldungFinishedEpic.START]: (state, action) => {
        return {
            ...state,
            isSending: true
        }
    },
    [sendBedarfsanmeldungFinishedEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            changed: [],
            bedarfsanmeldungenChanged: [],
            isSending: false,
            isFetching: false
        }
    },
    [sendBedarfsanmeldungFinishedEpic.FAIL]: (state, action) => {
        return {
            ...state,
            isSending: false
        }
    },
    [CLEAR_STORE]: (state, action) => {
        return {
            ...initialState
        }
    },
    ...sendCapacityEpic.ACTION_HANDLERS,
    [sendCapacityEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            increaseId: [],
            decreaseId: [],
            isSending: false,
            isFetching: false
        }
    },
    ...fetchEinrichtungenEpic.ACTION_HANDLERS,
    [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            einrichtungen: action.payload.response
        }
    },
    [PROJECTION]: (state, action) => {
        let projection = state.tableFull.slice(0, state.bedarfsAnmeldungen.length)
        return {
            ...state,
            tableProjection: projection
        }
    },
    [fetchSizeEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            totalItems: action.payload.response
        }
    },
    [SEARCH]: (state, action) => {
        action.payload.input = action.payload.input.toUpperCase();
        let split = action.payload.input.split(", ")
        if(split[split.length - 1] === ''){
          split.pop();
        }
        let ages = split.filter(x => x.includes('$$AGE'));
        split = split.filter(x => !x.includes('$$AGE'));
        let result = [];
          state.searchIndex.forEach(x => {
              if (!split.some(v => !x.searchFlat.includes(v))) {
                if(ages.length === 0){
                  result.push(state.tableFull[x.index]);
                } else {

                  // Age --> range one hit is enough
                  if (ages.some(v => x.searchFlat.includes(v))) {
                      result.push(state.tableFull[x.index]);
                  }
                }
              }
          });
        return {
            ...state,
            tableProjection: result
        }
    },
    [UPDATE_SEACH_INDEX]: (state, action) => {
        let index = state.tableFull.findIndex(x => x.id === action.payload.id);
        if (index >= 0) {
            let newSearchIndex = state.searchIndex;
            let i = newSearchIndex[index].searchFlat.findIndex(x => x === action.payload.einrichtung.name.toUpperCase());
            if (i >= 0) {
                newSearchIndex[index].searchFlat = newSearchIndex[index].searchFlat.filter(x => x !== action.payload.einrichtung.name.toUpperCase());
            } else {
                newSearchIndex[index].searchFlat.push(action.payload.einrichtung.name.toUpperCase());
            }
            return {
                ...state,
                searchIndex: newSearchIndex
            }
        }
        return {
            ...state
        }
    },
    [CALC_CHANGED]: (state, action) => {
        let c = [];
        state.changed.forEach(changed => {
            c.push(state.bedarfsAnmeldungen[changed]);
        })
        return {
            ...state,
            bedarfsanmeldungenChanged: c
        }
    },
    [ADD_COMMENT]: (state, action) => {
        let index = state.bedarfsAnmeldungen.findIndex(x => x.id === action.payload.registrationId);
        if (index >= 0) {
            let registrations = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
            let tableFull = JSON.parse(JSON.stringify(state.tableFull));

            registrations[index].kindesdaten[0].bemerkungen = action.payload.comment;
            tableFull[index].comment = action.payload.comment;

            if(tableFull[index].iStatus !== null){
              let y = tableFull[index].iStatus.findIndex(x => x.value === 'b');
              if(y >= 0){
                if(action.payload.comment === '' || action.payload.comment === null){
                  tableFull[index].iStatus = tableFull[index].iStatus.filter(x => x.value !== 'b')
                  if(tableFull[index].iStatus.length === 0) tableFull[index].iStatus = null;
                }
              } else {
                tableFull[index].iStatus.push({
                  value: 'b',
                  tooltip: 'Bemerkung vorhanden',
                  highlight: true
                })
              }
            } else {
              tableFull[index].iStatus = [];
              tableFull[index].iStatus.push({
                value: 'b',
                tooltip: 'Bemerkung vorhanden',
                highlight: true
              })
            }

            let i = state.tableProjection.findIndex(x => x.id === action.payload.registrationId);
            if (i >= 0) {
                let tableProj = JSON.parse(JSON.stringify(state.tableProjection));
                tableProj[i].comment = action.payload.comment;
                if(tableProj[i].iStatus !== null){
                  let y = tableProj[i].iStatus.findIndex(x => x.value === 'b');
                  if(y >= 0){
                    if(action.payload.comment === '' || action.payload.comment === null){
                      tableProj[i].iStatus = tableProj[i].iStatus.filter(x => x.value !== 'b')
                      if(tableProj[i].iStatus.length === 0) tableProj[i].iStatus = null;
                    }
                  } else {
                    tableProj[i].iStatus.push({
                      value: 'b',
                      tooltip: 'Bemerkung vorhanden',
                      highlight: true
                    })
                  }
                } else {
                  tableProj[i].iStatus = [];
                  tableProj[i].iStatus.push({
                    value: 'b',
                    tooltip: 'Bemerkung vorhanden',
                    highlight: true
                  })
                }
                let newChanged = state.changed.filter(x => x !== index);
                newChanged.push(index);
                return {
                    ...state,
                    bedarfsAnmeldungen: registrations,
                    tableFull: tableFull,
                    tableProjection: tableProj,
                    changed: newChanged
                }
            }
        }
        return {
            ...state
        }
    },
    ...fetchArchiveEpic.ACTION_HANDLERS,
    [fetchArchiveEpic.SUCCESS]: (state, action) => {
      return prepareRegistrations(state, action);
    },
    [SELECT_ONE_INDEX]: (state, action) => {
      let tableProj = [state.tableFull[action.payload.index]];
      return {
          ...state,
          tableProjection: tableProj
      }
    },
    [SELECT_MULTIPLE_BY_INDEX]: (state, action) => {
      let tableProj = [];
      for(let i = 0; i < action.payload.indices.length; ++i){
        tableProj.push(state.tableFull[action.payload.indices[i]]);
      }
      return {
          ...state,
          tableProjection: tableProj
      }
    },
    ...fetchFinalEpic.ACTION_HANDLERS,
    [fetchFinalEpic.SUCCESS]: (state, action) => {
      return prepareRegistrations(state, action);
    },
    [ASSIGN_INTERNAL_PRIORITY]: (state, action) => {

        // Find registration with the desired ID
        let index = state.bedarfsAnmeldungen.findIndex(x => x.id === action.payload.id);
        if (index >= 0) {
            let registrations = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
            let tableFull = JSON.parse(JSON.stringify(state.tableFull));

            registrations[index].internalPriority = action.payload.prio;
            tableFull[index].internalPriority = action.payload.prio;

            let i = state.tableProjection.findIndex(x => x.id === action.payload.id);
            if (i >= 0) {
                let tableProj = JSON.parse(JSON.stringify(state.tableProjection));
                tableProj[i].internalPriority = action.payload.prio;

                let newChanged = state.changed.filter(x => x !== index);
                newChanged.push(index);
                return {
                    ...state,
                    bedarfsAnmeldungen: registrations,
                    tableFull: tableFull,
                    tableProjection: tableProj,
                    changed: newChanged
                }
            }
        }
        return {
            ...state
        }
    },
    ...sendTypeCapacityEpic.ACTION_HANDLERS,
    [sendTypeCapacityEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            isSending: false,
            isFetching: false
        }
    },
    [RESET_REGISTRATION]: (state, action) => {

        let index = state.bedarfsAnmeldungen.findIndex(x => x.id === action.payload.registration_id);
        if(index >= 0){
            let newState = JSON.parse(JSON.stringify(state.bedarfsAnmeldungen));
            let proj = JSON.parse(JSON.stringify(state.tableProjection));
            let projComplete = JSON.parse(JSON.stringify(state.tableFull));

            newState[index].zugeteilteEinrichtung = null;
            newState[index].priorities.forEach(p => p.status = null);
            newState[index].benachrichtigungsstatus = "OFFEN";

            projComplete[index].zugeteilteEinrichtung.id = null;
            projComplete[index].zugeteilteEinrichtung.value = null;
            projComplete[index].priorities.forEach(p => p.status = null);
            projComplete[index].benachrichtigungsstatus = "OFFEN";
            projComplete[index].status.value = "OFFEN";

            let i = state.tableProjection.findIndex(x => x.id === action.payload.registration_id);
            if(i >= 0){
                proj[i].zugeteilteEinrichtung.id = null;
                proj[i].zugeteilteEinrichtung.value = null;
                proj[i].priorities.forEach(p => p.status = null);
                proj[i].benachrichtigungsstatus = "OFFEN";
                proj[i].status.value = "OFFEN";

                let newChanged = state.changed.filter(x => x !== index);
                newChanged.push(index);

                return {
                    ...state,
                    bedarfsAnmeldungen: newState,
                    tableFull: projComplete,
                    tableProjection: proj,
                    changed: newChanged
                }
            }
        }
        return {
            ...state
        }
    },

}

export const actions = {
    fetchBedarfsanmeldung,
    refuseBedarfsanmeldung,
    assignBedarfsanmeldung,
    declineBedarfsanmeldung,
    sendBedarfsanmeldungen,
    clearStore,
    fetchEinrichtungen,
    calcProjection,
    fetchSize,
    startSearch,
    updateSearchIndex,
    calcChanged,
    addComment,
    sendCapacity,
    fetchArchive,
    selectOneByIndex,
    selectMultipleByIndex,
    fetchFinal,
    assignInternalPriority,
    sendBedarfsanmeldungenFinished,
    sendTypeCapacity,
    resetRegistration
};

export const epics = [
    fetchBedarfsanmeldungEpic.epic,
    sendBedarfsanmeldungEpic.epic,
    sendCapacityEpic.epic,
    fetchEinrichtungenEpic.epic,
    fetchSizeEpic.epic,
    fetchArchiveEpic.epic,
    fetchFinalEpic.epic,
    sendBedarfsanmeldungFinishedEpic.epic,
    sendReloadFinishedEpic.epic,
    sendTypeCapacityEpic.epic
]

const initialState = {
    bedarfsAnmeldungen: [],
    tableProjection: [],
    tableFull: [],
    einrichtungen: [],
    searchIndex: [],
    changed: [],
    bedarfsanmeldungenChanged: [],
    increaseId: [],
    decreaseId: [],
    type_capacaties_changed: [],
    isSending: false,
    hasError: false,
    isFetching: false,
    names: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
