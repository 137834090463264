import authReducer, {actions as authActions, epics as authEpics} from './user/auth';
import userReducer, {actions as userActions, epics as userEpics} from './user/user';
import bedarfsAnmeldungReducer, {actions as bedarfsAnmeldungActions, epics as bedarfsAnmeldungEpics} from './kita-bedarfsanmeldungen/bedarfsanmeldung';
import adminReducer, {actions as adminActions, epics as adminEpics} from './admin/admin';
import generalReducer, {actions as generalActions, epics as generalEpics} from './general';
import einrichtungsartReducer, {actions as einrichtungsartActions, epics as einrichtungsartEpics} from './admin/einrichtungsarten';
import traegerReducer, {actions as traegerActions, epics as traegerEpics} from './admin/traeger';
import saisonparameterReducer, {actions as saisonparameterActions, epics as saisonparameterEpics} from './admin/saisonparameter';
import mandantparameterReducer, {actions as mandantparameterActions, epics as mandantparameterEpics} from './admin/mandantparameter';
import exportReducer, {actions as exportActions, epics as exportEpics} from './kita-bedarfsanmeldungen/export';
import notificationReducer, {actions as notificationActions, epics as notificationEpics} from './kita-bedarfsanmeldungen/notification';
import confirmationReducer, {actions as confirmationActions, epics as confirmationEpics} from './kita-bedarfsanmeldungen/confirmation';
import imageReducer, {actions as imageActions, epics as imageEpics} from './admin/image';
import testReducer, {actions as testActions, epics as testEpics} from './test';
import archiveReducer, {actions as archiveActions, epics as archiveEpics} from './kita-bedarfsanmeldungen/archive';
import change_logReducer, {actions as change_logActions, epics as change_logEpics} from './admin/change-log';
import statisticsReducer, {actions as statisticsActions, epics as statisticsEpics} from './kita-bedarfsanmeldungen/statistics';
import kennzahlenReducer, {actions as kennzahlenActions, epics as kennzahlenEpics} from './admin/kennzahlen';

export const actions = {
    auth: authActions,
    user: userActions,
    bedarfsAnmeldung: bedarfsAnmeldungActions,
    admin: adminActions,
    general: generalActions,
    einrichtungsarten: einrichtungsartActions,
    traeger: traegerActions,
    saisonparameter: saisonparameterActions,
    mandantparameter: mandantparameterActions,
    export: exportActions,
    notification: notificationActions,
    confirmation: confirmationActions,
    image: imageActions,
    test: testActions,
    archive: archiveActions,
    change_log: change_logActions,
    statistics: statisticsActions,
    kennzahlen: kennzahlenActions
};

export const reducers = {
    auth: authReducer,
    user: userReducer,
    bedarfsAnmeldung: bedarfsAnmeldungReducer,
    admin: adminReducer,
    general: generalReducer,
    einrichtungsarten: einrichtungsartReducer,
    traeger: traegerReducer,
    saisonparameter: saisonparameterReducer,
    mandantparameter: mandantparameterReducer,
    export: exportReducer,
    notification: notificationReducer,
    confirmation: confirmationReducer,
    image: imageReducer,
    test: testReducer,
    archive: archiveReducer,
    change_log: change_logReducer,
    statistics: statisticsReducer,
    kennzahlen: kennzahlenReducer
}

export const epics = [
    ...authEpics,
    ...userEpics,
    ...bedarfsAnmeldungEpics,
    ...adminEpics,
    ...generalEpics,
    ...einrichtungsartEpics,
    ...traegerEpics,
    ...saisonparameterEpics,
    ...mandantparameterEpics,
    ...exportEpics,
    ...notificationEpics,
    ...confirmationEpics,
    ...imageEpics,
    ...testEpics,
    ...archiveEpics,
    ...change_logEpics,
    ...statisticsEpics,
    ...kennzahlenEpics
]
