<template>
<div>
  <v-autocomplete
      ref="autocomplete"
      v-model="nameSelected"
      label="Suche"
      :items="names"
      item-text="name"
      item-value="id"
      prepend-icon="search"
      no-data-text="Keine Anmeldung gefunden"
      append-icon="info"
      @click:append="emitExplain"
      :disabled="loading"
      clearable
      multiple
      :search-input.sync="searchInput"
      @change="searchInput=''"
  >
      <template v-slot:item="data" class="py-4">
          <v-list-item-avatar class="mr-4" style="border-right: 1px solid lightgray;">
            <v-icon v-if="data.item.isParent" color="primary">group</v-icon>
            <v-icon v-if="data.item.isChild" color="primary">child_care</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title><span>{{ data.item.lastName }} </span><span style="color: #616161">{{ data.item.firstName }}</span></v-list-item-title>
            <v-list-item-subtitle>
                <span v-if="data.item.parentOf !== null" class="caption">
                <b>Vertreter von: </b>{{ data.item.parentOf }}
                </span>
                <span v-if="data.item.parentOf === null && data.item.age !== null" class="caption">
                {{ data.item.age }}
                </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
          <Button icon ripple>
              <v-icon color="primary lighten-1" @click.stop @click="emitInfo(data.item.registrationID)">
              info
              </v-icon>
          </Button>
          </v-list-item-action>
          
      </template>
      <template v-slot:selection="data">
          <v-chip 
            v-if="data.index === 0 || data.index === 1"
            color="primary" 
            small
            close
            @click="data.select"
            @click:close="removeName(data.item)"
          >
          <span class="white--text">
              <v-icon color="white" small v-if="data.item.isChild">child_care</v-icon>
              <v-icon color="white" small v-if="data.item.isParent">group</v-icon>
              {{ data.item.firstName }} {{ data.item.lastName }}
          </span>
          </v-chip>
          <span
          v-if="data.index == 2"
          class="grey--text caption"
          >(+{{ nameSelected.length - 2 }} andere)</span>
      </template>
  </v-autocomplete>
  </div>
</template>

<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "autocomplete-names",
    components: {
      Button
    },
    data() {
      return {
          nameSelected: '',
          searchInput: ''
      }
    },
    props: {
      names: {
        type: Array,
        required: true
      },
      loading: {
          type: Boolean,
          required: false
      }
    },
    watch: {
      nameSelected: function (val) {
        this.$emit('select', val)
      }
    },

    methods: {
      emitExplain(){
        this.$emit('openExplain', {title: 'Suche', text: 'Nutzen Sie die Suche, um nach einer bestimmten Bedarfsanmeldung mit dem Vornamen oder Nach- und Vornnamen des Kindes oder dem Nach- und Vornamen der Eltern zu suchen. Sie können mehrere Anmeldungen auswählen.'});
      },
      removeName(item){
          this.nameSelected = this.nameSelected.filter(x => x !== item.id);
      },
      emitInfo(id){
        this.$emit('openInfo', id)
      }
    }
}
</script>
