<template>
<div>
  <table style="width: 100%">
      <tr>
          <th colspan="5">
            <span class="title primary--text darken-2">
              <b>Betreuungszeiten</b>
            </span>
          </th>
      </tr>
      <tr>
          <th>Montag</th>
          <th>Dienstag</th>
          <th>Mittwoch</th>
          <th>Donnerstag</th>
          <th>Freitag</th>
      </tr>
      <tr>
          <td v-for="(item, key, index) in betreuungszeiten" v-if="key !== 'samstag' && key !== 'sonntag'" :key="key">
            <div align="center">
              <span v-if="item !== null && (item.von !== null || item.bis !== null)">
                <span v-if="item.von !== null">{{item.von | cutSeconds }}</span> -
                <span v-if="item.bis !== null">{{item.bis | cutSeconds }}</span>
              </span>
              <Tooltip top v-else>
                  <v-icon class="pointer">remove</v-icon>
                <template #content>
                  <span>Keine Angabe</span>
                </template>
              </Tooltip>
            </div>
          </td>
      </tr>
      <tr>
          <td v-for="(item, key, index) in betreuungszeiten" v-if="key !== 'samstag' && key !== 'sonntag'" :key="key">
            <div align="center">
              <Tooltip top v-if="item !== null && item.mittagessen">
                  <v-icon class="pointer">restaurant_menu</v-icon>
                <template #content>
                  <span>Mittagessen gebucht</span>
                </template>
              </Tooltip>
              <Tooltip top v-else>
                  <v-icon class="pointer">remove</v-icon>
                <template #content>
                  <span>Kein Mittagessen gebucht</span>
                </template>
              </Tooltip>
            </div>
          </td>
      </tr>
  </table>
</div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip'

export default {
    name: "betreuungszeiten-table",
    components: {
      Tooltip
    },
    props: {
      betreuungszeiten: {
        type: Object,
        required: true
      }
    }
}
</script>

<style lang="stylus" scoped>
    i
      font-weight: bold

    td, th
      border: 1px solid #dddddd
      text-align: left
      padding: 8px

    tr:nth-child(even)
      background-color: #dddddd

</style>
