var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("keyboard_arrow_down")])]},proxy:true}])},[_c('span',{staticClass:"title white--text"},[_vm._v(_vm._s(_vm._f("incrementOne")(_vm.index))+". Vertreter -\n                "+_vm._s(_vm.vertreter.vorname)+" "+_vm._s(_vm.vertreter.name)+"\n            ")])]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"mt-6"},[_c('v-row',{staticClass:"mt-6 mb-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"border rounded-lg mx-10",staticStyle:{"border":"1px solid lightgray","box-shadow":"none"}},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('v-icon',{staticClass:"ml-6",attrs:{"large":"","color":"primary"}},[_vm._v("face")])],1),_vm._v(" "),_c('div',{staticStyle:{"flex-grow":"1"}},[_c('v-card-title',{staticClass:"pb-0",staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"title font-weight-bold primary--text ml-5"},[_vm._v("\n                                        "+_vm._s(_vm.vertreter.vorname)+"\n                                        "+_vm._s(_vm.vertreter.name)+"\n                                        "),(
                                                _vm.vertreter.geschlecht ===
                                                'MAENNLICH'
                                            )?_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("männlich")])]},proxy:true}],null,false,1706702664)},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$male")])],1)],1):_vm._e(),_vm._v(" "),(
                                                _vm.vertreter.geschlecht ===
                                                'WEIBLICH'
                                            )?_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("weiblich")])]},proxy:true}],null,false,2943045560)},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$female")])],1)],1):_vm._e(),_vm._v(" "),(
                                                _vm.vertreter.geschlecht ===
                                                'DIVERS'
                                            )?_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("divers")])]},proxy:true}],null,false,2446740752)},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("$divers")])],1)],1):_vm._e(),_vm._v(" "),(
                                                _vm.vertreter.geburtsname !==
                                                null
                                            )?_c('span',{staticClass:"subtitle-2",staticStyle:{"color":"gray"}},[_vm._v("(geb.\n                                            "+_vm._s(_vm.vertreter.geburtsname)+")")]):_vm._e()],1)]),_vm._v(" "),_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"subtitle-2",staticStyle:{"color":"gray"}},[_c('div',{staticClass:"ml-5 py-0"},[_vm._v("\n                                            "+_vm._s(_vm.capitalizedVertreterArt)+"\n                                        ")])])])],1)]),_vm._v(" "),_c('v-divider',{staticClass:"mx-4"}),_vm._v(" "),_c('v-card-text',{staticClass:"mx-12 subtitle-1"},[_c('div',{staticClass:"d-xl-flex flex-column flex-xl-row"},[_c('div',{staticStyle:{"flex":"1"}},[_c('div',{staticClass:"mb-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Geburtsdatum,\n                                                    Geburtsort")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("cake")])],1)],1),_vm._v(" "),(
                                                _vm.vertreter.geburtsdatum &&
                                                _vm.vertreter.geburtsdatum.trim() !==
                                                    ''
                                            )?_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("\n                                            "+_vm._s(_vm._f("transformDate")(_vm.vertreter.geburtsdatum))+"\n                                            "),(
                                                    _vm.vertreter.geburtsort &&
                                                    _vm.vertreter.geburtsort.trim() !==
                                                        ''
                                                )?_c('span',[_vm._v("\n                                                in\n                                                "+_vm._s(_vm.vertreter.geburtsort)+"\n                                            ")]):_vm._e()]):_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("-")])],1),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Staatsangehörigkeit")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("public")])],1)],1),_vm._v(" "),(
                                                _vm.vertreter.staatsangehoerigkeit &&
                                                _vm.vertreter.staatsangehoerigkeit.trim() !==
                                                    ''
                                            )?_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("\n                                            "+_vm._s(_vm.vertreter.staatsangehoerigkeit)+"\n                                        ")]):_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("-")])],1),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Herkunftsland")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("flag")])],1)],1),_vm._v(" "),(
                                                _vm.vertreter.herkunftsland &&
                                                _vm.vertreter.herkunftsland.trim() !==
                                                    ''
                                            )?_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("\n                                            "+_vm._s(_vm.vertreter.herkunftsland)+"\n                                        ")]):_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("-")])],1),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Familienstand")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("people")])],1)],1),_vm._v(" "),(
                                                _vm.vertreter.familienstand &&
                                                _vm.vertreter.familienstand.trim() !==
                                                    ''
                                            )?_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("\n                                            "+_vm._s(_vm.vertreter.familienstand.toLowerCase())+"\n                                        ")]):_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("-")])],1)]),_vm._v(" "),_c('div',{staticClass:"kontakt",staticStyle:{"flex":"1"}},[(_vm.vertreter.hauptwohnsitz)?_c('div',{staticClass:"mb-2"},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Hauptwohnsitz")])]},proxy:true}],null,false,1780119613)},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("home")])],1)],1),_vm._v(" "),_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("\n                                            "+_vm._s(_vm.vertreter.hauptwohnsitz
                                                    .strasse)+"\n                                            "+_vm._s(_vm.vertreter.hauptwohnsitz
                                                    .hausnummer)+",\n                                            "+_vm._s(_vm.vertreter.hauptwohnsitz
                                                    .postleitzahl)+"\n                                            "+_vm._s(_vm.vertreter.hauptwohnsitz.ort)+"\n                                            "),(
                                                    _vm.vertreter.hauptwohnsitz
                                                        .ortsteil
                                                )?_c('span',[_vm._v("\n                                                ("+_vm._s(_vm.vertreter.hauptwohnsitz
                                                        .ortsteil)+")\n                                            ")]):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mb-2"},[_c('div',{staticStyle:{"display":"flex","align-items":"flex-start"}},[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("Kontaktnummern")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("call")])],1)],1),_vm._v(" "),(
                                                    _vm.vertreter.kontaktnummern !==
                                                        null &&
                                                    _vm.vertreter.kontaktnummern
                                                        .length > 0
                                                )?_c('div',{staticStyle:{"color":"black","font-weight":"500"}},_vm._l((_vm.vertreter.kontaktnummern),function(nummer){return _c('div',{key:nummer.kontaktnummernArt,staticStyle:{"margin-bottom":"5px"}},[_vm._v("\n                                                    "+_vm._s(_vm._f("toCamelCase")(nummer.kontaktnummernArt))+": "+_vm._s(nummer.nummer)+"\n                                                ")])}),0):_c('div',{staticClass:"ml-1",staticStyle:{"color":"black"}},[_vm._v("\n                                                -\n                                            ")])],1)]),_vm._v(" "),_c('div',[_c('Tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',[_vm._v("E-Mail")])]},proxy:true}])},[_c('Button',{attrs:{"medium":"","icon":""}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"lightgray"}},[_vm._v("mail_outline")])],1)],1),_vm._v(" "),(_vm.vertreter.email)?_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v(_vm._s(_vm.vertreter.email))]):_c('span',{staticStyle:{"color":"black","font-weight":"500"}},[_vm._v("-")])],1)])])]),_vm._v(" "),(
                                (_vm.vertreter.bemerkungen !== null &&
                                    _vm.vertreter.bemerkungen.length > 0) ||
                                _vm.vertreter.neuerHauptwohnsitz ||
                                _vm.vertreter.umzugsdatum !== null ||
                                _vm.vertreter.elternzeit !== null
                            )?_c('v-card-text',{staticClass:"mx-12",staticStyle:{"color":"gray"}},[(
                                    _vm.vertreter.bemerkungen !== null &&
                                    _vm.vertreter.bemerkungen.length > 0
                                )?_c('div',{staticClass:"mb-2 subtitle-2"},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" info ")])],1),_vm._v(" "),_c('span',{staticClass:"ml-1"},[_vm._v("Bemerkung vorhanden:\n                                    "+_vm._s(_vm.vertreter.bemerkungen))])]):_vm._e(),_vm._v(" "),(
                                    _vm.vertreter.neuerHauptwohnsitz !== null ||
                                    _vm.vertreter.umzugsdatum !== null
                                )?_c('div',{staticClass:"mb-2 subtitle-2"},[_c('div',{staticStyle:{"display":"flex"}},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1),_vm._v(" "),_c('div',[(
                                                _vm.vertreter.neuerHauptwohnsitz
                                            )?_c('div',{staticClass:"ml-2"},[_c('b',[_vm._v("Neuer Hauptwohnsitz:")]),_vm._v("\n                                            "+_vm._s(_vm.vertreter.neuerHauptwohnsitz
                                                    .strasse)+"\n                                            "+_vm._s(_vm.vertreter.neuerHauptwohnsitz
                                                    .hausnummer)+",\n                                            "+_vm._s(_vm.vertreter.neuerHauptwohnsitz
                                                    .postleitzahl)+"\n                                            "+_vm._s(_vm.vertreter.neuerHauptwohnsitz
                                                    .ort)+"\n                                            "),(
                                                    _vm.vertreter
                                                        .neuerHauptwohnsitz
                                                        .ortsteil
                                                )?_c('span',[_vm._v("\n                                                ("+_vm._s(_vm.vertreter
                                                        .neuerHauptwohnsitz
                                                        .ortsteil)+")\n                                            ")]):_vm._e()]):_vm._e(),_vm._v(" "),(
                                                _vm.vertreter.umzugsdatum !==
                                                null
                                            )?_c('div',{staticClass:"ml-2"},[_c('b',[_vm._v("Umzugsdatum:")]),_vm._v("\n                                            "+_vm._s(_vm._f("transformDate")(_vm.vertreter.umzugsdatum))+"\n                                        ")]):_vm._e()])])]):_vm._e(),_vm._v(" "),(_vm.vertreter.elternzeit !== null)?_c('div',[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"mr-2"},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1)]),_vm._v(" "),_c('div',[(_vm.vertreter.elternzeit.von)?_c('div',{staticClass:"subtitle-2"},[_c('b',[_vm._v("Elternzeit: ")]),_vm._v("\n                                            Von\n                                            "+_vm._s(_vm._f("transformDate")(_vm.vertreter.elternzeit.von))+"\n\n                                            "),(
                                                    _vm.vertreter.elternzeit.bis
                                                )?_c('span',[_vm._v("\n                                                bis\n                                                "+_vm._s(_vm._f("transformDate")(_vm.vertreter.elternzeit.bis))+"\n                                            ")]):_vm._e()]):_vm._e()])])]):_vm._e()]):_vm._e()],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pr-6",attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"width":"100%","border":"none","box-shadow":"none"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1"}},[_vm._v("\n                                    Sorgeberechtigung\n                                ")])]),_vm._v(" "),[_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                        Alleinerziehend\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(_vm.vertreter.alleinerziehend)?[_c('span',[_vm._v("ja")])]:(
                                                _vm.vertreter.alleinerziehend ==
                                                false
                                            )?[_c('span',[_vm._v("nein")])]:[_c('span',[_vm._v("-")])]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                        Allein sorgeberechtigt\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                _vm.vertreter.personSorgeberechtigt
                                            )?[_c('span',[_vm._v("ja")])]:(
                                                _vm.vertreter.personSorgeberechtigt ==
                                                false
                                            )?[_c('span',[_vm._v("nein")])]:[_c('span',[_vm._v("-")])]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                        Negativbescheid vorliegend\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                _vm.vertreter.negativbescheidLiegtVor
                                            )?[_c('span',[_vm._v("ja")])]:_vm._e(),_vm._v(" "),(
                                                _vm.vertreter.negativbescheidLiegtVor ==
                                                false
                                            )?[_c('span',[_vm._v("nein")])]:[_c('span',[_vm._v("-")])]],2)])],_vm._v(" "),_c('div',{staticClass:"d-flex mb-2 mt-4"},[_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1"}},[_vm._v("\n                                    Berufliche Informationen\n                                ")])]),_vm._v(" "),[_c('div',{staticClass:"d-flex mb-2 even-row"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                        Berufstätig\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(_vm.vertreter.berufstaetig)?[_vm._v("ja")]:(
                                                _vm.vertreter.berufstaetig ==
                                                false
                                            )?[_vm._v("\n                                            nein\n                                        ")]:[_vm._v("-")]],2)]),_vm._v(" "),_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                        Arbeitgeber\n                                    ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                _vm.vertreter.arbeitgeber !==
                                                null
                                            )?[_vm._v(_vm._s(_vm.vertreter.arbeitgeber))]:[_c('span',[_vm._v("-")])]],2)])]],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }