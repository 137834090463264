<template>
<v-dialog v-model="dialog" max-width="80vw">
    <v-card>
        <v-card-title :class="color" class="headline" primary-title>
            <v-icon large style="color: white" class="pr-3">
                {{ icon }}
            </v-icon>
            <span style="color: white">
              {{ title }}
          </span>
            <v-spacer></v-spacer>
            <Button large icon @click="close">
                <v-icon color="white">
                    clear
                </v-icon>
            </Button>
        </v-card-title>
        <v-container grid-list>
            <v-layout row wrap class="mt-2">
                <v-flex xs6>
                    <h3 class="text-center darken-2">Kind:
                    <span>{{ children }}</span>
                  </h3>
                </v-flex>
                <v-flex xs6>
                    <h3 class="text-center darken-2">Status:
                    <span v-if="bedarfsAnmeldung.status">{{bedarfsAnmeldung.status.value}}</span>
                    <span color="error" v-else> - </span>
                  </h3>
                </v-flex>
                <v-flex xs12 md10 offset-md1>
                  <v-alert
                    class="mt-5"
                    :value="hint !== null"
                    :type="color === 'error' ? 'error' : 'info'"
                    style="margin-bottom: 0"
                    outlined
                  >
                    {{ hint }}
                  </v-alert>
                    <v-textarea
                      style="margin-top: 0"
                      class="pt-5"
                      outlined
                      name="refuseReason"
                      :label="color === 'primary' ? 'Verfassen der Bemerkung' : 'Begründen Sie Ihre Entscheidung'"
                      no-resize
                      v-model="refuseReason"
                      color="black">
                    </v-textarea>
                </v-flex>
            </v-layout>
        </v-container>
        <v-card-actions>
            <v-spacer></v-spacer>
            <Button
              raised
              color="error"
              @click="deleteBemerkung"
              v-if="confirmEvent === 'commentConfirm' || confirmEvent === 'reasonConfirm'"
              :disabled="!deletable">
                Löschen
            </Button>
            <Button
              raised
              :color="color"
              @click="confirm"
              :disabled="refuseReason === null || refuseReason === ''">
                {{ action }}
            </Button>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "refuse-dialog",
    components: {
      Button
    },
    data() {
        return {
            dialog: false,
            refuseReason: '',
            bedarfsAnmeldung: {},
            children: '',
            deletable: false
        }
    },
    props: {
      title: {
        type: String,
        default: 'Bedarfsanmeldung - Zurückstellung'
      },
      action: {
        type: String,
        default: 'zurückstellen'
      },
      hint: {
        type: String,
        default: null
      },
      confirmEvent: {
        type: String,
        required: true
      },
      icon: {
        type: String,
        default: 'block'
      },
      color: {
        type: String,
        default: 'headline error darken-2'
      }
    },
    methods: {
        open(bedarfsAnmeldung) {
            this.bedarfsAnmeldung = bedarfsAnmeldung;
            if(this.confirmEvent === "reclineConfirm"){
              this.refuseReason = this.bedarfsAnmeldung.bemerkung;
            }
            if(this.confirmEvent === "reasonConfirm"){
              if(this.bedarfsAnmeldung.decision.value !== null &&
                this.bedarfsAnmeldung.decision.value !== '') this.deletable = true;
              this.refuseReason = this.bedarfsAnmeldung.decision.value;
            }
            if(this.confirmEvent === "commentConfirm"){
              if(this.bedarfsAnmeldung.comment !== null &&
                this.bedarfsAnmeldung.comment !== '') this.deletable = true;
              this.refuseReason = this.bedarfsAnmeldung.comment;
            }

            this.bedarfsAnmeldung.name.forEach(child => {
              this.children += `${child.value} `
            });
            this.dialog = true;
        },
        close() {
            this.refuseReason = '';
            this.bedarfsAnmeldung = {};
            this.children = '';
            this.deletable = false;
            this.dialog = false;
        },
        confirm() {
            this.$emit(this.confirmEvent, {
                registrationId: this.bedarfsAnmeldung.id,
                refuseReason: this.refuseReason,
                numberOfChildren: this.bedarfsAnmeldung.name.length,
                sendStatus: this.bedarfsAnmeldung.benachrichtigungsstatus
            });
            this.close();
        },
        deleteBemerkung() {
            this.$emit(this.confirmEvent, {
                registrationId: this.bedarfsAnmeldung.id,
                refuseReason: null,
                numberOfChildren: this.bedarfsAnmeldung.name.length,
                sendStatus: this.bedarfsAnmeldung.benachrichtigungsstatus
            });
            this.close();
        }
    },
    watch: {
      dialog(val) {
        val || this.close();
      }
    },
}
</script>
