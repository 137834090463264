<template>
<v-dialog v-model="dialog" width="600">
    <v-card>
        <v-card-title :class="titleColor" primary-title>
          <v-icon large style="color: white" class="pr-3">
              {{ icon }}
          </v-icon>
            <span style="color: white">
              {{ title }}
            </span>
            <v-spacer></v-spacer>
            <Button large icon @click="close">
                <v-icon color="white">
                    clear
                </v-icon>
            </Button>
        </v-card-title>
        <v-card-text class="pa-5">
          <v-alert
            v-if="!isSending"
            :value="true"
            :type="alertType"
            outlined
          >
            {{ alertText }}
          </v-alert>
          <div align="center" v-else>
              <v-progress-circular color="primary" indeterminate></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <Button raised :color="color" @click="close">
                Schliessen
            </Button>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "feedback-dialog",
    components: {
      Button
    },
    data() {
        return {
            dialog: false,
            success: true,
            alertType: 'info',
            alertText: '',
            user: this.$select('user.value as user')
        }
    },
    props: {
      title: {
        type: String,
        required: true
      },
      titleColor: {
        type: String,
        default: 'headline primary lighten-1'
      },
      color: {
        type: String,
        default: 'primary'
      },
      icon: {
        type: String,
        default: 'info'
      },
      successText: {
        type: String,
        default: 'Erfolgreich!'
      },
      errorText: {
        type: String,
        default: 'Es ist zu einem Fehler gekommen.'
      },
      isSending: {
        type: Boolean
      },
      routingPoint: {
        type: String,
        default: '/dashboard'
      }
    },
    watch: {
      dialog(val) {
        val || this.close();
      }
    },
    methods: {
        open(
          registrations,
          dispatchFunction,
          alertType = null,
          alertText = null,
          chaining = true,
          successText = null) {

          this.dialog = true;

          if(dispatchFunction){
            this.$dispatch(dispatchFunction(registrations,  this.user.csrfToken.headerName, this.user.csrfToken.token,
                () => {
                  this.alertType = 'success';
                  this.alertText = this.successText;
                  if(successText !== null){
                    this.alertText = successText;
                  }
                  if(chaining){
                    this.$emit('success');
                  }
                },
                (error) => {
                  this.alertType = 'error';
                  this.alertText = this.errorText;
                  if(error.status === 401){
                    this.$dispatch(this.$actions().user.changeLogin())
                  }
                }));
          } else {
            this.alertType = alertType;
            this.alertText = alertText;
          }

        },
        close() {
            this.dialog = false;
            this.$router.replace(this.routingPoint);
        }
    }
}
</script>
