export default [{
        text: 'Aktionen',
        align: 'left',
        sortable: false,
        value: null
    },
    {
        text: 'Zugeteilt an',
        align: 'center',
        sortable: true,
        value: 'zugeteilteEinrichtung.value'
    },
    {
        text: 'Status',
        align: 'center',
        sortable: true,
        value: 'status.value'
    },
    {
        text: 'Name',
        align: 'center',
        sortable: true,
        value: 'name[0].sortVal'
    },
    {
        text: 'Geburtsdatum',
        align: 'center',
        sortable: true,
        value: 'birthdateSeconds'
    },
    {
        text: 'Wohnort',
        align: 'center',
        sortable: true,
        value: 'wohnort[0].value'
    },
    {
        text: 'Aufnahmedatum',
        align: 'center',
        sortable: true,
        value: 'aufnahmedatumSeconds'
    },
    {
        text: 'Merkmale',
        align: 'center',
        sortable: true,
        value: 'iStatus.length'
    },
    {
        text: ' ',
        align: '',
        sortable: false,
        value: null
    }
]
