import {
        noop,
        calculateAge,
        findNameFromID,
        transformDate,
        calculateAddedHours,
        dateToSeconds,
        compareLexicographical
} from '../../util/helper'

import {
  GETepicFactory,
  PUTepicFactory,
  mergeEpicFactory,
  POSTepicFactory
} from '../../util/epicFactories';

const prefix = `CONFIRMATION`;
export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const FETCH_ASSIGNED = `${prefix}/FETCH_ASSIGNED`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const SEND_BEDARFSANMELDUNG = `${prefix}/SEND_BEDARFSANMELDUNG`;
export const SELECT_ONE = `${prefix}/SELECT_ONE`;
export const RESET = `${prefix}/RESET`;
export const FILTER = `${prefix}/FILTER`;
export const SELECT_MULTIPLE_BY_INDEX = `${prefix}/SELECT_MULTIPLE_BY_INDEX`;

export const fetchAssignedEpic = GETepicFactory(`${prefix}/FETCH_ASSIGNED`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC&benachrichtigungsstatus=BENACHRICHTIGT`
})
export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/names`
})
export const sendBedarfsanmeldungEpic = PUTepicFactory(`${prefix}/SEND_BEDARFSANMELDUNG`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen/updateAll`,
    body: (action) => action.payload
})

export const fetchAssigned = (success = noop, error = noop) => fetchAssignedEpic.run({},{success, error});
export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});
export const sendBedarfsanmeldungen = (bedarfsAnmeldungen, headerKey, headerVal, success = noop, error = noop) => sendBedarfsanmeldungEpic.run(
  {
    payload: bedarfsAnmeldungen,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const clearStore = () => ({
    type: CLEAR_STORE
});
export const selectOne = (id, action) => dispatch => {
  dispatch({
      type: SELECT_ONE,
      payload: {
        id: id,
        action: action
      }
  });
  return Promise.resolve();
};
export const resetTable = () => dispatch => {
    dispatch({
        type: RESET
    });
    return Promise.resolve();
};
export const filterByFacility = (id) => dispatch => {
    dispatch({
        type: FILTER,
        payload: {
          id: id
        }
    });
    return Promise.resolve();
};
export const selectMultipleByIndex = (indices) => ({
    type: SELECT_MULTIPLE_BY_INDEX,
    payload: {
        indices: indices
    }
});

const sendReloadEpic = mergeEpicFactory(sendBedarfsanmeldungEpic.SUCCESS, () => fetchAssignedEpic.run());

const ACTION_HANDLERS = {

  ...fetchAssignedEpic.ACTION_HANDLERS,
  [fetchAssignedEpic.START]: (state, action) => {
    return {
      ...initialState,
      einrichtungen: state.einrichtungen,
      isFetching: true,
      fetched: false
    }
  },
  [fetchAssignedEpic.SUCCESS]: (state, action) => {
      let registrations = action.payload.response;
      let table = [];
      let names = [];

      for(let i = 0; i < registrations.length; ++i){

        //OFFEN, ZUBENACHRICHTIGEN, BENACHRICHTIGT, BESTAETIGT
        let status = registrations[i].status;
        if(registrations[i].status === null){
          status = "OFFEN";
        }
        let sendStatus = registrations[i].benachrichtigungsstatus;
        if(registrations[i].benachrichtigungsstatus === null){
          sendStatus = "OFFEN";
        }
        let assignedFacility = '-';
        if(findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen) !== null){
          assignedFacility = findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen);
        }
        let tableRow = {
          id: registrations[i].id,
          name: [],
          zugeteilteEinrichtung: {
            value: findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen),
            id: registrations[i].zugeteilteEinrichtung,
            tooltip: `Zugeteilt an: ${assignedFacility}`
          },
          status: {
            value: status,
            tooltip: `Status: ${status}`
          },
          sendStatus: {
            value: sendStatus,
            tooltip: `Benachrichtigungsstatus: ${sendStatus}`
          },
          bemerkung: registrations[i].bemerkung,
          downloadSelected: false,
          declineSelected: false
        };
        registrations[i].kindesdaten.forEach(child => {
          let age = null;
          if (child.geburtsdatum) {
              age = calculateAge(child.geburtsdatum, new Date);
          }
          let gender = "Kein Geschlecht angegeben";
          if(child.geschlecht !== null){
            gender = child.geschlecht;
          };
          tableRow.name.push({
            value: `${child.name} ${child.vorname}`,
            tooltip: gender,
            sortVal: child.name
          });
          let name = child.vorname.toUpperCase().trim();
          let lastName = child.name.toUpperCase().trim();
          let nameSplitted = name.split(" ");
          let lastNameSplitted = lastName.split(" ");

          names.push({
            id: names.length,
            registrationID: registrations[i].id,
            name: `${child.name} ${child.vorname} ${child.name}`,
            index: i,
            isParent: false,
            isChild: true,
            firstName: `${child.vorname}`,
            lastName: `${child.name}`,
            parentOf: null,
            age: age !== null ? `${age.year} Jahre alt` : null
          })

        })
        registrations[i].vertreter.forEach(vertreter => {
          let name = vertreter.vorname.toUpperCase().trim();
          let lastName = vertreter.name.toUpperCase().trim();
          let nameSplitted = name.split(" ");
          let lastNameSplitted = lastName.split(" ");

          names.push({
            id: names.length,
            registrationID: registrations[i].id,
            name: `${vertreter.name} ${vertreter.vorname} ${vertreter.name}`,
            index: i,
            isParent: true,
            isChild: false,
            firstName: `${vertreter.vorname}`,
            lastName: `${vertreter.name}`,
            parentOf: `${registrations[i].kindesdaten[0].vorname} ${registrations[i].kindesdaten[0].name}`,
            age: null
          })
        })
        table.push(tableRow);
      }
      names.sort(compareLexicographical);
      for(let i = 0; i < names.length; ++i){
        names[i].id = i;
      }
      return {
        ...state,
        assigned: action.payload.response,
        tableProjection: table,
        tableFull: table,
        isFetching: false,
        names: names
      }
  },
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: true
      }
  },
  [CLEAR_STORE]: (state, action) => {
      return {
          ...initialState
      }
  },
  [sendBedarfsanmeldungEpic.START]: (state, action) => {
      return {
          ...state,
          isSending: true
      }
  },
  [sendBedarfsanmeldungEpic.SUCCESS]: (state, action) => {
      return {
          ...state,
          isSending: false
      }
  },
  [sendBedarfsanmeldungEpic.FAIL]: (state, action) => {
      return {
          ...state,
          isSending: false
      }
  },
  [SELECT_ONE]: (state, action) => {
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let registrations = JSON.parse(JSON.stringify(state.assigned));
      let selected = Array.from(state.selected);
      let index = table.findIndex(x => x.id === action.payload.id);
      if(index >= 0){
        if(action.payload.action === 'BESTAETIGT'){
          table[index].declineSelected = false;
          table[index].downloadSelected = !table[index].downloadSelected;
          if(table[index].downloadSelected){
            table[index].sendStatus.value = 'BESTAETIGT';
            registrations[index].benachrichtigungsstatus = 'BESTAETIGT';
          } else {
            table[index].sendStatus.value = 'BENACHRICHTIGT';
            registrations[index].benachrichtigungsstatus = 'BENACHRICHTIGT';
          }
          let i = projection.findIndex(x => x.id === action.payload.id);
          if(i >= 0){
            projection[i].downloadSelected = !projection[i].downloadSelected;
            projection[i].declineSelected = false;
            if(projection[i].downloadSelected){
              projection[i].sendStatus.value = 'BESTAETIGT'
            } else {
              projection[i].sendStatus.value = 'BENACHRICHTIGT'
            }
            if(projection[i].downloadSelected){
              selected.push(projection[i].id);
            } else {
              selected = selected.filter(x => x !== projection[i].id);
            }
            return {
              ...state,
              tableFull: table,
              tableProjection: projection,
              selected: selected,
              assigned: registrations
            }
          }
        }
        if(action.payload.action === 'ABGELEHNT'){
          table[index].downloadSelected = false;
          table[index].declineSelected = !table[index].declineSelected;
          if(table[index].declineSelected){
            table[index].sendStatus.value = 'ABGELEHNT';
            registrations[index].benachrichtigungsstatus = 'ABGELEHNT';
          } else {
            table[index].sendStatus.value = 'BENACHRICHTIGT';
            registrations[index].benachrichtigungsstatus = 'BENACHRICHTIGT';
          }
          let i = projection.findIndex(x => x.id === action.payload.id);
          if(i >= 0){
            projection[i].declineSelected = !projection[i].declineSelected;
            projection[i].downloadSelected = false;
            if(projection[i].declineSelected){
              projection[i].sendStatus.value = 'ABGELEHNT'
            } else {
              projection[i].sendStatus.value = 'BENACHRICHTIGT'
            }
            if(projection[i].declineSelected){
              selected.push(projection[i].id);
            } else {
              selected = selected.filter(x => x !== projection[i].id);
            }
            return {
              ...state,
              tableFull: table,
              tableProjection: projection,
              selected: selected,
              assigned: registrations
            }
          }
        }
      }
      return { ...state };
  },
  [RESET]: (state, action) => {
      let projection = state.tableFull.slice(0, state.tableFull.length)
      return {
          ...state,
          tableProjection: projection
      }
  },
  [FILTER]: (state, action) => {
    let projection = state.tableFull.filter(x => x.zugeteilteEinrichtung.id === action.payload.id);
      return {
          ...state,
          tableProjection: projection
      }
  },
  [SELECT_MULTIPLE_BY_INDEX]: (state, action) => {
    let tableProj = [];
    for(let i = 0; i < action.payload.indices.length; ++i){
      tableProj.push(state.tableFull[action.payload.indices[i]]);
    }
    return {
        ...state,
        tableProjection: tableProj
    }
  },
}

export const actions = {
    fetchAssigned,
    fetchEinrichtungen,
    clearStore,
    sendBedarfsanmeldungen,
    selectOne,
    resetTable,
    filterByFacility,
    selectMultipleByIndex
};

export const epics = [
  fetchAssignedEpic.epic,
  fetchEinrichtungenEpic.epic,
  sendBedarfsanmeldungEpic.epic,
  sendReloadEpic.epic
]

const initialState = {
    assigned: [],
    einrichtungen: [],
    tableFull: [],
    tableProjection: [],
    isFetching: false,
    fetched: false,
    isSending: false,
    selected: [],
    names: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
