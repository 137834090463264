import {
    noop,
    calculateAge,
    findNameFromID,
    transformDate,
    calculateAddedHours,
    dateToSeconds
} from '../../util/helper'
import {
    GETepicFactory,
    POSTepicFactory,
    PUTepicFactory,
    mergeEpicFactory
} from '../../util/epicFactories';

const prefix = `ARCHIVE`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const POST_ARCHIVE = `${prefix}/POST_ARCHIVE`;
export const UNDO_ARCHIVE = `${prefix}/UNDO_ARCHIVE`;
export const POST_BULK_ARCHIVE = `${prefix}/POST_BULK_ARCHIVE`;
export const RESTORE_BULK_ARCHIVE = `${prefix}/RESTORE_BULK_ARCHIVE`;

export const postArchiveEpic = POSTepicFactory(`${prefix}/POST_ARCHIVE`, {
    url: (action) => {
      return `/api/v1/kita/admin/archiv/bedarfsanmeldungen/${action.payload.id}`;
    },
    body: (action) => {}
}, () => true, 200);
export const postBulkArchiveEpic = POSTepicFactory(`${prefix}/POST_BULK_ARCHIVE`, {
    url: (action) => {
      return `/api/v1/kita/admin/archiv/bedarfsanmeldungen?ags=${action.payload.payload.ags}&von=${action.payload.payload.start}&bis=${action.payload.payload.end}`;
    },
    body: (action) => {}
}, () => true, 200);
export const restoreBulkArchiveEpic = POSTepicFactory(`${prefix}/RESTORE_BULK_ARCHIVE`, {
    url: (action) => {
      return `/api/v1/kita/admin/archiv/bedarfsanmeldungen/restore?ags=${action.payload.payload.ags}&von=${action.payload.payload.start}&bis=${action.payload.payload.end}`;
    },
    body: (action) => {}
}, () => true, 200);
export const undoArchiveEpic = POSTepicFactory(`${prefix}/UNDO_ARCHIVE`, {
    url: (action) => {
      return `/api/v1/kita/admin/archiv/bedarfsanmeldungen/${action.payload.id}/restore`;
    },
    body: (action) => {}
}, () => true, 200);

export const clearStore = () => dispatch => {
  dispatch({
      type: CLEAR_STORE
  });
  return Promise.resolve();
};
export const postArchive = (id, headerKey, headerVal, success = noop, error = noop) => postArchiveEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const postBulkArchive = (payload, headerKey, headerVal, success = noop, error = noop) => postBulkArchiveEpic.run(
  {
    payload: payload,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const restoreBulkArchive = (payload, headerKey, headerVal, success = noop, error = noop) => restoreBulkArchiveEpic.run(
  {
    payload: payload,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const undoArchive = (id, headerKey, headerVal, success = noop, error = noop) => undoArchiveEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});


const ACTION_HANDLERS = {
    [CLEAR_STORE]: (state, action) => {
        return {
            isFetching: false,
            archivedRegistrations: [],
            undidRegistrations: []
        }
    },
    ...postArchiveEpic.ACTION_HANDLERS,
    [postArchiveEpic.SUCCESS]: (state, action) => {
      let c = state.archivedRegistrations;
      c.push(action.payload.id);
      return {
          ...state,
          isFetching: false,
          archivedRegistrations: c
      }
    },
    ...postBulkArchiveEpic.ACTION_HANDLERS,
    [postBulkArchiveEpic.SUCCESS]: (state, action) => {
      return {
          ...state,
          isFetching: false
      }
    },
    ...restoreBulkArchiveEpic.ACTION_HANDLERS,
    ...undoArchiveEpic.ACTION_HANDLERS,
    [undoArchiveEpic.SUCCESS]: (state, action) => {
      let c = state.undidRegistrations;
      c.push(action.payload.id);
      return {
          ...state,
          isFetching: false,
          undidRegistrations: c
      }
    },
}

export const actions = {
    clearStore,
    postArchive,
    postBulkArchive,
    restoreBulkArchive,
    undoArchive
};

export const epics = [
  postArchiveEpic.epic,
  postBulkArchiveEpic.epic,
  restoreBulkArchiveEpic.epic,
  undoArchiveEpic.epic
]

const initialState = {
    isFetching: false,
    archivedRegistrations: [],
    undidRegistrations: [],
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
