<template>
<div>
    <v-layout>
        <v-flex xs12 md12 lg10 offset-lg1 class="pt-4">
          <v-toolbar color="primary">
            <v-app-bar-nav-icon>
                <v-icon color="white">account_circle</v-icon>
            </v-app-bar-nav-icon>
            <v-toolbar-title class="white--text">Kontoinformation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card class="mb-5 pb-4">
            <v-layout class="py-4">
              <v-flex xs12 md10 offset-md1>
                <v-layout>
                  <v-flex xs12 md6>
                      <h4 class="primary--text text-xs-left">Benutzername: {{ user.value.username }}</h4>
                  </v-flex>
                  <v-flex xs12 md6>
                      <h4 class="primary--text text-xs-left">Rolle: {{ user.role }}</h4>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout>
              <v-flex xs12 md10 offset-md1>
                  <h4 
                  class="primary--text text-xs-left">AGS: <span v-for="(a, i) in user.value.ags" :key="i">{{ a }}<span v-if="i < user.value.ags.length - 1">, </span></span></h4>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <v-flex xs12 md10 offset-md1>
              <h2 class="primary--text pt-2">Rechte - Informationen</h2>
              <v-expansion-panels>
                <v-expansion-panel expand v-for="(item, key, index) in permissionsSorted" :key="index">
                  <v-expansion-panel-header>
                    <span class="primary--text">{{ item }}</span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div slot="header">
                      <div>
                          <h4 class="primary--text">{{ permissionDict[item] }} </h4>
                      </div>
                    </div>
                    <div v-for="(s, index) in ['lesen / anzeigen','ändern','löschen','anlegen']" class="pl-5 py-2" :key="index" style="display: inline">
                        <v-chip
                          class="ma-2"
                          color="success"
                          outlined
                        >
                          <v-icon left v-if="permissions[item].includes(s)">
                            check
                          </v-icon>
                          <v-icon v-else>
                            clear
                          </v-icon>
                          {{ s | firstLetterUpperCase }}
                        </v-chip>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-flex>
          </v-card>
        </v-flex>
    </v-layout>
</div>
</template>
<script>
export default {
    name: "user-account",
    data() {
        return {
          user: this.$select('user as user'),
          permissions: this.$select('user.permissions as permissions'),
          permissionDict: {
            'AGS': 'AGS - Werte',
            'Ansprechpartner': 'Ansprechpartner',
            'Bedarfsanmeldung': 'Bedarfsanmeldungen',
            'Bedarfsanmeldung.archiv': 'Archiv',
            'Einrichtung': 'Einrichtungen',
            'Einrichtung.einrichtungsnummer': 'Einrichtungsnummern',
            'Einrichtung.images': 'Einrichtungsbilder',
            'Einrichtung.oeffnungszeiten': 'Einrichtungsöffnungszeiten',
            'Einrichtungsart': 'Einrichtungsarten',
            'Mandantparameter': 'Mandantparameter',
            'Postkorbnachricht': 'Postkorbnachrichten',
            'Postkorbnachricht.only': 'Rechte für Postkorbnachricht aber nicht Zuteilung',
            'Traeger': 'Träger',
            'Saisonparameter': 'Saisonparameter',
          },
          permissionsSorted: []
        }
    },
    mounted(){
      const rightsNotToDisplay = ['Bestaetigung.save', 'Einrichtung.metadata',
      'Einrichtung.name', 'Mandantparameter.gebuehren-url', 'Mandantparameter.kibe-url',
      'Postkorbnachricht.send', 'Saisonparameter.einrichtung-amount', 'Traeger.Traegerart', 'Textbaustein'];

      Object.keys(this.permissions).forEach(key => {
        if(!rightsNotToDisplay.includes(key)){
          this.permissionsSorted.push(key);
        }
      })
      this.permissionsSorted.sort();
    },
    methods: {

    }
}
</script>

<style lang="stylus" scoped>

</style>
