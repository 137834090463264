<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header color="primary">
                <span class="title white--text"
                    >{{ index | incrementOne }}. Vertreter -
                    {{ vertreter.vorname }} {{ vertreter.name }}
                </span>
                <template v-slot:actions>
                    <v-icon color="white">keyboard_arrow_down</v-icon>
                </template>
            </v-expansion-panel-header>

            <v-expansion-panel-content class="mt-6">
                <v-row class="mt-6 mb-6">
                    <v-col cols="6">
                        <!--  Steckbrief -->
                        <v-card
                            class="border rounded-lg mx-10"
                            style="
                                border: 1px solid lightgray;
                                box-shadow: none;
                            "
                        >
                            <div style="display: flex; align-items: center">
                                <div>
                                    <v-icon large color="primary" class="ml-6"
                                        >face</v-icon
                                    >
                                </div>
                                <div style="flex-grow: 1">
                                    <v-card-title
                                        class="pb-0"
                                        style="
                                            display: flex;
                                            justify-content: space-between;
                                        "
                                    >
                                        <span
                                            class="title font-weight-bold primary--text ml-5"
                                        >
                                            {{ vertreter.vorname }}
                                            {{ vertreter.name }}
                                            <Tooltip
                                                top
                                                v-if="
                                                    vertreter.geschlecht ===
                                                    'MAENNLICH'
                                                "
                                            >
                                                <Button medium icon>
                                                    <v-icon color="primary"
                                                        >$male</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span>männlich</span>
                                                </template>
                                            </Tooltip>
                                            <Tooltip
                                                top
                                                v-if="
                                                    vertreter.geschlecht ===
                                                    'WEIBLICH'
                                                "
                                            >
                                                <Button medium icon>
                                                    <v-icon color="primary"
                                                        >$female</v-icon
                                                    ></Button
                                                >
                                                <template #content>
                                                    <span>weiblich</span>
                                                </template>
                                            </Tooltip>
                                            <Tooltip
                                                top
                                                v-if="
                                                    vertreter.geschlecht ===
                                                    'DIVERS'
                                                "
                                            >
                                                <Button medium icon>
                                                    <v-icon color="primary"
                                                        >$divers</v-icon
                                                    ></Button
                                                >
                                                <template #content>
                                                    <span>divers</span>
                                                </template>
                                            </Tooltip>
                                            <span
                                                class="subtitle-2"
                                                style="color: gray"
                                                v-if="
                                                    vertreter.geburtsname !==
                                                    null
                                                "
                                                >(geb.
                                                {{
                                                    vertreter.geburtsname
                                                }})</span
                                            >
                                        </span>
                                    </v-card-title>

                                    <v-card-text class="pt-0">
                                        <div
                                            class="subtitle-2"
                                            style="color: gray"
                                        >
                                            <div class="ml-5 py-0">
                                                {{ capitalizedVertreterArt }}
                                            </div>
                                        </div>
                                    </v-card-text>
                                </div>
                            </div>
                            <v-divider class="mx-4"></v-divider>

                            <v-card-text class="mx-12 subtitle-1">
                                <div class="d-xl-flex flex-column flex-xl-row">
                                    <div style="flex: 1">
                                        <div class="mb-2">
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >cake</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span
                                                        >Geburtsdatum,
                                                        Geburtsort</span
                                                    >
                                                </template>
                                            </Tooltip>
                                            <span
                                                v-if="
                                                    vertreter.geburtsdatum &&
                                                    vertreter.geburtsdatum.trim() !==
                                                        ''
                                                "
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                            >
                                                {{
                                                    vertreter.geburtsdatum
                                                        | transformDate
                                                }}
                                                <span
                                                    v-if="
                                                        vertreter.geburtsort &&
                                                        vertreter.geburtsort.trim() !==
                                                            ''
                                                    "
                                                >
                                                    in
                                                    {{ vertreter.geburtsort }}
                                                </span>
                                            </span>
                                            <span
                                                v-else
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >-</span
                                            >
                                        </div>

                                        <div class="mb-2">
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >public</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span
                                                        >Staatsangehörigkeit</span
                                                    >
                                                </template>
                                            </Tooltip>
                                            <span
                                                v-if="
                                                    vertreter.staatsangehoerigkeit &&
                                                    vertreter.staatsangehoerigkeit.trim() !==
                                                        ''
                                                "
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                            >
                                                {{
                                                    vertreter.staatsangehoerigkeit
                                                }}
                                            </span>
                                            <span
                                                v-else
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >-</span
                                            >
                                        </div>

                                        <div class="mb-2">
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >flag</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span>Herkunftsland</span>
                                                </template>
                                            </Tooltip>
                                            <span
                                                v-if="
                                                    vertreter.herkunftsland &&
                                                    vertreter.herkunftsland.trim() !==
                                                        ''
                                                "
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                            >
                                                {{ vertreter.herkunftsland }}
                                            </span>
                                            <span
                                                v-else
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >-</span
                                            >
                                        </div>

                                        <div class="mb-2">
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >people</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span>Familienstand</span>
                                                </template>
                                            </Tooltip>
                                            <span
                                                v-if="
                                                    vertreter.familienstand &&
                                                    vertreter.familienstand.trim() !==
                                                        ''
                                                "
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                            >
                                                {{
                                                    vertreter.familienstand.toLowerCase()
                                                }}
                                            </span>
                                            <span
                                                v-else
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >-</span
                                            >
                                        </div>
                                    </div>

                                    <!-- Hauptwohnsitz -->
                                    <div class="kontakt" style="flex: 1">
                                        <div
                                            v-if="vertreter.hauptwohnsitz"
                                            class="mb-2"
                                        >
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >home</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span>Hauptwohnsitz</span>
                                                </template>
                                            </Tooltip>
                                            <span
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                            >
                                                {{
                                                    vertreter.hauptwohnsitz
                                                        .strasse
                                                }}
                                                {{
                                                    vertreter.hauptwohnsitz
                                                        .hausnummer
                                                }},
                                                {{
                                                    vertreter.hauptwohnsitz
                                                        .postleitzahl
                                                }}
                                                {{
                                                    vertreter.hauptwohnsitz.ort
                                                }}
                                                <span
                                                    v-if="
                                                        vertreter.hauptwohnsitz
                                                            .ortsteil
                                                    "
                                                >
                                                    ({{
                                                        vertreter.hauptwohnsitz
                                                            .ortsteil
                                                    }})
                                                </span>
                                            </span>
                                        </div>

                                        <!-- Kontaktnummern -->
                                        <div class="mb-2">
                                            <div
                                                style="
                                                    display: flex;
                                                    align-items: flex-start;
                                                "
                                            >
                                                <Tooltip top>
                                                    <Button medium icon>
                                                        <v-icon
                                                            class="mr-2"
                                                            style="
                                                                color: lightgray;
                                                            "
                                                            >call</v-icon
                                                        >
                                                    </Button>
                                                    <template #content>
                                                        <span
                                                            >Kontaktnummern</span
                                                        >
                                                    </template>
                                                </Tooltip>
                                                <div
                                                    v-if="
                                                        vertreter.kontaktnummern !==
                                                            null &&
                                                        vertreter.kontaktnummern
                                                            .length > 0
                                                    "
                                                    style="
                                                        color: black;
                                                        font-weight: 500;
                                                    "
                                                >
                                                    <div
                                                        v-for="nummer in vertreter.kontaktnummern"
                                                        :key="
                                                            nummer.kontaktnummernArt
                                                        "
                                                        style="
                                                            margin-bottom: 5px;
                                                        "
                                                    >
                                                        {{
                                                            nummer.kontaktnummernArt
                                                                | toCamelCase
                                                        }}: {{ nummer.nummer }}
                                                    </div>
                                                </div>

                                                <div
                                                    v-else
                                                    style="color: black"
                                                    class="ml-1"
                                                >
                                                    -
                                                </div>
                                            </div>
                                        </div>

                                        <!-- E-Mail -->
                                        <div>
                                            <Tooltip top>
                                                <Button medium icon>
                                                    <v-icon
                                                        class="mr-2"
                                                        style="color: lightgray"
                                                        >mail_outline</v-icon
                                                    >
                                                </Button>
                                                <template #content>
                                                    <span>E-Mail</span>
                                                </template>
                                            </Tooltip>
                                            <span
                                                v-if="vertreter.email"
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >{{ vertreter.email }}</span
                                            ><span
                                                v-else
                                                style="
                                                    color: black;
                                                    font-weight: 500;
                                                "
                                                >-</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>

                            <v-card-text
                                v-if="
                                    (vertreter.bemerkungen !== null &&
                                        vertreter.bemerkungen.length > 0) ||
                                    vertreter.neuerHauptwohnsitz ||
                                    vertreter.umzugsdatum !== null ||
                                    vertreter.elternzeit !== null
                                "
                                class="mx-12"
                                style="color: gray"
                            >
                                <div
                                    class="mb-2 subtitle-2"
                                    v-if="
                                        vertreter.bemerkungen !== null &&
                                        vertreter.bemerkungen.length > 0
                                    "
                                >
                                    <span medium icon>
                                        <v-icon color="primary"> info </v-icon>
                                    </span>

                                    <span class="ml-1"
                                        >Bemerkung vorhanden:
                                        {{ vertreter.bemerkungen }}</span
                                    >
                                </div>

                                <div
                                    class="mb-2 subtitle-2"
                                    v-if="
                                        vertreter.neuerHauptwohnsitz !== null ||
                                        vertreter.umzugsdatum !== null
                                    "
                                >
                                    <div style="display: flex">
                                        <span medium icon>
                                            <v-icon color="primary"
                                                >info</v-icon
                                            >
                                        </span>

                                        <div>
                                            <div
                                                v-if="
                                                    vertreter.neuerHauptwohnsitz
                                                "
                                                class="ml-2"
                                            >
                                                <b>Neuer Hauptwohnsitz:</b>
                                                {{
                                                    vertreter.neuerHauptwohnsitz
                                                        .strasse
                                                }}
                                                {{
                                                    vertreter.neuerHauptwohnsitz
                                                        .hausnummer
                                                }},
                                                {{
                                                    vertreter.neuerHauptwohnsitz
                                                        .postleitzahl
                                                }}
                                                {{
                                                    vertreter.neuerHauptwohnsitz
                                                        .ort
                                                }}
                                                <span
                                                    v-if="
                                                        vertreter
                                                            .neuerHauptwohnsitz
                                                            .ortsteil
                                                    "
                                                >
                                                    ({{
                                                        vertreter
                                                            .neuerHauptwohnsitz
                                                            .ortsteil
                                                    }})
                                                </span>
                                            </div>
                                            <div
                                                v-if="
                                                    vertreter.umzugsdatum !==
                                                    null
                                                "
                                                class="ml-2"
                                            >
                                                <b>Umzugsdatum:</b>
                                                {{
                                                    vertreter.umzugsdatum
                                                        | transformDate
                                                }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="vertreter.elternzeit !== null">
                                    <div style="display: flex">
                                        <div class="mr-2">
                                            <span medium icon>
                                                <v-icon color="primary"
                                                    >info</v-icon
                                                >
                                            </span>
                                        </div>
                                        <div>
                                            <div
                                                v-if="vertreter.elternzeit.von"
                                                class="subtitle-2"
                                            >
                                                <b>Elternzeit: </b>
                                                Von
                                                {{
                                                    vertreter.elternzeit.von
                                                        | transformDate
                                                }}

                                                <span
                                                    v-if="
                                                        vertreter.elternzeit.bis
                                                    "
                                                >
                                                    bis
                                                    {{
                                                        vertreter.elternzeit.bis
                                                            | transformDate
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <!--  Zusatzinfos -->
                    <v-col cols="6" class="pr-6">
                        <v-card
                            style="width: 100%; border: none; box-shadow: none"
                        >
                            <v-card-text class="pt-0">
                                <div class="d-flex mb-2">
                                    <div
                                        class="subtitle-1 font-weight-bold"
                                        style="flex: 1"
                                    >
                                        Sorgeberechtigung
                                    </div>
                                </div>

                                <template>
                                    <div class="d-flex mb-2 even-row">
                                        <div
                                            class="subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Alleinerziehend
                                        </div>
                                        <div
                                            class="subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="vertreter.alleinerziehend"
                                            >
                                                <span>ja</span>
                                            </template>
                                            <template
                                                v-else-if="
                                                    vertreter.alleinerziehend ==
                                                    false
                                                "
                                            >
                                                <span>nein</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <div
                                            class="subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Allein sorgeberechtigt
                                        </div>
                                        <div
                                            class="subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="
                                                    vertreter.personSorgeberechtigt
                                                "
                                            >
                                                <span>ja</span>
                                            </template>
                                            <template
                                                v-else-if="
                                                    vertreter.personSorgeberechtigt ==
                                                    false
                                                "
                                            >
                                                <span>nein</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-2 even-row">
                                        <div
                                            class="subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Negativbescheid vorliegend
                                        </div>
                                        <div
                                            class="subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="
                                                    vertreter.negativbescheidLiegtVor
                                                "
                                            >
                                                <span>ja</span>
                                            </template>
                                            <template
                                                v-if="
                                                    vertreter.negativbescheidLiegtVor ==
                                                    false
                                                "
                                            >
                                                <span>nein</span>
                                            </template>
                                            <template v-else>
                                                <span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                                <div class="d-flex mb-2 mt-4">
                                    <div
                                        class="subtitle-1 font-weight-bold"
                                        style="flex: 1"
                                    >
                                        Berufliche Informationen
                                    </div>
                                </div>

                                <template>
                                    <div class="d-flex mb-2 even-row">
                                        <div
                                            class="subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Berufstätig
                                        </div>
                                        <div
                                            class="subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="vertreter.berufstaetig"
                                                >ja</template
                                            >

                                            <template
                                                v-else-if="
                                                    vertreter.berufstaetig ==
                                                    false
                                                "
                                            >
                                                nein
                                            </template>
                                            <template v-else>-</template>
                                        </div>
                                    </div>
                                    <div class="d-flex mb-2">
                                        <div
                                            class="subtitle-1 gray--text"
                                            style="flex: 1"
                                        >
                                            Arbeitgeber
                                        </div>
                                        <div
                                            class="subtitle-1 font-weight-bold"
                                            style="flex: 1; margin-left: 10px"
                                        >
                                            <template
                                                v-if="
                                                    vertreter.arbeitgeber !==
                                                    null
                                                "
                                                >{{
                                                    vertreter.arbeitgeber
                                                }}</template
                                            >
                                            <template v-else
                                                ><span>-</span>
                                            </template>
                                        </div>
                                    </div>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import Tooltip from '@s/views/general_components/components/Tooltip';

export default {
    name: 'vertreter',
    components: {
        Tooltip,
    },
    computed: {
        capitalizedVertreterArt() {
            if (!this.vertreter.vertreterArt) return '';
            const lowerCaseValue = this.vertreter.vertreterArt.toLowerCase();
            return (
                lowerCaseValue.charAt(0).toUpperCase() + lowerCaseValue.slice(1)
            );
        },
    },
    filters: {
        toCamelCase(text) {
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },
    },

    props: {
        vertreter: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            default: null,
        },
    },
};
</script>

<style lang="stylus" scoped>
i
  font-weight: bold


.even-row
  background-color: #f0f0f0

.flex-container {
  display: flex;
  flex-wrap: wrap;
}
</style>
