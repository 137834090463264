const re = (/^([\w\.-]+)\s+as\s+([\w\.-]+)$/);


function isObject(obj) {
    return obj !== null && typeof obj === 'object';
}

function getPathSegments(path) {
    const pathArr = path.split('.');
    const parts = [];

    for (let i = 0; i < pathArr.length; i++) {
        let p = pathArr[i];

        while (p[p.length - 1] === '\\' && pathArr[i + 1] !== undefined) {
            p = p.slice(0, -1) + '.';
            p += pathArr[++i];
        }

        parts.push(p);
    }

    return parts;
}

const isValidDate = (date) => {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}


var isArray = Array.isArray;
var keyList = Object.keys;
var hasProp = Object.prototype.hasOwnProperty;

export function deepEqual(a, b) {
  if (a === b) return true;

  if (a && b && typeof a == 'object' && typeof b == 'object') {
    var arrA = isArray(a)
      , arrB = isArray(b)
      , i
      , length
      , key;

    if (arrA && arrB) {
      length = a.length;
      if (length != b.length) return false;
      for (i = length; i-- !== 0;)
        if (!deepEqual(a[i], b[i])) return false;
      return true;
    }

    if (arrA != arrB) return false;

    var dateA = a instanceof Date
      , dateB = b instanceof Date;
    if (dateA != dateB) return false;
    if (dateA && dateB) return a.getTime() == b.getTime();

    var regexpA = a instanceof RegExp
      , regexpB = b instanceof RegExp;
    if (regexpA != regexpB) return false;
    if (regexpA && regexpB) return a.toString() == b.toString();

    var keys = keyList(a);
    length = keys.length;

    if (length !== keyList(b).length)
      return false;

    for (i = length; i-- !== 0;)
      if (!hasProp.call(b, keys[i])) return false;

    for (i = length; i-- !== 0;) {
      key = keys[i];
      if (!deepEqual(a[key], b[key])) return false;
    }

    return true;
  }

  return a!==a && b!==b;
};

export function cloneDeep (o) {
  let newO;
  let i;

  if (typeof o !== 'object') return o;

  if (!o) return o;

  if (Object.prototype.toString.apply(o) === '[object Array]') {
    newO = [];
    for (i = 0; i < o.length; i += 1) {
      newO[i] = cloneDeep(o[i]);
    }
    return newO;
  }

  if (isValidDate(o)) {
      return new Date(o.getTime())
  }

  newO = {}
  for (i in o) {
    if (o.hasOwnProperty(i)) {
      newO[i] = cloneDeep(o[i]);
    }
  }
  return newO;
}

export function parseProp(prop) {
    let realProp = prop;
    let storeProp = prop;
    if (re.test(prop)) {
        [, storeProp, realProp] = prop.match(re)
    }
    return {storeProp, realProp}
}

export function deepProp(obj, path) {
    // Prevent to set a VueJS Component Observable into the Redux Store
    return cloneDeep(path.split('.').reduce((o, p) => o[p], obj))
}

export function replaceDots(obj) {
    if (!isObject(obj)) {
        return obj;
    }
    return Object.keys(obj).reduce((s, k) => {
        const nk = k.replace(/\./g, '$#');
        s[nk] = replaceDots(obj[k]);
        return s;
    }, {})
}

export function restoreDots(obj) {
    if (!isObject(obj)) {
        return obj;
    }
    return Object.keys(obj).reduce((s, k) => {
        const nk = k.replace(/\$\#/g, '.');
        s[nk] = restoreDots(obj[k]);
        return s;
    }, {})
}

export function setDeep(obj, path, value) {
    if (!isObject(obj) || typeof path !== 'string') {
        return;
    }

    const pathArr = getPathSegments(path);

    for (let i = 0; i < pathArr.length; i++) {
        const p = pathArr[i];

        if (!isObject(obj[p])) {
            obj[p] = {};
        }

        if (i === pathArr.length - 1) {
            // Prevent to set a VueJS Component Observable into the Redux Store
            if (!deepEqual(obj[p], value)) {
                obj[p] = cloneDeep(value);
            }
        }

        obj = obj[p];
    }
}
