import {
  noop,
  findNameFromID,
  compareLexicographical
} from '../../util/helper'
import {
  GETepicFactory
  } from '../../util/epicFactories';

const prefix = `EXPORT`;

export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const FETCH_ASSIGNED = `${prefix}/FETCH_ASSIGNED`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const FILTER_EINRICHTUNG = `${prefix}/FILTER_EINRICHTUNG`;

export const fetchAssignedEpic = GETepicFactory(`${prefix}/FETCH_ASSIGNED`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC`
})

export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/einrichtungen?page=0&size=2147483647&sort=NAME&dir=ASC`
})

export const fetchAssigned = (success = noop, error = noop) => fetchAssignedEpic.run({},{success, error});

export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});

export const clearStore = () => ({
    type: CLEAR_STORE
});

export const filterEinrichtung = (id) => dispatch => {
  dispatch({
      type: FILTER_EINRICHTUNG,
      payload: {
        id: id
      }
  });
  return Promise.resolve();
};



const ACTION_HANDLERS = {
  ...fetchAssignedEpic.ACTION_HANDLERS,
  [fetchAssignedEpic.SUCCESS]: (state, action) => {
    let registrations = action.payload.response;
    let table = [];
    for(let i = 0; i < registrations.length; ++i){
      let assignedFacility = '-';
      if(findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen) !== null){
        assignedFacility = findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen);
      }

      let tableRow = {
        id: registrations[i].id,
        name: [],
        zugeteilteEinrichtung: {
          value: findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen),
          id: registrations[i].zugeteilteEinrichtung,
          tooltip: `Zugeteilt an: ${assignedFacility}`
        }
      };
      registrations[i].kindesdaten.forEach(child => {
        let gender = "Kein Geschlecht angegeben";
        if(child.geschlecht !== null){
          gender = child.geschlecht;
        };
        tableRow.name.push({
          value: `${child.name} ${child.vorname}`,
          tooltip: gender,
          sortVal: child.name
        });
      })
      table.push(tableRow);
    }
    return {
      ...state,
      assigned: action.payload.response,
      tableProjection: table,
      tableFull: table,
      isFetching: false
    }
  },
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
    action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: true
      }
  },
  [CLEAR_STORE]: (state, action) => {
      return {
          ...initialState
      }
  },
  [FILTER_EINRICHTUNG]: (state, action) => {
      let id = action.payload.id;
      let projection = [];
      if(id !== null){
        projection = state.tableFull.filter(x => x.zugeteilteEinrichtung.id === id);
      } else {
        projection = state.tableFull;
      }
      return {
          ...state,
          tableProjection: projection
      }
  },
}

export const actions = {
    fetchAssigned,
    fetchEinrichtungen,
    clearStore,
    filterEinrichtung
};

export const epics = [
  fetchAssignedEpic.epic,
  fetchEinrichtungenEpic.epic
]

const initialState = {
    assigned: [],
    einrichtungen: [],
    tableFull: [],
    tableProjection: [],
    isFetching: false,
    fetched: false
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
