<template lang="html">
    <v-app class="background">
        <router-view></router-view>
    </v-app>
</template>
<script>
    export default {
        name: 'application'
    }
</script>

<style lang="stylus">
    #app, body, html
        margin 0
        padding 0

    .background
        background-color: #fff

    .pointer
      cursor: pointer

    .normal
      cursor: default
</style>
