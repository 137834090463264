<template>
<div>
    <div v-if="['Superadmin', 'KV', 'Träger'].includes(role)" style="display: flex">
      <Tooltip
        top
        v-if="bedarfsAnmeldung.status.value === 'ZURUECKGESTELLT' && role === 'Träger'">
          <Button icon small disabled>
              <v-icon color="primary darken-1">
                  more_horiz
              </v-icon>
          </Button>
        <template #content>
          <span>Zurückgestellt - Kein Zuteilung möglich</span>
        </template>
      </Tooltip>
      <Tooltip top v-else>
          <Button icon small @click="openMenu">
              <v-icon color="primary darken-1">
                  more_horiz
              </v-icon>
          </Button>
        <template #content>
          <span>Zuteilung</span>
        </template>
      </Tooltip>
      <v-menu
        v-model="menuOpen"
        :close-on-content-click="false"
        :position-x="x"
        :position-y="y"
        absolute
        offset-y>
        <v-list subheader two-line>
            <v-subheader class="text-xs-left">Zuteilen an:</v-subheader>
            <ListComponent
            v-for="(einrichtung, index) in bedarfsAnmeldung.priorities"
            :key="einrichtung.id"
            :einrichtung="mapEinrichtung(einrichtung.id)"
            :tileProps="getProps(mapEinrichtung(einrichtung.id), index, einrichtung.status)"
            backgroundColor="primary"
            @assignRegistration="assignRegistration">
          </ListComponent>
            <hr style="border: 2px solid #00838F" />
            <AdditionalKitaComponent
            :einrichtung="mapAdditionalEinrichtung()"
            :tileProps="getProps(mapAdditionalEinrichtung())"
            :priorities="bedarfsAnmeldung.priorities"
            @assignRegistration="assignRegistration"
            @emitExplanation="emitExplanation">
            </AdditionalKitaComponent>
        </v-list>
      </v-menu>
      <template>
        <div class="text-xs-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <Button icon small v-bind="attrs" v-on="on" >
                  <v-icon color="primary">expand_more</v-icon>
              </Button>
            </template>
            <v-list subheader two-line>
              <v-subheader class="text-xs-left">Zusätzliche Aktionen</v-subheader>
              <AdditionalActionComponent
                :status="bedarfsAnmeldung.status.value"
                :reclineAllowed="role === 'Träger' ? false : true"
                :archiveAllowed="['Superadmin', 'KV'].includes(role)"
                :internalPriority="bedarfsAnmeldung.internalPriority"
                @emitRecline="emitRecline"
                @emitUndoRecline="emitUndoRecline"
                @emitComment="emitComment"
                @emitExplanation="emitExplanation"
                @emitArchive="emitArchive"
                @emitInternalPriority="emitInternalPriority">
              </AdditionalActionComponent>
            </v-list>
          </v-menu>
        </div>
      </template>
    </div>
    <div v-else>
      <v-menu offset-y v-if="bedarfsAnmeldung.status.value !== 'ZURUECKGESTELLT'">
        <template v-slot:activator="{ on, attrs }">
            <Button
              v-bind="attrs" v-on="on" 
              small
              icon>
              <v-icon color="primary" medium>
                  more_horiz
              </v-icon>
            </Button>
        </template>
        <v-list subheader two-line>
          <v-subheader class="text-xs-left">Zuteilen an:</v-subheader>
          <EinrichtungActionComponent
            :tileProps="getTileProps(bedarfsAnmeldung.priorities)"
            @assignRegistration="assignRegistration"
            @declineRegistration="declineRegistration">
          </EinrichtungActionComponent>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
              <Button
                small
                v-bind="attrs" v-on="{...menu}"
                icon>
                <v-icon color="primary" medium>
                    expand_more
                </v-icon>
                <Tooltip top>
                  <span>Zusätzliche Aktionen</span>
                </Tooltip>
              </Button>
        </template>
        <v-list subheader two-line>
          <v-subheader class="text-xs-left">Zusätzliche Aktionen</v-subheader>
          <AdditionalActionComponent
            :status="bedarfsAnmeldung.status.value"
            :reclineAllowed="false"
            :internalPriority="bedarfsAnmeldung.internalPriority"
            @emitRecline="emitRecline"
            @emitUndoRecline="emitUndoRecline"
            @emitComment="emitComment"
            @emitExplanation="emitExplanation"
            @emitInternalPriority="emitInternalPriority">
          </AdditionalActionComponent>
        </v-list>
      </v-menu>
      <Tooltip
        v-if="notifications_active && bedarfsAnmeldung.status.value !== 'ZURUECKGESTELLT'"
        top>
          <v-icon
            color="primary"
            class="pointer">
              notifications_active
          </v-icon>
        <template #content>
          <span> Sie sind mit der Zuteilung dran </span>
        </template>
      </Tooltip>
      <Tooltip v-if="undo_possible" top>
          <v-icon
            color="error darken-2"
            class="pointer">
            block
          </v-icon>
        <template #content>
          <span> Abgelehnt - Rückgängig machen möglich </span>
        </template>
      </Tooltip>

    </div>
</div>
</template>

<script>
import ListComponent from './ListComponent.vue'
import AdditionalKitaComponent from './AdditionalKitaComponent.vue'
import AdditionalActionComponent from './AdditionalActionComponent.vue'
import EinrichtungActionComponent from './EinrichtungActionComponent.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

/**
 * The menu component provides the functionality for assigning a registration to
 * a facility. Displaying the facilities, the capacity and emiting the assignment
 * click event.
 */
export default {
    name: "menu-component",
    data() {
        return {
          menuOpen: false,
          x: 0,
          y: 0,
          notifications_active: false,
          undo_possible: false
        }
    },
    props: {
      /**
       * The registrations of a user.
       */
        bedarfsAnmeldung: {
            type: Object
        },
        /**
         * The facilities of a user.
         *
         */
        einrichtungen: {
            type: Array
        },
      /**
       * The role of the user logged in. Derived from the redux store -
       * fetched from the API endpoint for user information. Based on the role
       * a different menu will get displayed.
       *
       * @values KV, Traeger, ER
       */
        role: {
          type: String,
          required: true
        }
    },
    methods: {
        openMenu(e){
          e.preventDefault()
          this.x = e.clientX
          this.y = e.clientY
          this.$nextTick(() => {
            this.menuOpen = true
          })
        },
        mapEinrichtung(id) {
            let item = this.einrichtungen.findIndex(i => i.id === id);
            if (item >= 0) {
                return this.einrichtungen[item];
            } else {
                return {
                    id: 'Undefiened',
                    name: 'Undefiened',
                    freiePlaetze: 0,
                    gesamtPlaetze: 0
                }
            }
        },
        mapAdditionalEinrichtung(){
          if(this.bedarfsAnmeldung.zugeteilteEinrichtung.id !== null){
            let index = this.bedarfsAnmeldung.priorities.findIndex(x => x.id === this.bedarfsAnmeldung.zugeteilteEinrichtung.id)
            if(index < 0){
              return this.mapEinrichtung(this.bedarfsAnmeldung.zugeteilteEinrichtung.id);
            }
          }
          return null;
        },

        /**
         * getProps - Calculating the tile properties for the drop down menu
         *            for Admin, Kommune and Träger roles
         *
         * @param  {type} einrichtung Einrichtung object from this.mapEinrichtung()
         * @param  {type} index       Index in array (priority)
         * @return {type}             Object containing the props for ListComponent
         */
        getProps(einrichtung, index, status = null){
          if(einrichtung === null){
            if(this.bedarfsAnmeldung.zugeteilteEinrichtung.id !== null) {
              let index = this.einrichtungen.findIndex(x => x.id === this.bedarfsAnmeldung.zugeteilteEinrichtung.id);
              if(index === -1){
                return {
                  iconName: 'block',
                  iconColor: 'primary',
                  backgroundColor: 'background-color: rgb(188, 188, 188, 0.8)',
                  headerText: 'Zuteilung an eine nicht priorisierte Kita',
                  subheaderText: `Wählen sie mit der Suche eine Kita aus`,
                  disabled: true
                }
              }
            }
            return {
              iconName: 'block',
              iconColor: 'primary',
              backgroundColor: 'background-color: rgb(188, 188, 188, 0.8)',
              headerText: 'Zuteilung an eine nicht priorisierte Kita',
              subheaderText: `Wählen sie mit der Suche eine Kita aus`,
              disabled: false
            }
          }

          // Prio is empty or ID is unkown --> KBE does belong to another Trager or Gemeinde
          // So this user is not allowed to assign a registration to it
          if(einrichtung.id === 'Undefiened'){
            return {
              iconName: 'block',
              iconColor: 'primary',
              backgroundColor: 'background-color: rgb(188, 188, 188, 0.8)',
              headerText: 'Keine Zuteilung möglich',
              subheaderText: `Diese KBE gehört zu einer anderen Gemeinde/Träger`,
              disabled: true
            }
          }

          // Registration is already assigned to this KBE
          if(this.bedarfsAnmeldung.zugeteilteEinrichtung.id !== null) {
            if(einrichtung.id === this.bedarfsAnmeldung.zugeteilteEinrichtung.id){
              return {
                iconName: 'undo',
                iconColor: 'primary',
                backgroundColor: '',
                headerText: 'Rückgängig machen',
                subheaderText: `Zuteilung von ${einrichtung.name} entfernen`,
                tooltipText: 'Undo',
                disabled: false
              }
            }

            // Registration is assigned to a facility which does not belong to the user
            let index = this.einrichtungen.findIndex(x => x.id === this.bedarfsAnmeldung.zugeteilteEinrichtung.id);
            if(index === -1){
              return {
                iconName: 'block',
                iconColor: 'primary',
                backgroundColor: '',
                headerText: 'Zuteilung zur Einrichtung eines anderen Trägers erfolgt.',
                subheaderText: `Zuteilung zu ${einrichtung.name} ist daher nicht möglich.`,
                tooltipText: 'Undo',
                disabled: true
              }
            }
          }

          if((einrichtung.freiePlaetze && einrichtung.gesamtPlaetze !== null)
              && Number.isInteger(einrichtung.freiePlaetze)
              && Number.isInteger(einrichtung.gesamtPlaetze)){
            // Perfectly balanced (as all things should be) -> green background -> check icon
            if(einrichtung.freiePlaetze === 0){
              return {
                iconName: 'check_circle',
                iconColor: 'success',
                backgroundColor: 'background-color: rgb(0, 107, 3, 0.2)',
                headerText: einrichtung.name,
                subheaderText: `0 von ${einrichtung.gesamtPlaetze} Plätze frei`,
                tooltipText: 'Perfekt ausgelastet',
                disabled: false,
                prio_status: this.bedarfsAnmeldung.priorities[index].status,
                prio: ++index,
                status: status
              }
            }
            console.log(this.bedarfsAnmeldung);

            // There are free seats
            if(einrichtung.freiePlaetze > 0){
              return {
                iconName: 'add',
                iconColor: 'primary',
                backgroundColor: '',
                headerText: einrichtung.name,
                subheaderText: `${einrichtung.freiePlaetze} von
                                ${einrichtung.gesamtPlaetze} Plätze frei`,
                tooltipText: 'Zuteilen',
                disabled: false,
                prio_status: this.bedarfsAnmeldung.priorities[index].status,
                prio: ++index,
                status: status
              }
            }

            // KBE is overloaded -> red background -> warning icon
            if(einrichtung.freiePlaetze < 0){
              return {
                iconName: 'warning',
                iconColor: 'error',
                backgroundColor: 'background-color: rgb(142, 0, 0, 0.2)',
                headerText: einrichtung.name,
                subheaderText: `${einrichtung.freiePlaetze} von
                                ${einrichtung.gesamtPlaetze} Plätze frei`,
                tooltipText: 'Überlastet',
                disabled: false,
                prio_status: this.bedarfsAnmeldung.priorities[index].status,
                prio: ++index,
                status: status
              }
            }
          } else {
            return {
              iconName: 'add',
              iconColor: 'primary',
              backgroundColor: '',
              headerText: einrichtung.name,
              subheaderText: `- Einrichtung hat keine Platzangabe -`,
              tooltipText: 'Hinzufügen',
              disabled: false,
              prio_status: this.bedarfsAnmeldung.priorities[index].status,
              prio: ++index,
              status: status
            }
          }
        },

        /**
         * getTileProps - Calculating the properties for the drop down menu
         *                for Einrichtung roles
         *
         * @param  {type} all The priorities array from the registration
         * @return {type}     Object containing the properties for EinrichtungActionComponent
         */
        getTileProps(all){
          let index = -1;

          // Finding the priority index for the facility belonging to the user
          for(let i = 0; i < all.length; ++i){
            let c = this.mapEinrichtung(all[i].id);
            if(c.id !== 'Undefiened'){
              index = i;
            }
          }
          let result = [];
          for(let i = 0; i < all.length; ++i){
            let p = this.mapEinrichtung(all[i].id);

            // Not your own Einrichtung
            if(p.id === "Undefiened"){
              if(all[i].status === null || all[i].status === 'OFFEN'){
                if(index > i){

                  // Facility which does not belong to the user has a higher
                  // priority and did not take action yet
                  result.push({
                    iconName: 'timer',
                    iconColor: 'error darken-2',
                    backgroundColor: '',
                    headerText: 'Handlung steht aus.',
                    subheaderText: 'Eine Einrichtung mit höherer Priorität muss noch handeln.',
                    disabled: true,
                    prio: i,
                    einrichtung: p,
                    undoAssign: false,
                    undoRecline: false,
                  })
                } else {

                  // Facility which does not belong to the user with a lower
                  // priority did not take action yet
                  result.push({
                    iconName: 'timer',
                    iconColor: 'error darken-2',
                    backgroundColor: '',
                    headerText: 'Einrichtung wählt nach Ihnen',
                    subheaderText: 'Eine Einrichtung mit geringerer Priorität wählt nach Ihnen. ',
                    disabled: true,
                    prio: i,
                    einrichtung: p,
                    undoAssign: false,
                    undoRecline: false,
                  })
                }
              }

              // Facility which does not belong to the user accepted the registration
              if(all[i].status === 'ANGENOMMEN'){
                result.push({
                  iconName: 'check',
                  iconColor: 'success darken-2',
                  backgroundColor: '',
                  headerText: 'Diese Einrichtung hat angenommen',
                  subheaderText: 'Das Kind ist vorerst vergeben.',
                  disabled: true,
                  prio: i,
                  einrichtung: p,
                  undoAssign: false,
                  undoRecline: false,
                })
              }

              // Facility which does not belong to the user declined the registration
              if(all[i].status === 'ABGELEHNT'){
                result.push({
                  iconName: 'block',
                  iconColor: 'error darken-2',
                  backgroundColor: '',
                  headerText: 'Diese Einrichtung hat abgelehnt',
                  subheaderText: 'Das Kind wurde von dieser Einrichtung abgelehnt',
                  disabled: true,
                  prio: i,
                  einrichtung: p,
                  undoAssign: false,
                  undoRecline: false,
                })
              }
            } else {
              let yourTurn = true;
              let accepted = false;
              let tooLate = false;
              for(let y = i -1; y >= 0; --y){
                if(all[y].status === 'ANGENOMMEN'){
                  accepted = true;
                }
                if(all[y].status === null || all[y].status === 'OFFEN' || all[y].status === 'ANGENOMMEN'){
                  yourTurn = false;
                }
              }
              for(let y = i + 1; y < all.length; ++y){
                if(all[y].status === 'ANGENOMMEN'){
                  yourTurn = false;
                  tooLate = true;
                }
              }
              if(this.bedarfsAnmeldung.zugeteilteEinrichtung.id !== null){
                if(all.filter(x => x.id === this.bedarfsAnmeldung.zugeteilteEinrichtung.id).length === 0){
                  accepted = true;
                  yourTurn = false;
                }
              }
              if(yourTurn){
                if(all[i].status === "ANGENOMMEN" || all[i].status === "ABGELEHNT"){
                  if(all[i].status === "ANGENOMMEN"){

                    // Facility belonging to the user accepted the registration
                    // Undo is possible
                    result.push({
                      iconName: 'check',
                      iconColor: 'success darken-2',
                      backgroundColor: 'background-color: rgb(0, 107, 3, 0.2)',
                      headerText: 'Sie haben das Kind angenommen.',
                      subheaderText: `Das Kind wurde ${p.name} zugeteilt.`,
                      disabled: false,
                      prio: i,
                      einrichtung: p,
                      undoAssign: true,
                      undoRecline: false,
                    })
                  } else {

                    // Facility belonging to the user declined the registration
                    // but lower priority did not take action yet therefore an
                    // undo is still possible
                    this.undo_possible = true;
                    result.push({
                      iconName: 'block',
                      iconColor: 'success darken-2',
                      backgroundColor: 'background-color: rgb(142, 0, 0, 0.2)',
                      headerText: 'Sie haben das Kind abgelehnt.',
                      subheaderText: `${p.name} - abgelehnt. Andere Einrichtungen sind nun dran.`,
                      disabled: false,
                      prio: i,
                      einrichtung: p,
                      undoAssign: false,
                      undoRecline: true,
                    })
                  }
                } else {

                  // Facility belonging to the user has to take action now
                  this.notifications_active = true;
                  result.push({
                    iconName: 'notifications_active',
                    iconColor: 'error darken-2',
                    backgroundColor: 'background-color: rgb(0, 107, 3, 0.2)',
                    headerText: 'Sie sind dran.',
                    subheaderText: `Zuteilung zu ${p.name}?`,
                    disabled: false,
                    prio: i,
                    einrichtung: p,
                    undoAssign: false,
                    undoRecline: false,
                  })
                }
              } else {
                if(accepted){

                  // Facility with higher priority accepted the registration
                  result.push({
                    iconName: 'block',
                    iconColor: 'error darken-2',
                    backgroundColor: 'background-color: rgb(0, 107, 3, 0.2)',
                    headerText: 'Sie brauchen nicht warten ',
                    subheaderText: 'Eine Einrichtung mit höherer Priorität hat das Kind angenommen. ',
                    disabled: true,
                    prio: i,
                    einrichtung: p,
                    undoAssign: false,
                    undoRecline: false,
                  })
                } else {
                  if(tooLate){

                    // Facility with lower priority accepted the registration
                    // Therefore an undo for the facility belonging to the user
                    // is not possible
                    result.push({
                      iconName: 'block',
                      iconColor: 'error darken-2',
                      backgroundColor: '',
                      headerText: 'Rückgängig machen nicht möglich. ',
                      subheaderText: 'Eine Einrichtung nach Ihnen hat das Kind zugeteilt bekommen. ',
                      disabled: true,
                      prio: i,
                      einrichtung: p,
                      undoAssign: false,
                      undoRecline: false,
                    })
                  } else {

                    // Facility belonging to the user has to wait for facility
                    // with higher priority to take action.
                    result.push({
                      iconName: 'home',
                      iconColor: 'error darken-2',
                      backgroundColor: 'background-color: rgb(0, 107, 3, 0.2)',
                      headerText: 'Warten Sie. ',
                      subheaderText: 'Eine Einrichtung mit höherer Priorität ist vor Ihnen dran. ',
                      disabled: true,
                      prio: i,
                      einrichtung: p,
                      undoAssign: false,
                      undoRecline: false,
                    })
                  }
                }
              }
            }
          }
          return result
        },
        assignRegistration(einrichtung, noPrio = false, undo = 'KV') {
          this.notifications_active = false;
          this.undo_possible = false;

        /**
          * Triggers when the assign registration was clicked.
          *
          * @property {object} item Facility and registration to assign to.
          */
          this.$emit('assignRegistration', {
              einrichtung: einrichtung,
              bedarfsAnmeldung: this.bedarfsAnmeldung,
              noPrio: noPrio,
              undo: undo
          })
          setTimeout(()=>{
            this.menuOpen = false;
          }, 0)
        },
        declineRegistration(einrichtung) {
          this.notifications_active = false;
          this.undo_possible = false;
        /**
          * Triggers when the decline registration was clicked.
          *
          * @property {object} item Facility and registration to decline.
          */
          this.$emit('declineRegistration', {
              einrichtung: einrichtung,
              bedarfsAnmeldung: this.bedarfsAnmeldung
          })
        },
        emitRecline(){
        /**
          * Triggers when the recline registration was clicked.
          *
          * @property {object} registration The registration to recline.
          */
          this.$emit('recline', this.bedarfsAnmeldung);
        },
        emitUndoRecline(){
        /**
          * Triggers when the undo recline registration was clicked.
          *
          * @property {object} registration The registration to undo recline on.
          */
          this.$emit('undoRecline', this.bedarfsAnmeldung);
        },
        emitRefuse(){
        /**
          * Triggers when the refuse registration was clicked.
          *
          * @property {object} registration The registration to refuse.
          */
          this.$emit('refuse', this.bedarfsAnmeldung);
        },
        emitComment(){
        /**
          * Triggers when the add comment was clicked.
          *
          * @property {object} bedarfsAnmeldung The registration to comment.
          */
          this.$emit('comment', this.bedarfsAnmeldung);
        },
        emitExplanation(title, explanation){
        /**
          * Triggers when the explanation icon on the menu was clicked
          *
          * @property {string} title The title the explanation dialog is supposed to have.
          * @property {string} explanation The explanation to display.
          */
          this.$emit("emitExplanation", title, explanation)
        },
        emitArchive(){
        /**
          * Triggers when the archive registration was clicked.
          *
          * @property {object} registration The registration to archive.
          */
          this.$emit('emitArchive', this.bedarfsAnmeldung);
        },
        emitInternalPriority(prio){        
        /**
          * Triggers when the emit internal priority button was clicked
          *
          * @property {string} id The id of the registration to assign the priority to.
          * @property {number} prio The score of the internal priority (1 - 5 and null)
          */
          this.$emit('emitInternalPriority', this.bedarfsAnmeldung.id, prio);
        }
    },
    components: {
      ListComponent,
      AdditionalKitaComponent,
      AdditionalActionComponent,
      EinrichtungActionComponent,
      Tooltip,
      Button
    }
}
</script>
<style lang="css" scoped>

</style>
