/**
 * No operation.
 */
export const noop = () => {};

/**
 * transformDate - Checks if a given string is in ISO-Date format
 * if so a 'DD.MM.YYYY' representation of the date gets returned.
 *
 * @param  {type} date the string to check
 * @return {type}      'DD.MM.YYYY' representation of the ISO date
 */
export function transformDate (date) {
  if(date != null && typeof date === 'string'){
    let iso = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
    let match = date.match(iso);
    if(match == null){
      return date
    }
    let newDate = new Date(date);
    let dayPrefix = '';
    let monthPrefix = '';
    if (newDate instanceof Date && !isNaN(newDate.valueOf()) && date !== true && date !== false) {
      if(newDate.getDate() < 10){
        dayPrefix = '0';
      };
      if(newDate.getMonth() + 1 < 10){
        monthPrefix = '0';
      }
      return `${dayPrefix}${newDate.getDate()}.${monthPrefix}${newDate.getMonth() + 1}.${newDate.getFullYear()}`
    };
  }
  return date;
}

/**
 * firstLetterUpperCase - Transforms the first letter of a fiven string to
 * upper case.
 *
 * @param  {type} word Word to transform
 * @return {type}      Word with first letter in upper case
 */
export function firstLetterUpperCase (word) {
  if(word != null && typeof word === 'string'){
    return word.charAt(0).toUpperCase() + word.substr(1);
  }
  return word;
}

/**
 * calculateAge - Calculates the number of years between two given dates.
 *                Used for calculating the age of a child.
 *                (Function is not precise +- 4 days margin)
 *
 * @param  {type} birthdate  Chronological first date
 * @param  {type} targetDate Chronological second date
 * @return {type}            the number of years
 */
export function calculateAge (birthdate, targetDate) {
  if(birthdate === null || targetDate === null){
    return "-"
  }
  let seconds = (new Date(targetDate).getTime() - new Date(birthdate).getTime()) / 1000;
  let days = 0;
  let month = 0;
  let years = 0;
  if(seconds >= 0){
    days = Math.floor(seconds / 86400);
    month = Math.floor(days / 30.3);
    years = Math.floor(month / 12);
  }
  let rest = month % 12;
  return {
    year: years,
    month: rest
  }
}

/**
 * calculateAddedHours - Calculates the Wochenstunden for a betreuungszeit object
 *                      ( {montag: {von: "", bis: ""}} ...)
 *
 * @param  {type} betreuungszeiten  registration.betreuungszeit object
 * @param  {type} multiplicator = 1 number of children of the registration
 * @return {type}                   a string containing hours and minutes of
 *                                  the calculated Wochenstunden
 */
export function calculateAddedHours (betreuungszeiten, multiplicator = 1) {
  if(betreuungszeiten !== null){
    let minutes = 0;
    let daysPerWeek = 0;
    Object.keys(betreuungszeiten).forEach(item => {
      if(betreuungszeiten[item]){
         if(betreuungszeiten[item].von && betreuungszeiten[item].bis){
           let von = betreuungszeiten[item].von.split(':');
           let bis = betreuungszeiten[item].bis.split(':');
           let c = ((parseInt(bis[0]) - parseInt(von[0])) - 1) * 60;
           c += 60 - parseInt(von[1]) + parseInt(bis[1]);
           daysPerWeek++;
           minutes += c;
         }
      }
    })
    minutes *= multiplicator;
    let hours = (minutes / 60) >> 0;
    let rest = minutes % 60;
    return `${hours} Stunden und ${rest} Minuten an ${daysPerWeek} Tagen`;
  }
  return `Keine Wochenstunden angegeben.`;

}

/**
 * findNameFromID - Returns the name from a given array containing objects
 *                  with at least the fields id and name
 *
 * @param  {type} id          the id of the desired name
 * @param  {type} searchArray the array to search in
 * @return {type}             the name from the id object
 */
export function findNameFromID (id, searchArray) {
  if(id !== null && Array.isArray(searchArray)){
    let index = searchArray.findIndex(x => x.id === id);
    if(index > -1){
      return searchArray[index].name;
    }
  }
  return null;
}

/**
 * dateToSeconds - Converts a given iso date to milliseconds
 *
 * @param  {type} date Given iso date
 * @return {type}      Milliseconds of the given date
 */
export function dateToSeconds(date){
  if(date !== null){
      return new Date(date).getTime();
  }
  return null;
}

/**
 * compareLexicographical - Compares to string lexicographically
 *
 * @param  {type} a The first string
 * @param  {type} b The seconde string
 * @return {type}   -1, 1 or 0 depending on the comparison
 */
export function compareLexicographical(a, b){
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    }
    return 0;
}

/**
 * flattenRegistration - Flattens a registration to depth one. Needs einrichtung
 *                       and einrichtungsarten to resolve fields.
 *
 * Example { kindesdaten: [vorname: 'Test']} => kindesdaten.0.vorname: 'Test'
 *
 * @param  {type} einrichtungen     [{name: '', id: ''}] for resolving
 * @param  {type} einrichtungsarten [{name: '', id: ''}] for resolving
 * @param  {type} registration      a registration
 * @return {type}                   a flattened registration object with
 *                                  paths as key and value as value
 */
export function flattenRegistration(einrichtungen, einrichtungsarten, registration){

  let flatten = (items, level, parent, res, obj) => {
    if(typeof items === 'object' && items !== null){
      Object.keys(items).forEach(key => {
        return flatten(items[key], ++level, `${parent}.${key}`, res, items);
      })

    // Special cases
    } else {
      if(["Eingliederungshilfe nach SGB VIII (KJHG)",
      "Eingliederungshilfe wegen körperlicher/geistiger Behinderung",
      "Eingliederungshilfe wegen seelischer Behinderung"].includes(items)) {
        let newParent = parent.replace(/integrationsbedarf.[0-9]/, items);
        res[newParent] = true;
        return res;
      }
      if(["PRIVAT", "ARBEIT", "HANDY", "FAX"].includes(items)){
        let newParent = parent.replace(/[0-9].kontaktnummernArt/, items);
        res[newParent] = obj.nummer;
        return res;

      }
      if([".priorities.0.id", ".priorities.1.id", ".priorities.2.id",
      ".priorities.3.id", ".priorities.4.id"].includes(parent)){
        res[parent] = findNameFromID(obj.id, einrichtungen);
        return res;
      }
      if(parent === ".einrichtungsart"){
        res[parent] = findNameFromID(items, einrichtungsarten);
        return res;
      }
      if(parent === ".zugeteilteEinrichtung"){
        res[parent] = findNameFromID(items, einrichtungen);
        if(items !== null){
          let index = einrichtungen.findIndex(x => x.id === items);
          if(index >= 0 && einrichtungen[index].einrichtungsnummer !== null){
            res['.Kita_ID'] = einrichtungen[index].einrichtungsnummer;
          } else {
            res[".Kita_ID"] = ' - ';
          }
        }
        return res;
      }
      if([".kindesdaten.0.kindId", ".kindesdaten.1.kindId", ".kindesdaten.2.kindId",
      ".kindesdaten.3.kindId", ".kindesdaten.4.kindId"].includes(parent)){
        res['.kindId'] = `${obj.vorname}_${obj.name}_${obj.geburtsdatum}`;
        return res;
      }
      res[parent] = items;
      return res;
    }
    return res;
  }
  return flatten(registration, 0, '', {}, null);
}

/**
 * convertToCSV - Takes a dictionary and a flattend representation of a registration
 *                and converts it to a comma separated values string.
 *
 * Dict exmaple: { kindesdaten.0.vorname: 'Kind-1-Vorname'}
 *
 * @param  {type} dict      a dictionary mapping the flattend key paths to a
 *                          more human readable string
 * @param  {type} flattened the flattend registrations
 * @return {type}           a csv string
 */
export function convertToCSV(dict, flattened){
  let result = '';
  Object.keys(dict).forEach(dictKey => {
    result += `${dict[dictKey]},`
  })
  result = result.substring(0, result.length - 1);
  result += '\n'

  flattened.forEach(registration => {
      Object.keys(dict).forEach(dictKey => {
        if(typeof registration[dictKey] !== 'undefined' && registration[dictKey] !== null){
          result += `"=""${(typeof registration[dictKey] === 'string' && registration[dictKey].replace(/[\n\r]/g,' ')) || registration[dictKey]}""",`
        } else {
          result += ' ,'
        }
      })
      result = result.substring(0, result.length - 1);
      result += '\n'
  })
  return result;
}

/**
 * convertToCSV - Takes a dictionary and a flattend representation of a registration
 *                and converts it to a comma separated values string.
 *
 * Dict exmaple: { kindesdaten.0.vorname: 'Kind-1-Vorname'}
 *
 * @param  {type} dict      a dictionary mapping the flattend key paths to a
 *                          more human readable string
 * @param  {type} flattened the flattend registrations
 * @return {type}           a csv string
 */
 export function convertToCSVWINKITA(dict, flattened){
  let result = '';
  Object.keys(dict).forEach(dictKey => {
    result += `${dict[dictKey]};`
  })
  result = result.substring(0, result.length - 1);
  result += '\n'

  flattened.forEach(registration => {
      Object.keys(dict).forEach(dictKey => {
        if(typeof registration[dictKey] !== 'undefined' && registration[dictKey] !== null){
          result += `${(typeof registration[dictKey] === 'string' && registration[dictKey].replace(/[\n\r]/g,' ')) || registration[dictKey]};`
        } else {
          result += ' ;'
        }
      })
      result = result.substring(0, result.length - 1);
      result += '\n'
  })
  return result;
}


/**
 * prepareRegistrations - Gets the state from reducers that deal with
 *                        registrations. The state needs to contain the facilities.
 *
 *                        The methods calculates various things needed for
 *                        displaying the assignment view (statistics, resolved IDs etc. )
 *
 * @param  {type} state.einrichtungen   The facilities to resolve IDs
 * @param  {type} action.payload.response The registrations.
 * @return {type}         Object containing the prepared keys needed to display the
 *                        assignment and archive view.
 */
export function prepareRegistrations(state, action){

  // Calculating the rows with the vals needed for the table
  const newState = action.payload.response;
  let projection = [];
  let searchIndex = [];
  const er_facilities = state.einrichtungen.map(x => x.id);

  let selectNames = [];

  for (let i = 0; i < newState.length; ++i) {
      let aufnahmedatumVal = null;
      let aufnahmedatumSeconds = null;
      if (newState[i].aufnahmedatum !== null && newState[i].aufnahmedatum.von) {
          aufnahmedatumVal = transformDate(newState[i].aufnahmedatum.von);
          aufnahmedatumSeconds = dateToSeconds(newState[i].aufnahmedatum.von);
      }
      let assignedFacility = '-';
      if(findNameFromID(newState[i].zugeteilteEinrichtung, state.einrichtungen) !== null){
        assignedFacility = findNameFromID(newState[i].zugeteilteEinrichtung, state.einrichtungen);
      }

      const allRejected = (currentValue) => currentValue.status === 'ABGELEHNT';

      let tableRow = {
          id: newState[i].id,
          bemerkung: newState[i].bemerkung,
          comment: newState[i].kindesdaten[0].bemerkungen,
          priorities: JSON.parse(JSON.stringify(newState[i].priorities)),
          zugeteilteEinrichtung: {
              value: assignedFacility === '-' && newState[i].zugeteilteEinrichtung !== null ? 'Andere KBE' : assignedFacility === '-' ? null : assignedFacility,
              id: newState[i].zugeteilteEinrichtung,
              tooltip: `Zugeteilt an: ${assignedFacility}`,
              highlight: false
          },
          aufnahmedatum: [{
              value: aufnahmedatumVal,
              tooltip: calculateAddedHours(newState[i].betreuungszeit, newState[i].kindesdaten.length),
              highlight: false
          }],
          aufnahmedatumSeconds: aufnahmedatumSeconds,
          name: [],
          geburtsdatum: [],
          birthdateSeconds: dateToSeconds(newState[i].kindesdaten[0].geburtsdatum),
          wohnort: [],
          status: {
              value: newState[i].status,
              tooltip: "Status",
              highlight: false
          },
          iStatus: [],
          allRejected: newState[i].priorities.every(allRejected),
          internalPriority: newState[i].internalPriority,
          einrichtungsart: newState[i].einrichtungsart,
          facility_role_must_act: false
      };

      let integrationsbedarf = false;
      let integrationsbedarfTooltip = 'Integrationsbedarf';
      let isSingleParent = false;
      if (newState[i].kindesdaten[0].bemerkungen !== null &&
            newState[i].kindesdaten[0].bemerkungen.trim() !== ''){
        tableRow.iStatus.push({
            value: 'b',
            tooltip: 'Bemerkung vorhanden',
            highlight: true
        })
      }

      let all_verteter_berufstaetig = true;
      let at_least_one_verteter_berufstaetig = false;
      newState[i].vertreter.forEach(vertreter => {
        if(vertreter.berufstaetig && vertreter.berufstaetig !== null && vertreter.berufstaetig === true) {
          at_least_one_verteter_berufstaetig = true;
        } else {
          all_verteter_berufstaetig = false;
        }
      })

      if(all_verteter_berufstaetig === true){
        tableRow.iStatus.push({
          value: 'BT',
          tooltip: 'Alle Verteter sind berufstätig',
          highlight: true
        })
      } else {
        if(at_least_one_verteter_berufstaetig === true) {
          tableRow.iStatus.push({
            value: 'bt',
            tooltip: 'Mindestens ein Vertreter ist berufstätig',
            highlight: true
          })
        }
      }




      if (newState[i].geschwister !== null &&
            newState[i].geschwister.length > 0){
              let gk_tooltip = 'Geschwisterkind';
              if(newState[i].geschwister[0].einrichtung && newState[i].geschwister[0].einrichtung !== null){
                gk_tooltip += ` - Einrichtung: ${newState[i].geschwister[0].einrichtung}`
              }
        tableRow.iStatus.push({
            value: 'gk',
            tooltip: gk_tooltip,
            highlight: true
        })
      }
      if (newState[i].aufnahmedatum !== null && newState[i].aufnahmedatum.von !== null) {
        // Wished admission date by parents
        let date = new Date(newState[i].aufnahmedatum.von);

        // Current year
        let year = new Date().getFullYear();
        let month = new Date().getMonth();

        // Its early in the year so registration belongs to previous season
        if(month <= 7){
          --year;
        }
        let seasonDate = new Date(`${year}-09-02`);
        let seasonDateSucc = new Date(`${++year}-08-31`);
        if((date.getTime() >= seasonDate.getTime()) && (date.getTime() <= seasonDateSucc.getTime())){
          tableRow.iStatus.push({
              value: 'u',
              tooltip: 'Unterjährig',
              highlight: true
          })
        }
      }

      // Sie sind dran - Filter for einrichtung role
      if(action.payload.user_role !== null && action.payload.user_role === 'ER' || action.payload.user_role === 'Einrichtung'){

        newState[i].priorities.forEach((prio, index) => {

          // Facility given in prio belongs to the user
          if(er_facilities.includes(prio.id)){

            // Registration is not assigned yet
            if(prio.status === null || prio.status === 'OFFEN'){

              // Check if prev prio did reject registration
              if(index === 0 || newState[i].priorities[index - 1].status === 'ABGELEHNT'){

                // Show only registrations that are not reclined
                if(newState[i].status !== 'ZURUECKGESTELLT') {
                  tableRow.facility_role_must_act = true;
                }  
              }
            }
          }
        })
      }
      if(newState[i].alternativesAufnahmedatum !== null && newState[i].alternativesAufnahmedatum.von !== null){
        tableRow.iStatus.push({
            value: 'ad',
            tooltip: `Alternatives Aufnahmedatum: ${newState[i].alternativesAufnahmedatum.von}`,
            highlight: true
        })
      }
      newState[i].kindesdaten.forEach(child => {
          if (child.integrationsbedarf !== null && child.integrationsbedarf.length > 0) {
              integrationsbedarf = true;
              tableRow.iStatus.push({
                  value: 'i',
                  tooltip: integrationsbedarfTooltip,
                  highlight: true
              })
          }
          let gender = "Kein Geschlecht angegeben";
          if (child.geschlecht !== null) {
              gender = child.geschlecht;
          };
          tableRow.name.push({
              value: `${child.name} ${child.vorname}`,
              tooltip: gender,
              sortVal: `${child.name}`
          });

          let age = null;
          let suffix = 'alt';
          if (newState[i].aufnahmedatum !== null && newState[i].aufnahmedatum.von) {
              age = calculateAge(child.geburtsdatum, newState[i].aufnahmedatum.von);
              suffix = 'alt zum Zeitpunkt der Aufnahme.'
          } else {
              age = calculateAge(child.geburtsdatum, new Date());
          }
          let yearString = 'Jahre';
          if (age && age.year === 1) {
              yearString = 'Jahr';
          }
          tableRow.geburtsdatum.push({
              value: transformDate(child.geburtsdatum),
              tooltip: `${age.year} ${yearString}, ${age.month} Monate ${suffix}`,
              years: age.year,
              months: age.month
          })

          let wohnort = child.wohnort;
          let found = false;

          // Resolving wohnort, child.wohnort lists the name of the parent
          // the child is living with
          if (wohnort !== null) {
              newState[i].vertreter.forEach(x => {
                  let vName = `${x.vorname} ${x.name}`.toUpperCase().trim();
                  if (vName === wohnort.toUpperCase().trim()) {
                      if (x.hauptwohnsitz.ort) {
                          found = true;
                          tableRow.wohnort.push({
                              value: x.hauptwohnsitz.ort,
                              tooltip: `${x.hauptwohnsitz.postleitzahl}, ${x.hauptwohnsitz.strasse} ${x.hauptwohnsitz.hausnummer}`
                          })
                      }
                  };
                  if(x.alleinerziehend){
                    if(!isSingleParent){
                      isSingleParent = true;
                      tableRow.iStatus.push({
                          value: 'a',
                          tooltip: 'Alleinerziehend',
                          highlight: true
                      })
                    }
                  }
              });
              if (!found) {
                  tableRow.wohnort.push({
                      value: null,
                      tooltip: 'Keine Angabe zum Wohnort gemacht. '
                  });
              }
          } else {
              tableRow.wohnort.push({
                  value: null,
                  tooltip: 'Keine Angabe zum Wohnort gemacht. '
              });
          }
      })

      let at_least_one_neuer_hauptwohnsitz = false;
      
      if (newState[i].vertreter
        .map(parent => parent.hasOwnProperty('neuerHauptwohnsitz') && parent.neuerHauptwohnsitz !== null && Object.keys(parent.neuerHauptwohnsitz).length > 0)
        .reduce((prev, curr) => { return prev || curr }, false)) {
          const nh_tooltip = 'Neuer Hauptwohnsitz';
          tableRow.iStatus.push({
            value: 'nh',
            tooltip: nh_tooltip,
            highlight: true
          });
        at_least_one_neuer_hauptwohnsitz = true;
      }

      if (tableRow.iStatus.length === 0) {
          tableRow.iStatus = null
      }
      tableRow.benachrichtigungsstatus = newState[i].benachrichtigungsstatus;
      projection.push(tableRow);

      // Creating Search Index
      let vertreterArray = [];
      let childrenArray = [];
      let prioArray = [];
      let specialFeatures = [];
      let singleParent = false;

      if(tableRow.geburtsdatum.length !== 0){
        let ageInMonths = tableRow.geburtsdatum[0].years * 12 + tableRow.geburtsdatum[0].months;
        specialFeatures.push(`$$AGE-${ageInMonths}`);
      }
      if(tableRow.internalPriority !== null){
        specialFeatures.push(`INTERNALPRIO`);
      }
      if (tableRow.einrichtungsart !== null) {
        if(tableRow.einrichtungsart.split(',').length < 4) {
          generateCombinations(tableRow.einrichtungsart.split(','))
          .map(x => `EINRICHTUNGSART-${x.join(',').toUpperCase()}`)
          .forEach(x => specialFeatures.push(x))
        }
      }
      if(tableRow.facility_role_must_act === true){
        specialFeatures.push(`FACILITYROLEMUSTACT`);
      }
      if(tableRow.allRejected){
        specialFeatures.push("ALLREJECTED");
      }
      if(newState[i].alternativesAufnahmedatum !== null){
        specialFeatures.push("ALTERNATIVEDATE")
      }
      if(all_verteter_berufstaetig === true){
        specialFeatures.push("ALL_VERTRETER_BERUFSTAETIG")
      }
      if(at_least_one_verteter_berufstaetig === true){
        specialFeatures.push("AT_LEAST_ONE_VERTRETER_BERUFSTAETIG")
      }
      if (at_least_one_neuer_hauptwohnsitz === true) {
        specialFeatures.push("AT_LEAST_ONE_NEUER_HAUPTWOHNSITZ")
      }

      let singleParentFound = false;
      newState[i].vertreter.forEach(x => {
          vertreterArray.push(`${x.name.toUpperCase()} ${x.vorname.toUpperCase()}`);
          vertreterArray.push(`${x.vorname.toUpperCase()} ${x.name.toUpperCase()}`);
          vertreterArray.push(`${x.hauptwohnsitz.ort.toUpperCase().trim()}`);
          selectNames.push({
            id: selectNames.length,
            registrationID: newState[i].id,
            name: `${x.name} ${x.vorname} ${x.name}`,
            index: i,
            isParent: true,
            isChild: false,
            firstName: `${x.vorname}`,
            lastName: `${x.name}`,
            parentOf: `${newState[i].kindesdaten[0].vorname} ${newState[i].kindesdaten[0].name}`,
            age: null
          })

          if(x.alleinerziehend){
            singleParent = true;
            if(!singleParentFound){
              singleParentFound = true;
            }
          }
      });
      if(singleParent){
        specialFeatures.push("SINGLEPARENT");
      }
      if(newState[i].geschwister !== null && newState[i].geschwister.length > 0){
        specialFeatures.push("SIBLINGS");
      }
      if(newState[i].aufnahmedatum !== null && newState[i].aufnahmedatum.von !== null){
        let date = new Date(newState[i].aufnahmedatum.von);
        let year = new Date().getFullYear();
        let month = new Date().getMonth();

        // Its early in the year so registration belongs to previous season
        if(month <= 7){
          --year;
        }
        let seasonDate = new Date(`${year}-09-01`);
        let seasonDateSucc = new Date(`${++year}-08-31`);

        if((date.getTime() >= seasonDate.getTime()) && (date.getTime() <= seasonDateSucc.getTime())) {
          specialFeatures.push("UNTERJAEHRIG");
        }
      }
      newState[i].kindesdaten.forEach(child => {
          let age = null;
          if (child.geburtsdatum) {
              age = calculateAge(child.geburtsdatum, new Date);
          }

          if(child.bemerkungen !== null && child.bemerkungen.length > 0){
            specialFeatures.push("BEMERKUNG");
          }

          if (child.integrationsbedarf !== null && child.integrationsbedarf.length >= 1){
            specialFeatures.push("INTEGRATIONSBEDARF");
          }
          childrenArray.push(`${child.name.toUpperCase().trim()} ${child.vorname.toUpperCase().trim()}`)
          childrenArray.push(`${child.vorname.toUpperCase().trim()} ${child.name.toUpperCase().trim()}`)
          childrenArray.push(`${child.vorname.toUpperCase().trim()}`)
          childrenArray.push(`${child.name.toUpperCase().trim()}`)
          selectNames.push({
            id: selectNames.length,
            registrationID: newState[i].id,
            name: `${child.name} ${child.vorname} ${child.name}`,
            index: i,
            isParent: false,
            isChild: true,
            firstName: `${child.vorname}`,
            lastName: `${child.name}`,
            parentOf: null,
            age: age !== null ? `${age.year} Jahre alt` : null
          })

          let singleName = child.vorname.split(" ");
          if (singleName.length > 1) {
              childrenArray.push(`${singleName[0].toUpperCase()}`);
              childrenArray.push(`${singleName[0].toUpperCase()} ${child.name.toUpperCase()}`);
              childrenArray.push(`${child.name.toUpperCase().trim()} ${singleName[0].toUpperCase()}`);
          }
      });
      let c = 1;
      newState[i].priorities.forEach(kita => {
          let index = state.einrichtungen.findIndex(x => x.id === kita.id);
          if (index >= 0) {
              prioArray.push(state.einrichtungen[index].id.toUpperCase());
              prioArray.push(`${state.einrichtungen[index].id.toUpperCase()}-PRIO:${c}`);
          }
          c++;
      });
      if (newState[i].zugeteilteEinrichtung !== null) {
          let name = findNameFromID(newState[i].zugeteilteEinrichtung, state.einrichtungen);
          if (name !== null) {
              prioArray.push(name.toUpperCase());
          }
      };
      let registrationStatus = newState[i].status;
      if (newState[i].status === null){
        registrationStatus = 'OFFEN';
      }
      searchIndex.push({
          index: i,
          searchFlat: [...[].concat.apply([], vertreterArray),
              ...[].concat.apply([], childrenArray), ...prioArray,
              ...specialFeatures, registrationStatus
          ]
      });
  }
  selectNames.sort(compareLexicographical);
  for(let i = 0; i < selectNames.length; ++i){
    selectNames[i].id = i;
  }
  return {
      ...state,
      bedarfsAnmeldungen: newState,
      tableProjection: projection,
      tableFull: projection,
      isFetching: false,
      searchIndex: searchIndex,
      names: selectNames
  }
}

/**
 * getEntity - description
 *
 * @param  {type} obj description
 * @return {type}     description
 */
export function getEntity(obj){
  if(typeof obj === 'undefined' || obj === null){
    return 'Undefined';
  }
  if(Object.keys(obj).includes('zugeteilteEinrichtung')){
    return 'Bedarfsanmeldung';
  }
  if(Object.keys(obj).includes('minimalAlter')){
    return 'Einrichtungsart';
  }
  if(Object.keys(obj).includes('location')){
    return 'Einrichtung';
  }
  if(Object.keys(obj).includes('license')){
    return 'Einrichtungsbild';
  }
  if(Object.keys(obj).includes('traegerArt')){
    return 'Träger';
  }
  if(Object.keys(obj).includes('zuteilungszeitraum')){
    return 'Saisonparameter';
  }
  if(Object.keys(obj).includes('buergerserviceportalUrl')){
    return 'Mandantparameter';
  }
  if(Object.keys(obj).includes('senderName')){
    return 'Postkorbnachricht';
  }
  return 'Undefined'
}

/**
 * Generate all unique combinations given an array.
 * e.g. ['a','b'] => [['a'], ['a', 'b'], ['b'], ['b', 'a']].
 */
function generateCombinations(arr) {
  const results = [];

  function combine(currentCombination, remainingElements) {
    if (currentCombination.length > 0) {
      results.push(currentCombination.slice());
    }

    for (let i = 0; i < remainingElements.length; i++) {
      currentCombination.push(remainingElements[i]);
      const newRemaining = remainingElements.slice(0, i).concat(remainingElements.slice(i + 1));
      combine(currentCombination, newRemaining);
      currentCombination.pop();
    }
  }

  combine([], arr);
  return results;
}