import {noop} from '../../util/helper'
import {POSTepicFactory} from '../../util/epicFactories';

const prefix = `AUTH`;
export const IS_TRUSTED = `${prefix}/IS_TRUSTED`;
export const NEW_LOGOUT = `${prefix}/NEW_LOGOUT`;

export const newLogout = () => dispatch => {
  dispatch({
      type: NEW_LOGOUT,
      payload: {}
  });
  return Promise.resolve();
};
export const isTrusted = () => dispatch => {
    dispatch({
        type: IS_TRUSTED,
        payload: {}
    });
    return Promise.resolve();
};

const ACTION_HANDLERS = {
    [IS_TRUSTED]: (state, action) => {
        sessionStorage.setItem('tl', 1)
        return {
            ...state,
            trustlevel: 1,
            isFetching: false
        }
    },
    [NEW_LOGOUT]: (state, action) => {
        sessionStorage.removeItem('tl')
        return {
            trustlevel: 0,
            isFetching: false
        }
    }
}

export const actions = {
    isTrusted,
    newLogout
};

export const epics = []

const initialState = {
    trustlevel: parseInt(sessionStorage.getItem('tl')) || 0,
    isFetching: false
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
