import Vue from 'vue';
import VueRouter from 'vue-router';

import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

import './styles/index.styl';

import Revue from './util/revue';
import Application from './Application.vue';
import createRoutes from './views';

import { actions as reduxActions } from './reducer/';
import './util/filterFunctions.js';

import DiversIcon from './views/icon components/DiversIcon.vue';
import MaleIcon from './views/icon components/MaleIcon.vue';
import FemaleIcon from './views/icon components/FemaleIcon.vue';

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#00838F',
                secondary: '#90A4AE',
                accent: '#00BCD4',
                success: '#009688',
                error: '#EF5350',
            },
        },
    },
    icons: {
        iconfont: 'md',
        values: {
            divers: {
                component: DiversIcon,
            },
            male: {
                component: MaleIcon,
            },
            female: {
                component: FemaleIcon,
            },
        },
    },
};
Vue.use(Vuetify);

export function setupStore(LocalVue, reduxStore) {
    return new Revue(LocalVue, reduxStore, reduxActions);
}

export function setupRouter(LocalVue) {
    LocalVue.use(VueRouter);

    const routes = createRoutes();
    return new VueRouter({ mode: 'history', routes });
}

class AppRunner {
    store = null;
    router = null;
    constructor(reduxStore) {
        this.store = setupStore(Vue, reduxStore);
        this.router = setupRouter(Vue);
    }

    run() {
        this.router.beforeEach((to, from, next) => {
            if (
                to.matched &&
                to.matched.length > 0 &&
                to.matched[0].path === '/dashboard' &&
                this.store.state.auth.trustlevel === 0
            ) {
                const nextTo = {
                    meta: to.meta,
                    name: to.name,
                    params: to.params,
                    path: to.path,
                    query: to.query,
                };

                this.nextToCallback = (cb) => cb(nextTo);
                next('/');
            } else {
                next();
            }
        });

        if (
            this.store.state.auth.trustlevel === 1 &&
            this.router.currentRoute.fullPath === '/'
        ) {
            this.router.replace('/dashboard');
        }

        this.store.dispatch(
            this.store.reduxActions.user.info(() => {
                // Dummy request so the user will not get logged out by Spring backend
                setInterval(() => {
                    this.store.dispatch(
                        this.store.reduxActions.user.checkSession()
                    );
                }, 1500000);
            })
        );
        new Vue({
            el: document.getElementById('app'),
            vuetify: new Vuetify(opts),
            render: (h) => h(Application),
            store: this.store,
            router: this.router,
            $app: this,
        });
    }
}

export default (reduxStore) => {
    new AppRunner(reduxStore).run();
};
