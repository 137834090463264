import IndexPage from './index-page'
import Dashboard, {routes as dashboardRoutes} from './dashboard'

export default () => {
    return [{
        path: '/',
        component: IndexPage
    }, {
        path: '/dashboard',
        component: Dashboard,
        children: dashboardRoutes
    }]
}
