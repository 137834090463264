<template>
<v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title class="headline info" primary-title>
          <v-icon large style="color: white" class="pr-3">
            info
          </v-icon>
          <span style="color: white">
            {{ title }}
        </span>
          <v-spacer></v-spacer>
          <Button large icon color="white" @click="closeDialog">
              <v-icon >
                  clear
              </v-icon>
          </Button>
      </v-card-title>
      <v-card-text>
        <p class="blockquote">
        {{ text }}
        </p>
        <strong v-if="highlightSection !== null"> {{ highlightSection }} </strong>
      </v-card-text>
      <v-card-actions>
          <v-spacer></v-spacer>
          <Button color="info" @click="closeDialog">
              Verstanden
          </Button>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "explanation-dialog",
    data() {
        return {
            dialog: false
        }
    },
    props: {
      title: {
        type: String,
        default: 'Definition'
      },
      text: {
        type: String,
        required: true
      },
      highlightSection: {
        type: String,
        default: null
      }
    },
    watch: {
      dialog(val) {
        val || this.closeDialog();
      }
    },
    methods: {
        open() {
            this.dialog = true;
        },
        closeDialog() {
          this.dialog = false;
        },
        confirm(){
          this.closeDialog();
        }
    },
    components: {
      Button
    }
}
</script>
