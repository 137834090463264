<template>
    <page-card>
        <template slot="content">
            <div class="headline text-xs-center"><small class="grey--text text--darken-2">Hallo</small><br> {{fullname}}</div>
        </template>
        <template slot="actions">
            <v-layout row align-center justify-center>
                <Button color="success" @click="$router.push('/dashboard')">Kurzbeschreibung</Button>
                <v-divider class="page-divider mx-4 mt-2 full-width" />
                <Button color="error" @click="logout">Abmelden</Button>
            </v-layout>
        </template>
    </page-card>
</template>
<script>
import PageCard from './PageCard.vue'
import Button from '@s/views/general_components/components/Button'
export default {
    name: 'welcome',
    data() {
        return {
            fullname: this.$select('user.value.fullname as fullname')
        }
    },
    methods: {
        logout() {
        }
    },
    components: {
      PageCard,
      Button
    }
}
</script>

<style lang="stylus" scoped>
.page-divider
    width 1px
    max-width 1px
    height 48px
    max-height initial
    border-top-width 0px
    border-right-width thin
    margin 0
</style>
