import {
    noop
} from '../util/helper'
import {
    GETepicFactory,
    POSTepicFactory,
    PUTepicFactory,
    mergeEpicFactory
} from '../util/epicFactories';

const prefix = `TESTING`;
export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const CREATE_BEDARFSANMELDUNG = `${prefix}/CREATE_BEDARFSANMELDUNG`;

export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/einrichtungen?page=0&size=2147483647&sort=NAME&dir=ASC`
})

export const createBedarfsanmeldungEpic = POSTepicFactory(`${prefix}/CREATE_BEDARFSANMELDUNG`, {
    url: (action) => `/api/v1/kita/bedarfsanmeldungen`,
    body: (action) => action.payload
}, () => true, 201)


export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({}, {
    success,
    error
});

export const createBedarfsanmeldung = (bedarfsAnmeldung, headerKey, headerVal, success = noop, error = noop) => createBedarfsanmeldungEpic.run(
  {
    payload: bedarfsAnmeldung,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});


const ACTION_HANDLERS = {
    ...fetchEinrichtungenEpic.ACTION_HANDLERS,
    [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            einrichtungen: action.payload.response
        }
    },
    [createBedarfsanmeldungEpic.START]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isSending: true
        }
    },
    [createBedarfsanmeldungEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isSending: false
        }
    },
    [createBedarfsanmeldungEpic.FAIL]: (state, action) => {
        return {
            ...state,
            isFetching: false,
            isSending: false
        }
    }
}

export const actions = {
    fetchEinrichtungen,
    createBedarfsanmeldung
};

export const epics = [
    fetchEinrichtungenEpic.epic,
    createBedarfsanmeldungEpic.epic
]

const initialState = {
    einrichtungen: [],
    isFetching: false,
    isSending: false
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
