import { GETepicFactory } from '../../util/epicFactories';
import { noop } from '../../util/helper';

const prefix = `USER`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const CHANGE_LOGIN = `${prefix}/CHANGE_LOGIN`;

const infoEpic = GETepicFactory(`${prefix}/INFO`, {
    url: (action) => `/user/info`,
});
const checkSessionEpic = GETepicFactory(`${prefix}/CHECK_SESSION`, {
    url: (action) => `/user/info`,
});

export const info = (success = noop, error = noop) =>
    infoEpic.run(
        {},
        {
            success,
            error,
        }
    );
export const checkSession = (success = noop, error = noop) =>
    checkSessionEpic.run(
        {},
        {
            success,
            error,
        }
    );
export const clearStore = () => (dispatch) => {
    dispatch({
        type: CLEAR_STORE,
    });
    return Promise.resolve();
};
export const changeLogin = () => (dispatch) => {
    dispatch({
        type: CHANGE_LOGIN,
    });
    return Promise.resolve();
};

const ACTION_HANDLERS = {
    ...infoEpic.ACTION_HANDLERS,
    [infoEpic.SUCCESS]: (state, action) => {
        let permissions = state.permissions;
        let c = null;
        if (action.payload.response) {
            c = JSON.parse(action.payload.response.permissionsJson);
            if ('permissions' in c) {
                c.permissions.forEach((item) => {
                    Object.keys(item).forEach((key) => {
                        permissions[key] = item[key];
                    });
                });
            }
            return {
                isFetching: false,
                fetched: false,
                value: action.payload.response,
                permissions: permissions,
                role: c._name,
                loggedIn: action.payload.response.loggedIn,
            };
        }
    },
    ...checkSessionEpic.ACTION_HANDLERS,
    [checkSessionEpic.START]: (state, action) => {
        return {
            ...state,
        };
    },
    [checkSessionEpic.SUCCESS]: (state, action) => {
        return {
            ...state,
            loggedIn: action.payload.response.loggedIn,
        };
    },
    [CLEAR_STORE]: () => {
        return {
            ...initialState,
        };
    },
    [CHANGE_LOGIN]: (state, action) => {
        return {
            ...state,
            loggedIn: false,
        };
    },
};

export const actions = {
    info,
    checkSession,
    clearStore,
    changeLogin,
};

export const epics = [infoEpic.epic, checkSessionEpic.epic];

const initialState = {
    isFetching: false,
    fetched: false,
    value: {
        loggedIn: false,
    },
    loggedIn: false,
    role: null,
    permissions: {
        'Postkorbnachricht.send': [],
        Traeger: [],
        Saisonparameter: [],
        Mandantparameter: [],
        Einrichtungsart: [],
        Einrichtung: [],
        'Bestaetigung.save': [],
        'Einrichtung.images': [],
    },
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
