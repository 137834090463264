<template>
    <div>
        <Tooltip top v-if="internalPriority !== null && internalPriority !== 0">
            <v-icon
                class="pointer"
                small
                :color="color"
                style="font-size: 19px"
            >
                {{ icon }}
            </v-icon>
            <template #content>
                <span> {{ tooltip }}</span>
            </template>
        </Tooltip>
        <template v-else>
            <Tooltip top v-if="show_null === true">
                <v-icon class="pointer" small :color="color">
                    remove_circle
                </v-icon>
                <template #content>
                    <span> Keine Priorität vergeben</span>
                </template>
            </Tooltip>
        </template>
    </div>
</template>
<script>
import Tooltip from '@s/views/general_components/components/Tooltip';

/**
 * Component to display the internal priority of a registration.
 */
export default {
    name: 'internal-priority',
    components: {
        Tooltip,
    },
    props: {
        /**
         * The internal priority
         */
        internalPriority: {
            type: Number,
            required: false,
        },
        /**
         * Wether to display the internal priority for null value
         * (Internal prio is not set )
         */
        show_null: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        icon: function () {
            if (
                this.internalPriority !== null &&
                typeof this.internalPriority === 'number'
            ) {
                switch (this.internalPriority) {
                    case 1:
                        return 'looks_one';
                    case 2:
                        return 'looks_two';
                    case 3:
                        return 'looks_3';
                    case 4:
                        return 'looks_4';
                    case 5:
                        return 'looks_5';
                }
            } else {
                return null;
            }
        },
        color: function () {
            if (
                this.internalPriority !== null &&
                typeof this.internalPriority === 'number'
            ) {
                switch (this.internalPriority) {
                    case 1:
                        return 'success';
                    case 2:
                        return 'success';
                    case 3:
                        return 'orange';
                    case 4:
                        return 'error';
                    case 5:
                        return 'error';
                }
            } else {
                return null;
            }
        },
        tooltip: function () {
            if (
                this.internalPriority !== null &&
                typeof this.internalPriority === 'number'
            ) {
                switch (this.internalPriority) {
                    case 1:
                        return 'Sehr geringe interne Dringlichkeit (1)';
                    case 2:
                        return 'Geringe interne Dringlichkeit (2)';
                    case 3:
                        return 'Mittlere interne Dringlichkeit (3)';
                    case 4:
                        return 'Hohe interne Dringlichkeit (4)';
                    case 5:
                        return 'Sehr hohe interne Dringlichkeit (5)';
                }
            } else {
                return null;
            }
        },
    },
};
</script>
<style lan="css">
.pointer:hover {
    cursor: pointer;
}
</style>
