import {noop} from '../../util/helper'
import {
  POSTepicFactory,
  PUTepicFactory,
  DELETEepicFactory
} from '../../util/epicFactories';

const prefix = `GENERAL`;

export const SEND_EINRICHTUNGSART = `${prefix}/SEND_EINRICHTUNGSART`;
export const CHANGE_EINRICHTUNGSART = `${prefix}/CHANGE_EINRICHTUNGSART`;
export const DELETE_EINRICHTUNGSART = `${prefix}/DELETE_EINRICHTUNGSART`;

export const sendEinrichtungsartEpic = POSTepicFactory(`${prefix}/SEND_EINRICHTUNGSART`, {
    url: (action) => `/api/v1/kita/admin/einrichtungsarten`,
    body: (action) => action.payload
}, () => true, 201)

export const changeEinrichtungsartEpic = PUTepicFactory(`${prefix}/CHANGE_EINRICHTUNGSART`, {
    url: (action) => `/api/v1/kita/admin/einrichtungsarten`,
    body: (action) => action.payload
});

export const deleteEinrichtungsartEpic = DELETEepicFactory(`${prefix}/DELETE_EINRICHTUNGSART`, {
    url: (action) => `/api/v1/kita/admin/einrichtungsarten/${action.payload.id}`
})

export const sendEinrichtungsart = (einrichtungsart, headerKey, headerVal, success = noop, error = noop) => sendEinrichtungsartEpic.run(
  {
    payload: einrichtungsart,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const changeEinrichtungsart = (einrichtungsart, headerKey, headerVal, success = noop, error = noop) => changeEinrichtungsartEpic.run(
  {
    payload: einrichtungsart,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const deleteEinrichtungsart = (id, headerKey, headerVal, success = noop, error = noop) => deleteEinrichtungsartEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

const ACTION_HANDLERS = {
  ...sendEinrichtungsartEpic.ACTION_HANDLERS,
  ...changeEinrichtungsartEpic.ACTION_HANDLERS,
  ...deleteEinrichtungsartEpic.ACTION_HANDLERS,

}

export const actions = {
    sendEinrichtungsart,
    changeEinrichtungsart,
    deleteEinrichtungsart
};

export const epics = [
  sendEinrichtungsartEpic.epic,
  changeEinrichtungsartEpic.epic,
  deleteEinrichtungsartEpic.epic
]

const initialState = {
  isFetching: false
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
