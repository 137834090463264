<template>
    <v-dialog v-model="dialog" max-width="90vw">
        <v-card v-if="bedarfsAnmeldung !== null">
            <v-card-title class="headline primary" primary-title>
                <!-- obere Leiste -->
                <v-icon meidum style="color: white" class="pr-3"> info </v-icon>
                <span style="color: white">
                    Bedarfsanmeldung -
                    {{ bedarfsAnmeldung.kindesdaten[0].vorname }}
                    {{ bedarfsAnmeldung.kindesdaten[0].name }}
                    (geb.
                    {{
                        bedarfsAnmeldung.kindesdaten[0].geburtsdatum
                            | transformDate
                    }})
                </span>
                <v-spacer></v-spacer>
                <Button large icon @click="closeDialog">
                    <v-icon color="white"> clear </v-icon>
                </Button>
            </v-card-title>
            <!-- Alles -->
            <v-container fluid>
                <v-layout row wrap>
                    <v-flex xs12 md12 lg12 xl12 class="ma-4">
                        <!-- Allgemein -->
                        <div class="mb-12 mt-4">
                            <div class="mx-12">
                                <div
                                    class="mt-0 mb-2 mx-1 d-flex align-center"
                                    v-if="bedarfsAnmeldung.internalPriority"
                                >
                                    <span class="mx-2" medium icon>
                                        <v-icon color="primary"> info </v-icon>
                                    </span>

                                    <span class="d-flex"
                                        ><b class="pr-2"
                                            >Interne Dringlichkeit:
                                        </b>
                                        <InternalPriority
                                            :internalPriority="
                                                bedarfsAnmeldung.internalPriority
                                            "
                                    /></span>
                                </div>

                                <v-alert
                                    v-if="
                                        bedarfsAnmeldung.kindesdaten[0]
                                            ?.bemerkungen
                                    "
                                    :value="true"
                                    type="info"
                                    outlined
                                    style="
                                        margin-left: 15px;
                                        margin-right: 15px;
                                    "
                                >
                                    <h3>Interne Bemerkung:</h3>
                                    {{
                                        bedarfsAnmeldung.kindesdaten[0]
                                            ?.bemerkungen
                                    }}
                                </v-alert>

                                <v-alert
                                    v-if="bedarfsAnmeldung.bemerkung"
                                    :value="true"
                                    type="error"
                                    outlined
                                    style="
                                        margin-left: 15px;
                                        margin-right: 15px;
                                    "
                                >
                                    <h3>Grund der Zurückstellung:</h3>
                                    {{ bedarfsAnmeldung.bemerkung }}
                                </v-alert>

                                <v-row class="mt-4 mb-4">
                                    <v-col cols="6" class="subtitle-2">
                                        <v-card
                                            style="
                                                width: 100%;
                                                border: none;
                                                box-shadow: none;
                                            "
                                        >
                                            <v-card-text class="pt-0">
                                                <div
                                                    class="d-flex mb-2 even-row"
                                                >
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Status:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.status !==
                                                                    null &&
                                                                bedarfsAnmeldung.status !==
                                                                    ''
                                                            "
                                                        >
                                                            <span>{{
                                                                bedarfsAnmeldung.status
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div class="d-flex mb-2">
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Zugeteilte Einrichtung:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.zugeteilteEinrichtung !==
                                                                null
                                                            "
                                                        >
                                                            <span>{{
                                                                mapEinrichtung(
                                                                    bedarfsAnmeldung.zugeteilteEinrichtung
                                                                )
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex mb-2 even-row"
                                                >
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Benachrichtigungsstatus:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.benachrichtigungsstatus !==
                                                                    null &&
                                                                bedarfsAnmeldung.benachrichtigungsstatus !==
                                                                    ''
                                                            "
                                                        >
                                                            <span>{{
                                                                bedarfsAnmeldung.benachrichtigungsstatus
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div class="d-flex mb-2">
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Aufnahmedatum:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.aufnahmedatum !==
                                                                    null &&
                                                                bedarfsAnmeldung.aufnahmedatum !==
                                                                    '' &&
                                                                bedarfsAnmeldung
                                                                    .aufnahmedatum
                                                                    .von !==
                                                                    null
                                                            "
                                                        >
                                                            <span
                                                                v-if="
                                                                    bedarfsAnmeldung
                                                                        .aufnahmedatum
                                                                        .von !==
                                                                    null
                                                                "
                                                                >{{
                                                                    bedarfsAnmeldung
                                                                        .aufnahmedatum
                                                                        .von
                                                                        | transformDate
                                                                }}
                                                                <span
                                                                    v-if="
                                                                        bedarfsAnmeldung
                                                                            .aufnahmedatum
                                                                            .bis !==
                                                                        null
                                                                    "
                                                                >
                                                                    bis
                                                                    {{
                                                                        bedarfsAnmeldung
                                                                            .aufnahmedatum
                                                                            .bis
                                                                            | transformDate
                                                                    }}</span
                                                                ></span
                                                            >
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex mb-2 even-row"
                                                >
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Alternatives
                                                        Aufnahmedatum:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.alternativesAufnahmedatum !==
                                                                    null &&
                                                                bedarfsAnmeldung.alternativesAufnahmedatum !==
                                                                    ''
                                                            "
                                                        >
                                                            <span
                                                                v-if="
                                                                    bedarfsAnmeldung
                                                                        .alternativesAufnahmedatum
                                                                        .von !==
                                                                    null
                                                                "
                                                                >{{
                                                                    bedarfsAnmeldung
                                                                        .alternativesAufnahmedatum
                                                                        .von
                                                                        | transformDate
                                                                }}</span
                                                            ><span
                                                                v-if="
                                                                    bedarfsAnmeldung
                                                                        .alternativesAufnahmedatum
                                                                        .bis !==
                                                                    null
                                                                "
                                                                >bis
                                                                {{
                                                                    bedarfsAnmeldung
                                                                        .alternativesAufnahmedatum
                                                                        .bis
                                                                        | transformDate
                                                                }}</span
                                                            >
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>
                                            </v-card-text>
                                        </v-card></v-col
                                    >
                                    <v-col cols="6" class="subtitle-1">
                                        <v-card
                                            style="
                                                width: 100%;
                                                border: none;
                                                box-shadow: none;
                                            "
                                        >
                                            <v-card-text class="pt-0">
                                                <div class="d-flex mb-2">
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Eingang der Anmeldung:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.erzeugungsDatum !==
                                                                    null &&
                                                                bedarfsAnmeldung.erzeugungsDatum !==
                                                                    ''
                                                            "
                                                        >
                                                            <span>{{
                                                                bedarfsAnmeldung.erzeugungsDatum
                                                                    | transformDate
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex mb-2 even-row"
                                                >
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Letzte Änderung:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.aenderungsDatum !==
                                                                    null &&
                                                                bedarfsAnmeldung.aenderungsDatum !==
                                                                    ''
                                                            "
                                                        >
                                                            <span>{{
                                                                bedarfsAnmeldung.aenderungsDatum
                                                                    | transformDate
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>
                                                <div class="d-flex mb-2">
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Letzte Benachrichtigung:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.lastNotificationDate !==
                                                                null
                                                            "
                                                        >
                                                            <span>{{
                                                                bedarfsAnmeldung.lastNotificationDate
                                                                    | transformDate
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>

                                                <div
                                                    class="d-flex mb-2 even-row"
                                                >
                                                    <div
                                                        class="subtitle-1 gray--text"
                                                        style="flex: 1"
                                                    >
                                                        Einrichtungsart:
                                                    </div>
                                                    <div
                                                        class="subtitle-1 font-weight-bold"
                                                        style="
                                                            flex: 1;
                                                            margin-left: 10px;
                                                        "
                                                    >
                                                        <template
                                                            v-if="
                                                                bedarfsAnmeldung.einrichtungsart !==
                                                                    null &&
                                                                bedarfsAnmeldung.einrichtungsart !==
                                                                    ''
                                                            "
                                                        >
                                                            <span>{{
                                                                mapEinrichtungsart(
                                                                    bedarfsAnmeldung.einrichtungsart
                                                                )
                                                            }}</span>
                                                        </template>
                                                        <template v-else>
                                                            -
                                                        </template>
                                                    </div>
                                                </div>
                                            </v-card-text>
                                        </v-card></v-col
                                    >

                                    <v-col
                                        class="mb-4"
                                        cols="6"
                                        v-if="
                                            bedarfsAnmeldung.bemerkungAlternativesAufnahmedatum
                                        "
                                    >
                                        <v-card
                                            class="mx-4 border rounded-lg lighten-1"
                                            style="
                                                border: 1px solid lightgrey;
                                                box-shadow: none;
                                                flex: 0.475;
                                                display: flex;
                                                align-items: center;
                                                flex-direction: column;
                                            "
                                        >
                                            <v-card-text
                                                class="d-flex align-center"
                                                ><Tooltip top>
                                                    <Button
                                                        medium
                                                        icon
                                                        class="mr-4"
                                                    >
                                                        <v-icon
                                                            style="
                                                                color: lightgray;
                                                            "
                                                            >mail_outline</v-icon
                                                        >
                                                    </Button>
                                                    <template #content>
                                                        <span>
                                                            Diese Anmerkung wird
                                                            den Eltern in der
                                                            Benachrichtigung
                                                            mitgeteilt.
                                                        </span>
                                                    </template> </Tooltip
                                                ><span>
                                                    <b
                                                        >Alternatives
                                                        Aufnahmedatum
                                                        Anmerkung</b
                                                    ><br />
                                                    {{
                                                        bedarfsAnmeldung.bemerkungAlternativesAufnahmedatum
                                                    }}
                                                </span></v-card-text
                                            ></v-card
                                        >
                                    </v-col>
                                </v-row>
                            </div>
                            <v-divider
                                class="mb-10"
                                style="margin-left: 64px; margin-right: 64px"
                            ></v-divider>

                            <template>
                                <div class="mx-12 mb-10">
                                    <div
                                        class="subtitle-1 font-weight-bold grey--text lighten-2 mx-4 mb-4"
                                    >
                                        Betreuungszeiten
                                    </div>
                                    <div class="d-flex w-100 mx-2">
                                        <v-card
                                            class="border rounded-lg mx-2"
                                            v-for="key in [
                                                'Montag',
                                                'Dienstag',
                                                'Mittwoch',
                                                'Donnerstag',
                                                'Freitag',
                                            ]"
                                            :key="key"
                                            style="
                                                border: 1px solid lightgray;
                                                box-shadow: none;
                                                flex: 1;
                                                display: flex;
                                                align-items: center;
                                                flex-direction: column;
                                            "
                                        >
                                            <v-card-text class="text-center">
                                                <div
                                                    class="pb-2 pt-0 primary--text font-weight-bold"
                                                >
                                                    {{ key }}
                                                </div>

                                                <v-divider></v-divider>

                                                <div
                                                    class="pt-2"
                                                    v-if="
                                                        bedarfsAnmeldung.betreuungszeit !==
                                                            null &&
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ] !== null
                                                    "
                                                >
                                                    {{
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ].von | cutSeconds
                                                    }}
                                                    -
                                                    {{
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ].bis | cutSeconds
                                                    }}
                                                </div>
                                                <div class="pt-2" v-else>
                                                    keine Betreuung
                                                </div>

                                                <div
                                                    class="pt-2"
                                                    v-if="
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ] !== null &&
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ].mittagessen !==
                                                            null &&
                                                        bedarfsAnmeldung
                                                            .betreuungszeit[
                                                            key.toLowerCase()
                                                        ].mittagessen
                                                    "
                                                >
                                                    <Tooltip top>
                                                        <v-icon
                                                            style="
                                                                color: lightgray;
                                                            "
                                                            >restaurant_menu</v-icon
                                                        >
                                                        <template #content>
                                                            <span
                                                                >Mittagessen
                                                                gebucht</span
                                                            >
                                                        </template>
                                                    </Tooltip>
                                                </div>
                                                <div class="pt-2" v-else>
                                                    <Tooltip top>
                                                        <v-icon
                                                            style="
                                                                color: lightgray;
                                                            "
                                                            >remove</v-icon
                                                        >
                                                        <template #content>
                                                            <span
                                                                >kein
                                                                Mittagessen
                                                                gebucht</span
                                                            >
                                                        </template>
                                                    </Tooltip>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </div>
                                </div>
                            </template>
                            <v-divider
                                class="mb-10 mt-12"
                                style="margin-left: 64px; margin-right: 64px"
                            ></v-divider>

                            <template>
                                <div class="mx-12 mb-6">
                                    <div
                                        class="subtitle-1 font-weight-bold grey--text lighten-2 mx-4 mb-6"
                                    >
                                        Priorisierte Einrichtungen
                                    </div>
                                    <v-row no-gutters class="mx-4">
                                        <v-col
                                            v-for="i in prioLength"
                                            :key="`prio_${i}`"
                                            :cols="12 / prioLength"
                                        >
                                            <v-col style="height: 100%">
                                                <v-row
                                                    :class="
                                                        i % 2 === 0
                                                            ? 'odd-col'
                                                            : 'odd-col'
                                                    "
                                                >
                                                    <v-col
                                                        :class="{
                                                            'primary lighten-1 white--text font-weight-bold':
                                                                bedarfsAnmeldung
                                                                    .priorities[
                                                                    i - 1
                                                                ].status ===
                                                                'ANGENOMMEN',
                                                        }"
                                                        style="
                                                            height: 100%;
                                                            display: flex;
                                                            flex-direction: column;
                                                            justify-content: center;
                                                        "
                                                    >
                                                        <v-row
                                                            class="flex-column"
                                                        >
                                                            <v-col
                                                                class="text-center pt-4 pb-4"
                                                            >
                                                                <span
                                                                    class="font-weight-bold"
                                                                    >Priorität
                                                                    {{
                                                                        i
                                                                    }}</span
                                                                >
                                                                <br />
                                                                <span
                                                                    class="subtitle-2"
                                                                >
                                                                    {{
                                                                        bedarfsAnmeldung
                                                                            .priorities[
                                                                            i -
                                                                                1
                                                                        ]
                                                                            .status ||
                                                                        'keine Handlung'
                                                                    }}
                                                                </span>
                                                            </v-col>
                                                            <v-col
                                                                class="text-center pb-4 pt-4"
                                                                :class="{
                                                                    'primary lighten-2 white--text font-weight-bold':
                                                                        bedarfsAnmeldung
                                                                            .priorities[
                                                                            i -
                                                                                1
                                                                        ]
                                                                            .status ===
                                                                        'ANGENOMMEN',
                                                                    'even-col':
                                                                        i %
                                                                            2 ===
                                                                        0,
                                                                }"
                                                                style="align-self-start; background-color: white; padding: 10px; "
                                                            >
                                                                {{
                                                                    mapEinrichtung(
                                                                        bedarfsAnmeldung
                                                                            .priorities[
                                                                            i -
                                                                                1
                                                                        ].id
                                                                    )
                                                                }}
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                        </div>

                        <!-- Kind -->
                        <Kindesdaten
                            v-for="(
                                children, index
                            ) in bedarfsAnmeldung.kindesdaten"
                            :key="`child_${index}`"
                            :kindesdaten="children"
                            :geschwister="bedarfsAnmeldung.geschwister"
                            :calculatedAge="calculatedAge"
                            class="my-4"
                        >
                        </Kindesdaten>

                        <!-- Vertreter -->
                        <Vertreter
                            v-for="(
                                vertreter, index
                            ) in bedarfsAnmeldung.vertreter"
                            :key="`vertreter_${index}`"
                            :vertreter="vertreter"
                            :index="index"
                            class="my-4"
                        >
                        </Vertreter>

                        <!-- Sonstiges -->
                        <Sonstiges
                            :sonstigeDaten="getSonstigeDaten"
                            :hinweise="bedarfsAnmeldung.hinweise"
                            :internalPriority="
                                bedarfsAnmeldung.internalPriority
                            "
                            :nachzuegler="bedarfsAnmeldung.nachzuegler"
                            :unterjaehrig="bedarfsAnmeldung.quereinsteiger"
                            class="my-4"
                        >
                        </Sonstiges>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-card>
    </v-dialog>
</template>
<script>
import Kindesdaten from './components/Kindesdaten.vue';
import Vertreter from './components/Vertreter.vue';
import Sonstiges from './components/Sonstiges.vue';
import BetreuungszeitenTable from './components/BetreuungszeitenTable.vue';
import { calculateAge } from '../../../../../util/helper.js';
import Tooltip from '@s/views/general_components/components/Tooltip';
import Button from '@s/views/general_components/components/Button';
import InternalPriority from '../table-components/InternalPriority.vue';

/**
 *
 * Displays all details of a registration.
 */
export default {
    name: 'new-all-information-dialog',
    data() {
        return {
            einrichtungsarten: this.$select(
                'general.einrichtungsarten as einrichtungsarten'
            ),
            dialog: false,
            bedarfsAnmeldung: null,
            einrichtungen: [],
            role: null,
        };
    },

    computed: {
        prioLength() {
            return this.bedarfsAnmeldung.priorities.length;
        },
        calculatedAge() {
            if (
                this.bedarfsAnmeldung.kindesdaten[0].geburtsdatum !== null &&
                this.bedarfsAnmeldung.aufnahmedatum !== null &&
                this.bedarfsAnmeldung.aufnahmedatum.von !== null
            ) {
                console.log('calc age:');
                return this.calculateAge(
                    this.bedarfsAnmeldung.kindesdaten[0].geburtsdatum,
                    this.bedarfsAnmeldung.aufnahmedatum.von
                );
            }
            return null;
        },
        getSonstigeDaten() {
            return {
                datenschutzZustimmung:
                    this.bedarfsAnmeldung.datenschutzZustimmung,
                datenverwendungZustimmung:
                    this.bedarfsAnmeldung.datenverwendungZustimmung,
                gelesenUndGeprueft: this.bedarfsAnmeldung.gelesenUndGeprueft,
                zustimmungZustimmung:
                    this.bedarfsAnmeldung.zustimmungZustimmung,
                bankverbindungKontoinhaber:
                    this.bedarfsAnmeldung.bankverbindungKontoinhaber,
                bankverbindungBIC: this.bedarfsAnmeldung.bankverbindungBIC,
                bankverbindungIBAN: this.bedarfsAnmeldung.bankverbindungIBAN,
            };
        },
    },
    methods: {
        open(bedarfsAnmeldung, einrichtungen, role = 'Einrichtung') {
            this.bedarfsAnmeldung = bedarfsAnmeldung;
            this.einrichtungen = einrichtungen;
            this.role = role;
            this.dialog = true;
        },

        closeDialog() {
            this.dialog = false;

            //Dialog closing animation looks weird if cleanup gets done immediately
            setTimeout(() => {
                this.bedarfsAnmeldung = null;
                this.einrichtungen = null;
            }, 200);
        },
        mapEinrichtungsart(ids) {
            const empty = ' - ';
            if (!ids) {
                return empty;
            }
            return ids
                .split(',')
                .map((id) => {
                    const index = this.einrichtungsarten.findIndex(
                        (x) => x.id === id
                    );
                    return index >= 0
                        ? this.einrichtungsarten[index].name
                        : empty;
                })
                .join(', ');
        },
        mapEinrichtung(id, status = 'ANDERE KBE') {
            let index = this.einrichtungen.findIndex((x) => x.id === id);
            if (index >= 0) {
                return this.einrichtungen[index].name;
            }
            if (status === null || status === 'OFFEN') {
                return '-';
            }
            return 'Andere KBE';
        },
        calculateAge,
    },
    components: {
        Kindesdaten,
        Sonstiges,
        BetreuungszeitenTable,
        Vertreter,
        Tooltip,
        Button,
        InternalPriority,
    },
};
</script>

<style lang="stylus" scoped>
i
  font-weight: bold

.odd-col {
  background-color: #f0f0f0;
}

.even-col {
  background-color: white;
}

.even-row
  background-color: #f0f0f0
</style>
