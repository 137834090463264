<template>
    <v-card class="elevation-1">
        <banner></banner>
        <div v-if="loading" class="loader-wrap">
            <v-progress-linear class="loader" height="4" indeterminate></v-progress-linear>
        </div>
        <v-card-text>
            <slot name="content"></slot>
        </v-card-text>
      <v-card-actions class="justify-center actions">
        <slot name="actions"></slot>
      </v-card-actions>
    </v-card>
</template>
<script>
import Banner from './Banner.vue'
export default {
  name: "page-card",
  props: ['loading'],
  components: {
    Banner
  }
}
</script>

<style lang="stylus" scoped>
.loader-wrap
    position: absolute;
    width: 100%
.loader
    position: relative;
    bottom 18px
.actions
    border-top 1px solid #ccc
</style>
