import {noop} from '../../util/helper'
import {
  GETepicFactory,
  POSTepicFactory,
  PUTepicFactory,
  DELETEepicFactory,
  mergeEpicFactory
} from '../../util/epicFactories';

const prefix = `TRAEGER`;

export const FETCH_TRAEGER = `${prefix}/FETCH_TRAEGER`;
export const SEND_TRAEGER = `${prefix}/SEND_TRAEGER`;
export const CHANGE_TRAEGER = `${prefix}/CHANGE_TRAEGER`;
export const DELETE_TRAEGER = `${prefix}/DELETE_TRAEGER`;

export const fetchTraegerEpic = GETepicFactory(`${prefix}/FETCH_TRAEGER`, {
    url: (action) => `/api/v1/kita/traeger?page=0&size=2147483647&sort=NAME&dir=ASC`
})

export const sendTraegerEpic = POSTepicFactory(`${prefix}/SEND_TRAEGER`, {
    url: (action) => `/api/v1/kita/admin/traeger`,
    body: (action) => action.payload
}, () => true, 201)

export const changeTraegerEpic = PUTepicFactory(`${prefix}/CHANGE_TRAEGER`, {
    url: (action) => `/api/v1/kita/admin/traeger`,
    body: (action) => action.payload
});

export const deleteTraegerEpic = DELETEepicFactory(`${prefix}/DELETE_TRAEGER`, {
    url: (action) => `/api/v1/kita/admin/traeger/${action.payload.id}`
})

export const fetchTraeger = (success = noop, error = noop) => fetchTraegerEpic.run({}, {success, error});

export const sendTraeger = (traeger, headerKey, headerVal, success = noop, error = noop) => sendTraegerEpic.run(
  {
    payload: traeger,
    headerKey: headerKey,
    headerVal: headerVal
  },{success, error});

export const changeTraeger = (traeger, headerKey, headerVal, success = noop, error = noop) => changeTraegerEpic.run( {
    payload: traeger,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const deleteTraeger = (id, headerKey, headerVal, success = noop, error = noop) => deleteTraegerEpic.run({
  id: id,
  headerKey: headerKey,
  headerVal: headerVal
},{success, error});


const sendReloadEpic = mergeEpicFactory(sendTraegerEpic.SUCCESS, () => fetchTraegerEpic.run())
const changeReloadEpic = mergeEpicFactory(changeTraegerEpic.SUCCESS, () => fetchTraegerEpic.run())
const deleteReloadEpic = mergeEpicFactory(deleteTraegerEpic.SUCCESS, () => fetchTraegerEpic.run())

const ACTION_HANDLERS = {
  ...sendTraegerEpic.ACTION_HANDLERS,
  ...changeTraegerEpic.ACTION_HANDLERS,
  ...deleteTraegerEpic.ACTION_HANDLERS,
  ...fetchTraegerEpic.ACTION_HANDLERS,
  [fetchTraegerEpic.SUCCESS]: (state, action) => {
      return {
          ...state,
          traeger: action.payload.response,
          isFetching: false
      }
  },
  [fetchTraegerEpic.FAIL]: (state, action) => {
      return {
          ...state,
          isFetching: false
      }
  },

}

export const actions = {
    sendTraeger,
    changeTraeger,
    deleteTraeger,
    fetchTraeger
};

export const epics = [
  sendTraegerEpic.epic,
  changeTraegerEpic.epic,
  deleteTraegerEpic.epic,
  fetchTraegerEpic.epic,
  sendReloadEpic.epic,
  changeReloadEpic.epic,
  deleteReloadEpic.epic
]

const initialState = {
  isFetching: false,
  traeger: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
