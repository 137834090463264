var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"primary"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v("keyboard_arrow_down")])]},proxy:true}])},[_c('span',{staticClass:"title white--text"},[_vm._v("Sonstiges ")])]),_vm._v(" "),_c('v-expansion-panel-content',{staticClass:"mt-6"},[_c('div',{staticClass:"mb-10 mx-10"},[(_vm.hinweise !== null && _vm.hinweise.length > 0)?_c('div',{staticClass:"mt-0"},[_vm._l((_vm.hinweise),function(hinweis,index){return _c('div',{key:index,staticClass:"mb-2"},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("info")])],1),_vm._v(" "),_c('b',[_vm._v("Hinweis vorhanden: ")]),_vm._v(_vm._s(hinweis)),_c('br')])}),_vm._v(" "),(_vm.nachzuegler)?_c('div',{staticClass:"mt-0 mb-2"},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" info ")])],1),_vm._v(" "),_c('span',[_c('b',[_vm._v("Nachzügler")])])]):_vm._e(),_vm._v(" "),(_vm.unterjaehrig)?_c('div',{staticClass:"mt-0 mb-2"},[_c('span',{attrs:{"medium":"","icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" info ")])],1),_vm._v(" "),_c('span',[_c('b',[_vm._v("unterjährig")])])]):_vm._e()],2):_vm._e()]),_vm._v(" "),_c('v-row',{staticClass:"mb-6"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"ml-6",staticStyle:{"width":"100%","border":"none","box-shadow":"none"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1"}},[_vm._v("\n                                    Bankkonto\n                                ")])]),_vm._v(" "),[_c('div',[_vm._l(([
                                            'bankverbindungKontoinhaber',
                                            'bankverbindungIBAN',
                                            'bankverbindungBIC',
                                        ]),function(key,index){return [_c('div',{key:key,class:{
                                                'd-flex': true,
                                                'mb-2': true,
                                                'even-row': index % 2 === 0,
                                            }},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                "+_vm._s(_vm.formatKey(key))+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                        _vm.sonstigeDaten[
                                                            key
                                                        ] !== null &&
                                                        _vm.sonstigeDaten[
                                                            key
                                                        ] !== ''
                                                    )?[_c('span',[_vm._v(_vm._s(_vm.sonstigeDaten[key]))])]:[_vm._v("-")]],2)])]})],2)]],2)],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pr-6",attrs:{"cols":"6"}},[_c('v-card',{staticStyle:{"width":"100%","border":"none","box-shadow":"none"}},[_c('v-card-text',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex mb-2"},[_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1"}},[_vm._v("\n                                    Zugestimmt\n                                ")])]),_vm._v(" "),[_c('div',[_vm._l(([
                                            'datenschutzZustimmung',
                                            'datenverwendungZustimmung',
                                            'zustimmungZustimmung',
                                            'gelesenUndGeprueft',
                                        ]),function(key,index){return [_c('div',{key:key,class:{
                                                'd-flex': true,
                                                'mb-2': true,
                                                'even-row': index % 2 === 0,
                                            }},[_c('div',{staticClass:"subtitle-1 gray--text",staticStyle:{"flex":"1"}},[_vm._v("\n                                                "+_vm._s(_vm.formatKey(key))+"\n                                            ")]),_vm._v(" "),_c('div',{staticClass:"subtitle-1 font-weight-bold",staticStyle:{"flex":"1","margin-left":"10px"}},[(
                                                        _vm.sonstigeDaten[
                                                            key
                                                        ] !== null
                                                    )?_c('div',[(
                                                            _vm.sonstigeDaten[
                                                                key
                                                            ] === true
                                                        )?_c('span',[_vm._v("ja")]):_c('span',[_vm._v("nein")])]):_c('div',[_vm._v("nein")])])])]})],2)]],2)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }