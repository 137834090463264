<template>
<v-dialog v-model="dialog" width="700">
    <v-card>
      <v-card-title class="headline info" primary-title>
          <v-icon large style="color: white" class="pr-3">
            info
          </v-icon>
          <span style="color: white">
            Ungespeicherte Änderungen
        </span>
          <v-spacer></v-spacer>
          <Button large icon color="white" @click="closeDialog">
              <v-icon color="info">
                  clear
              </v-icon>
          </Button>
      </v-card-title>
      <v-card-text class="pa-4">
          <v-alert
            :value="true"
            type="info"
            icon="warning"
            outlined
          >
            Es liegen ungespeicherte Änderungen vor. Beim Verlassen der Seite gehen diese verloren.
            Um die Änderungen zu speichern, bleiben Sie auf der Seite und klicken Sie auf Änderungen speichern. 
          </v-alert>
      </v-card-text>
      <v-card-actions>
          <Button raised color="orange darken-2" @click="leave">
            <v-icon class="pr-2" color="white">
                directions
            </v-icon>
            <span class="white--text">Seite trotzdem verlasssen</span>
          </Button>
          <v-spacer></v-spacer>
          <Button raised color="info" @click="stay">
            <v-icon class="pr-2">
                stop
            </v-icon>
              Auf der Seite bleiben
          </Button>
      </v-card-actions>
    </v-card>
</v-dialog>
</template>
<script>
import Button from '@s/views/general_components/components/Button'

export default {
    name: "page-leave-dialog",
    data() {
        return {
            dialog: false
        }
    },
    props: {
      nextFunction: {
        type: Function,
        required: true
      }
    },
    watch: {
      dialog(val) {
        val || this.closeDialog();
      }
    },
    methods: {
        open() {
          this.dialog = true;
        },
        closeDialog() {
          this.dialog = false;
        },
        leave(){
          this.$emit('leave', this.nextFunction);
        },
        stay(){
          this.$emit('stay');
          this.closeDialog();
        }
    },
    components: {
      Button
    }
}
</script>
