<template>
<div>
  <einrichtung-information-dialog ref="einrichtungInformationDialog">
  </einrichtung-information-dialog>
  <v-data-table
    :headers="headers"
    :items="einrichtungen"
    :loading="loading"
    class="elevation-1"
    :expanded.sync="expanded"
    :search="search"
    :footer-props="{itemsPerPageText: rowsPerPageText}"
    >
      <template v-slot:top>
        <v-toolbar flat>

          <v-text-field
            v-model="search"
            append-icon="search"
            label="Nach Einrichtung suchen"
            placeholder="Nach Einrichtung suchen"
            single-line
            clearable
            clear-icon="close"
          ></v-text-field>

           <v-spacer></v-spacer>

          <v-switch
            v-model="showAuslastung"
            label="Kapazität pro Einrichtungsart anzeigen"
            class="mt-2"
          ></v-switch>

        </v-toolbar>
      </template>
      <template v-slot:item={item}>
        <tr :style="`background: linear-gradient(110deg, ${item.freiePlaetze < 0 ? 'rgb(142, 0, 0, 0.5)' : 'rgb(0, 107, 3, 0.5) '} ${((item.gesamtPlaetze - item.freiePlaetze) / item.gesamtPlaetze) * 100}%, rgb(0, 107, 3, 0.2) ${((item.gesamtPlaetze - item.freiePlaetze) / item.gesamtPlaetze) * 100}%)`">
          <td class="text-xs-left"> {{  item.name }} </td>
          <td class="text-xs-left"> {{ item.gesamtPlaetze }} </td>
          <td class="text-xs-left"> {{ item.gesamtPlaetze - item.freiePlaetze }} </td>
          <td class="text-xs-left">
            <span v-bind:class="( item.freiePlaetze < 0) ? 'font-weight-bold' : ''">
              {{ item.freiePlaetze }}
            </span>
          </td>
          <td class="text-right">
            <Tooltip top>
                <Button
                  small
                  icon
                  @click="openEinrichtungInformationDialog(item)">
                  <v-icon color="primary">
                      info
                  </v-icon>
                </Button>
              <template #content>
                <span>Vollständige Information</span>
              </template>
            </Tooltip>
          </td> 
        </tr>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-card class="pb-5">
          <v-card-title primary-title>
            <div style="width: 100%" align="center">
                <v-divider></v-divider>
                <span class="headline primary--text darken-2">Kapazität pro Einrichtungsart</span>
                <v-divider class="pb-2"></v-divider>
            </div>
          </v-card-title>
          <div v-for="(it, index) in item.plaetzeProEinrichtungsart" class="mx-5" :key="index">
            <div v-if="it.einrichtungsart !== null">
              <h3 class="primary--text text-xs-left">{{ einrichtungsarten_dic[it.einrichtungsart].name }}</h3>
              <div align="left">
                <span class="grey--text caption ">Plätze frei: {{ it.plaetze.freiePlaetze }}</span>
                <span class="grey--text caption pl-4">Plätze gesamt: {{ it.plaetze.gesamtPlaetze }}</span>
              </div>
              <v-progress-linear
                class="mt-0"
                :color="it.plaetze.freiePlaetze < 0 ? 'red' : 'green'"
                :value="((it.plaetze.gesamtPlaetze - it.plaetze.freiePlaetze) / it.plaetze.gesamtPlaetze) * 100"
              />
            </div>
          </div>
        </v-card>
      </td>
    </template>
  </v-data-table>
</div>
</template>
<script>
import EinrichtungInformationDialog from './dialogs/EinrichtungInformationDialog.vue'
import Tooltip from '@s/views/general_components/components/Tooltip'
import Button from '@s/views/general_components/components/Button'

export default {
    name: "capacity-vue",
    data() {
        return {
          einrichtungsarten_dic: this.$select('general.einrichtungsarten_dic as einrichtungsarten_dic'),
          headers: [
            {
                text: 'Einrichtung',
                align: 'left',
                sortable: true,
                value: 'name'
            },
            {
                text: 'Plätze gesamt',
                align: 'left',
                sortable: true,
                value: 'gesamtPlaetze'
            },
            {
                text: 'Plätze belegt',
                align: 'left',
                sortable: false,
                value: ``
            },
            {
                text: 'Plätze frei',
                align: 'left',
                sortable: true,
                value: 'freiePlaetze'
            },
            {
                text: '',
                align: 'right',
                sortable: false,
                value: ''
            }
          ],
          einrichtungInformationDialog: null,
          rowsPerPageItems: [5, 10, 20, { "text": "Alle", "value": -1 }],
          rowsPerPageText: 'Anzahl der Reihen pro Seite',
          expanded: [],
          showAuslastung: false,
          search: null
        }
    },
    props: {
      einrichtungen: {
        type: Array
      },
      loading: {
        type: Boolean
      }
    },
    mounted(){
      this.$dispatch(this.$actions().traeger.fetchTraeger(() => {

      },
      (error) => {
        if(error.status === 401){
          this.$dispatch(this.$actions().user.changeLogin())
        }
      }));
    },
    methods: {
      openEinrichtungInformationDialog(einrichtung){
        this.$nextTick(() => {
            this.$refs.einrichtungInformationDialog.open(einrichtung);
        });
      },
    },
    components: {
      EinrichtungInformationDialog,
      Tooltip,
      Button
    },
    watch: {
      showAuslastung(new_val){
        if(new_val){
          this.expanded = this.einrichtungen.filter(x => x.plaetzeProEinrichtungsart !== null && x.plaetzeProEinrichtungsart.length > 0);
        } else {
          this.expanded = [];
        }
      }
    }
}
</script>
