import {applyMiddleware, compose, createStore} from 'redux'
import thunk from '../util/thunk'
import { createEpicMiddleware } from 'redux-observable'
import makeRootReducer, {makeRootEpic, injectReducer} from './reducers'

export default (initialState = {}) => {
    // ======================================================
    // Middleware Configuration
    // ======================================================
    const epicMiddleware = createEpicMiddleware()
    const middleware = [
        thunk,
        epicMiddleware,
        require('redux-logger').default,
    ]

    // ======================================================
    // Store Enhancers
    // ======================================================
    const enhancers = []
    const devToolsExtension = window.devToolsExtension
    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }

    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    const store = createStore(
        makeRootReducer(),
        initialState,
        compose(
            applyMiddleware(...middleware),
            ...enhancers
        )
    )
    store.asyncReducers = {}
    store.injectReducer = injectReducer;

    epicMiddleware.run(makeRootEpic([]))

    if (module.hot) {
        module.hot.accept('./reducers', () => {
            const makeNextRootReducer = require('./reducers').default
            store.replaceReducer(makeNextRootReducer(store.asyncReducers))
        })
    }


    return store
}
