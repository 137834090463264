import {
  noop,
  compareLexicographical
        } from '../util/helper';
import {GETepicFactory, mergeEpicFactory} from '../util/epicFactories';
import {
  sendEinrichtungsartEpic,
  changeEinrichtungsartEpic,
  deleteEinrichtungsartEpic
} from './admin/einrichtungsarten'

const prefix = `GENERAL`;

export const FETCH_EINRICHTUNGSARTEN = `${prefix}/FETCH_EINRICHTUNGSARTEN`;

export const fetchEinrichtungsartenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGSARTEN`, {
    url: (action) => `/api/v1/kita/einrichtungsarten`
})

const sendReloadEpic = mergeEpicFactory(sendEinrichtungsartEpic.SUCCESS, () => fetchEinrichtungsartenEpic.run())
const changeReloadEpic = mergeEpicFactory(changeEinrichtungsartEpic.SUCCESS, () => fetchEinrichtungsartenEpic.run())
const deleteReloadEpic = mergeEpicFactory(deleteEinrichtungsartEpic.SUCCESS, () => fetchEinrichtungsartenEpic.run())

export const fetchEinrichtungsarten = (success = noop, error = noop) => fetchEinrichtungsartenEpic.run({},{success, error});

const ACTION_HANDLERS = {
  ...fetchEinrichtungsartenEpic.ACTION_HANDLERS,
  [fetchEinrichtungsartenEpic.SUCCESS]: (state, action) => {
    action.payload.response.sort(compareLexicographical)

    let einrichtungsarten_dic = {};
    action.payload.response.forEach(einrichtungsart => {
      einrichtungsarten_dic[einrichtungsart.id] = {
        id: einrichtungsart.id,
        name: einrichtungsart.name,
        minimalAlter: einrichtungsart.minimalAlter,
        maximalAlter: einrichtungsart.maximalAlter,
        beschreibung: einrichtungsart.beschreibung
      }
    })

    return {
      ...state,
      einrichtungsarten: action.payload.response,
      einrichtungsarten_dic: einrichtungsarten_dic,
      isFetching: false
    }
  },
  [fetchEinrichtungsartenEpic.FAIL]: (state, action) => {
      return {
        ...state,
        hasError: true,
        isFetching: false
      }
  },
}

export const actions = {
    fetchEinrichtungsarten
};

export const epics = [
  fetchEinrichtungsartenEpic.epic,
  sendReloadEpic.epic,
  changeReloadEpic.epic,
  deleteReloadEpic.epic
]

const initialState = {
    einrichtungsarten: [],
    traegerarten: ["KOMM", "KATH", "EVANG", "PARIT", "SOTR", "AWO","BRK"],
    einrichtungsarten_dic: {},
    maxFetchingSize: 2147483647
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
