export default {
    name: null,
    adresse: {
      strasse: null,
      hausnummer: null,
      postleitzahl: null,
      ort: null
    },
    location: {
      type: 'Point',
      coordinates: [
        null, null
      ]
    },
    einrichtungsarten: null,
    ansprechpartner: [],
    mittagsverpflegung: false,
    traeger: null,
    gesamtPlaetze: null,
    freiePlaetze: null,
    gruppen: null,
    informationen: null,
    ags: null,
    aufnahmedatum: {
      von: null,
      bis: null
    },
    betreuungszeit: {
      montag: {
        von: null,
        bis: null,
        mittagessen: null
      },
      dienstag: {
        von: null,
        bis: null,
        mittagessen: null
      },
      mittwoch: {
        von: null,
        bis: null,
        mittagessen: null
      },
      donnerstag: {
        von: null,
        bis: null,
        mittagessen: null
      },
      freitag: {
        von: null,
        bis: null,
        mittagessen: null
      },
      samstag: {
        von: null,
        bis: null,
        mittagessen: null
      },
      sonntag: {
        von: null,
        bis: null,
        mittagessen: null
      }
    },
    bildUrl: null,
    url: null,
    einrichtungsnummer: null,
    teilnahmeAnPortalloesung: null,
    externeId: null,
    betreuungsangebot: null,
    minimumTage: null,
    minimumWochenstunden: null,
    bilder: null
}
