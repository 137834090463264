import Vue from 'vue';

Vue.filter('transformDate', date => {
  if(date != null && typeof date === 'string'){
    let iso = /^([\+-]?\d{4}(?!\d{2}\b))((-?)((0[1-9]|1[0-2])(\3([12]\d|0[1-9]|3[01]))?|W([0-4]\d|5[0-2])(-?[1-7])?|(00[1-9]|0[1-9]\d|[12]\d{2}|3([0-5]\d|6[1-6])))([T\s]((([01]\d|2[0-3])((:?)[0-5]\d)?|24\:?00)([\.,]\d+(?!:))?)?(\17[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3]):?([0-5]\d)?)?)?)?$/;
    let match = date.match(iso);
    if(match == null){
      return date
    }
    let newDate = new Date(date);
    if (newDate instanceof Date && !isNaN(newDate.valueOf()) && date !== true && date !== false) {
      return `${newDate.getDate()}.${newDate.getMonth() + 1}.${newDate.getFullYear()}`
    };
  }
  return date;
});

Vue.filter('firstLetterUpperCase', word => {
  if(word != null && typeof word === 'string'){
    return word.charAt(0).toUpperCase() + word.substr(1);
  }
  return word;
});

Vue.filter('cutISOString', dateString => {
  if(dateString != null && typeof dateString === 'string'){
    return dateString.substring(0,10);
  }
  return dateString;
});

Vue.filter('cutSeconds', timeString => {
  if(timeString != null && typeof timeString === 'string'){
    return timeString.substring(0,5);
  }
  return timeString;
});

Vue.filter('calculateAge', timeString => {
  if(timeString != null && typeof timeString === 'string'){
    var today = new Date();
    var birthDate = new Date(timeString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if( m <= 0){
      m = m + 12;
    }
    return `${age} Jahr, ${m} Monate`;
  }
  return timeString;
});

Vue.filter('incrementOne', value => {
  return ++value;
});

Vue.filter('cutString', value => {
  if(typeof value === 'string' && value.length > 75){
    let returnString = `${value.slice(0,75)} [...]`;
    return returnString;
  } else {
    return value
  }
});

Vue.filter('cutString20', value => {
  if(typeof value === 'string' && value.length > 21){
    let returnString = `${value.slice(0,20)} [...]`;
    return returnString;
  } else {
    return value
  }
});



Vue.filter('UTCtoCETandCEST', date => {
  let parseISOString = (s) => {
    let b = s.split(/\D+/);
    if(b.length === 6) {
      b.push('00');
    }
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }
  let newDate = parseISOString(date);
  let timeDifference = newDate.getTimezoneOffset();
  let seconds = newDate.getTime() / 1000;
  seconds += -1 * (timeDifference * 60);
  let value = new Date(seconds * 1000).toISOString();
  let resultDate = value.slice(0, 10);
  let time = value.slice(11,19);
  return `${resultDate}, ${time} Uhr`
})

Vue.filter('displayTimer', seconds => {
  let min = Math.floor(seconds / 60);
  let sec = seconds % 60;
  let prefix = '';
  if(sec <= 9 >= 1) {
    prefix = '0';
  }
  return `${min}:${prefix}${sec}`
})
