import {
        noop,
        calculateAge,
        findNameFromID,
        transformDate,
        calculateAddedHours,
        dateToSeconds,
        compareLexicographical
} from '../../util/helper'

import {
  GETepicFactory,
  PUTepicFactory,
  mergeEpicFactory,
  POSTepicFactory
} from '../../util/epicFactories';

const prefix = `NOTIFICATION`;

export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const FETCH_ASSIGNED = `${prefix}/FETCH_ASSIGNED`;
export const FETCH_PDF = `${prefix}/FETCH_PDF`;
export const CLEAR_STORE = `${prefix}/CLEAR_STORE`;
export const SEND_BEDARFSANMELDUNG = `${prefix}/SEND_BEDARFSANMELDUNG`;
export const SEND_NOTIFICATIONS = `${prefix}/SEND_NOTIFICATIONS`;
export const RECLINE_REGISTRATION = `${prefix}/RECLINE_REGISTRATION`;
export const RECLINE_UNDO = `${prefix}/RECLINE_UNDO`
export const FILTER = `${prefix}/FILTER`;
export const SELECT_ALL = `${prefix}/SELECT_ALL`;
export const SELECT_ONE = `${prefix}/SELECT_ONE`;
export const MESSAGE_MANUALLY = `${prefix}/MESSAGE_MANUALLY`;
export const MESSAGE_MANUALLY_UNDO = `${prefix}/MESSAGE_MANUALLY_UNDO`;
export const RESET_PDF = `${prefix}/RESET_PDF`;
export const ADD_DECISION = `${prefix}/ADD_DECISION`;
export const SEARCH = `${prefix}/SEARCH`
export const RESET = `${prefix}/RESET`;
export const SELECT_TO_MESSAGE = `${prefix}/SELECT_TO_MESSAGE`;
export const SELECT_ALL_MESSAGES = `${prefix}/SELECT_ALL_MESSAGES`;
export const SAVE_DATE_ANNOTATION = `${prefix}/SAVE_DATE_ANNOTATION`;
export const DELETE_DATE_ANNOTATION = `${prefix}/DELETE_DATE_ANNOTATION`;
export const SELECT_MULTIPLE_BY_INDEX = `${prefix}/SELECT_MULTIPLE_BY_INDEX`;

export const fetchAssignedEpic = GETepicFactory(`${prefix}/FETCH_ASSIGNED`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen?page=0&size=2147483647&sort=ERZEUGUNGSDATUM&dir=DESC`
})
export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/names`
})
export const fetchPDFEpic = POSTepicFactory(`${prefix}/FETCH_PDF`, {
    url: (action) => `/api/v1/kita-workflow/bedarfsanmeldungen/rueckmeldungenForList`,
    body: (action) => action.payload
},  () => true, 201, () => true)
export const sendBedarfsanmeldungEpic = PUTepicFactory(`${prefix}/SEND_BEDARFSANMELDUNG`, {
    url: (action) => `/api/v1/kita/admin/bedarfsanmeldungen/updateAll`,
    body: (action) => action.payload
})
export const sendNotificationsEpic = POSTepicFactory(`${prefix}/SEND_NOTIFICATIONS`, {
    url: (action) => `/api/v1/kita-workflow/bedarfsanmeldungen/rueckmeldungenForList`,
    body: (action) => action.payload
}, () => true, 201, () => true)

export const fetchAssigned = (success = noop, error = noop) => fetchAssignedEpic.run({},{success, error});
export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});
export const fetchPDF = (payload, headerKey, headerVal, success = noop, error = noop) => fetchPDFEpic.run(
  {
    payload: payload,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const sendBedarfsanmeldungen = (bedarfsAnmeldungen, headerKey, headerVal, success = noop, error = noop) => sendBedarfsanmeldungEpic.run(
  {
    payload: bedarfsAnmeldungen,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const sendNotifications = (payload, headerKey, headerVal, success = noop, error = noop) => sendNotificationsEpic.run(
  {
    payload: payload,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const clearStore = () => ({
    type: CLEAR_STORE
});
export const filter = (status, einrichtung, assignmentStatus = null) => dispatch => {
  dispatch({
      type: FILTER,
      payload: {
        status: status,
        einrichtung: einrichtung,
        assignmentStatus: assignmentStatus
      }
  });
  return Promise.resolve();
};
export const reclineRegistration = (id, refuseReason) => dispatch => {
  dispatch({
      type: RECLINE_REGISTRATION,
      payload: {
        id: id,
        refuseReason: refuseReason
      }
  });
  return Promise.resolve();
};
export const reclineUndo = (id, oldState) => dispatch => {
  dispatch({
      type: RECLINE_UNDO,
      payload: {
        id: id,
        oldState: oldState
      }
  });
  return Promise.resolve();
};
export const selectAll = (selectAll) => dispatch => {
  dispatch({
      type: SELECT_ALL,
      payload: {
        selectAll: selectAll
      }
  });
  return Promise.resolve();
};
export const selectAllMessages = (selectAll) => dispatch => {
  dispatch({
      type: SELECT_ALL_MESSAGES,
      payload: {
        selectAll: selectAll
      }
  });
  return Promise.resolve();
};
export const selectOne = (id) => dispatch => {
  dispatch({
      type: SELECT_ONE,
      payload: {
        id: id
      }
  });
  return Promise.resolve();
};
export const selectToMessage = (id) => dispatch => {
  dispatch({
      type: SELECT_TO_MESSAGE,
      payload: {
        id: id
      }
  });
  return Promise.resolve();
};
export const messageManually = (id) => dispatch => {
  dispatch({
      type: MESSAGE_MANUALLY,
      payload: {
        id: id
      }
  });
  return Promise.resolve();
};
export const messageManuallyUndo = (id, oldState) => dispatch => {
  dispatch({
      type: MESSAGE_MANUALLY_UNDO,
      payload: {
        id: id,
        oldState: oldState
      }
  });
  return Promise.resolve();
};
export const resetPDF = () => dispatch => {
  dispatch({
      type: RESET_PDF,
      payload: {}
  });
  return Promise.resolve();
};
export const addDecision = (id, reason) => dispatch => {
  dispatch({
      type: ADD_DECISION,
      payload: {
        id: id,
        reason: reason
      }
  });
  return Promise.resolve();
};
export const startSearch = (input) => ({
    type: SEARCH,
    payload: {
        input: input
    }
});
export const resetTable = () => dispatch => {
    dispatch({
        type: RESET
    });
    return Promise.resolve();
};
export const saveDateAnnotation = (id, annotation, suggestionDate) => dispatch => {
    dispatch({
        type: SAVE_DATE_ANNOTATION,
        payload: {
          id: id,
          annotation: annotation,
          suggestionDate: suggestionDate
        }
    });
    return Promise.resolve();
};
export const deleteDateAnnotation = (id) => dispatch => {
    dispatch({
        type: DELETE_DATE_ANNOTATION,
        payload: {
          id: id
        }
    });
    return Promise.resolve();
};
export const selectMultipleByIndex = (indices) => ({
    type: SELECT_MULTIPLE_BY_INDEX,
    payload: {
        indices: indices
    }
});

//const sendReloadEpic = mergeEpicFactory(sendBedarfsanmeldungEpic.SUCCESS, () => fetchAssignedEpic.run());
const sendNotificationsReloadEpic = mergeEpicFactory(sendNotificationsEpic.SUCCESS, () => fetchAssignedEpic.run());

const ACTION_HANDLERS = {

  ...fetchAssignedEpic.ACTION_HANDLERS,
  [fetchAssignedEpic.START]: (state, action) => {
    return {
      ...initialState,
      einrichtungen: state.einrichtungen,
      pdf: state.pdf,
      isFetching: true,
      fetched: false
    }
  },
  [fetchAssignedEpic.SUCCESS]: (state, action) => {
      let registrations = action.payload.response;
      let table = [];
      let names = []

      const allRejected = (currentValue) => currentValue.status === 'ABGELEHNT';

      for(let i = 0; i < registrations.length; ++i){

        let aufnahmedatumVal = null;
        let aufnahmedatumSeconds = null;
        if (registrations[i].aufnahmedatum !== null && registrations[i].aufnahmedatum.von) {
            aufnahmedatumVal = transformDate(registrations[i].aufnahmedatum.von);
            aufnahmedatumSeconds = dateToSeconds(registrations[i].aufnahmedatum.von);
        }

        //OFFEN, ZUBENACHRICHTIGEN, BENACHRICHTIGT, BESTAETIGT
        let status = registrations[i].status;
        if(registrations[i].status === null){
          status = "OFFEN";
        }
        let sendStatus = registrations[i].benachrichtigungsstatus;
        if(registrations[i].benachrichtigungsstatus === null){
          sendStatus = "OFFEN";
        }
        let assignedFacility = '-';
        if(findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen) !== null){
          assignedFacility = findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen);
        }
        let tableRow = {
          id: registrations[i].id,
          name: [],
          zugeteilteEinrichtung: {
            value: findNameFromID(registrations[i].zugeteilteEinrichtung, state.einrichtungen),
            id: registrations[i].zugeteilteEinrichtung,
            tooltip: `Zugeteilt an: ${assignedFacility}`
          },
          status: {
            value: status,
            tooltip: `Status: ${status}`
          },
          sendStatus: {
            value: sendStatus,
            tooltip: `Benachrichtigungsstatus: ${sendStatus}`
          },
          decision: {
            value: registrations[i].entscheidung,
            tooltip: 'Begründung nach Außen'
          },
          aufnahmedatum: [{
              value: aufnahmedatumVal,
              tooltip: calculateAddedHours(registrations[i].betreuungszeit, registrations[i].kindesdaten.length),
              highlight: false
          }],
          bemerkung: registrations[i].bemerkung,
          downloadSelected: false,
          messageSelected: false,
          manualNotificationUndo: {
            possible: false,
            oldState: {}
          },
          reclineUndo: {
            possible: false,
            oldState: {}
          },
          acceptanceDateAnnotation: registrations[i].bemerkungAlternativesAufnahmedatum,
          acceptanceDateSuggestion: null,
          admissionDate: null,
          allRejected: registrations[i].priorities.every(allRejected)
        };
        if(registrations[i].aufnahmedatum !== null && registrations[i].aufnahmedatum.von !== null){
          tableRow.admissionDate = registrations[i].aufnahmedatum.von;
        }
        if(registrations[i].alternativesAufnahmedatum !== null && registrations[i].alternativesAufnahmedatum.von !== null){
          tableRow.acceptanceDateSuggestion = registrations[i].alternativesAufnahmedatum.von;
        }

        registrations[i].kindesdaten.forEach(child => {
          let age = null;
          if (child.geburtsdatum) {
              age = calculateAge(child.geburtsdatum, new Date);
          }
          let gender = "Kein Geschlecht angegeben";
          if(child.geschlecht !== null){
            gender = child.geschlecht;
          };
          tableRow.name.push({
            value: `${child.name} ${child.vorname}`,
            tooltip: gender,
            sortVal: child.name
          });
          let name = child.vorname.toUpperCase().trim();
          let lastName = child.name.toUpperCase().trim();
          let nameSplitted = name.split(" ");
          let lastNameSplitted = lastName.split(" ");

          names.push({
            id: names.length,
            registrationID: registrations[i].id,
            name: `${child.name} ${child.vorname} ${child.name}`,
            index: i,
            isParent: false,
            isChild: true,
            firstName: `${child.vorname}`,
            lastName: `${child.name}`,
            parentOf: null,
            age: age !== null ? `${age.year} Jahre alt` : null
          })
        })
        registrations[i].vertreter.forEach(x => {
            names.push({
              id: names.length,
              registrationID: registrations[i].id,
              name: `${x.name} ${x.vorname} ${x.name}`,
              index: i,
              isParent: true,
              isChild: false,
              firstName: `${x.vorname}`,
              lastName: `${x.name}`,
              parentOf: `${registrations[i].kindesdaten[0].vorname} ${registrations[i].kindesdaten[0].name}`,
              age: null
            })
        });

        tableRow.benachrichtigungsstatus = registrations[i].benachrichtigungsstatus;
        
        table.push(tableRow);
      }
      names.sort(compareLexicographical);
      for(let i = 0; i < names.length; ++i){
        names[i].id = i;
      }
      return {
        ...state,
        assigned: action.payload.response,
        tableProjection: table,
        tableFull: table,
        isFetching: false,
        names: names
      }
  },
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: true
      }
  },
  [CLEAR_STORE]: (state, action) => {
      return {
          ...initialState
      }
  },
  [FILTER]: (state, action) => {
      let status = action.payload.status;
      let einrichtung = action.payload.einrichtung;
      let assignmentStatus = action.payload.assignmentStatus;

      let projection = state.tableFull;

      if(einrichtung !== null){
        projection = projection.filter(x => x.zugeteilteEinrichtung.id === einrichtung);;
      }
      if(status !== null){
        projection = projection.filter(x => x.sendStatus.value === status);
      }
      if(assignmentStatus !== null){
        projection = projection.filter(x => x.status.value === assignmentStatus);
      }

      return {
          ...state,
          tableProjection: projection
      }
  },
  [sendBedarfsanmeldungEpic.START]: (state, action) => {
      return {
          ...state,
          isSending: true
      }
  },
  [sendBedarfsanmeldungEpic.SUCCESS]: (state, action) => {
      return {
          ...state,
          isSending: false,
          changed: []

      }
  },
  [sendBedarfsanmeldungEpic.FAIL]: (state, action) => {
      return {
          ...state,
          isSending: false
      }
  },
  [sendNotificationsEpic.START]: (state, action) => {
      return {
          ...state,
          isSending: true
      }
  },
  [sendNotificationsEpic.SUCCESS]: (state, action) => {
    let pdf = action.payload.response.pdf;
      return {
          ...state,
          isSending: false,
          pdf: pdf
      }
  },
  [sendNotificationsEpic.FAIL]: (state, action) => {
      return {
          ...state,
          isSending: false
      }
  },
  [RECLINE_REGISTRATION]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state.assigned));
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let changed = Array.from(state.changed);

      let index = state.assigned.findIndex(x => action.payload.id === x.id);
      if(index >= 0){
        let oldState = {
          zugeteilteEinrichtung: table[index].zugeteilteEinrichtung.id,
          zugeteilteEinrichtungName: table[index].zugeteilteEinrichtung.value,
          zugeteilteEinrichtungTooltip: table[index].zugeteilteEinrichtung.tooltip,
          status: table[index].status.value,
          statusTooltip: table[index].status.tooltip,
          bemerkung: newState[index].bemerkung
        };
        newState[index].zugeteilteEinrichtung = null;
        newState[index].status = "ZURUECKGESTELLT";
        newState[index].bemerkung = action.payload.refuseReason;
        table[index].zugeteilteEinrichtung.value = null;
        table[index].zugeteilteEinrichtung.id = null;
        table[index].zugeteilteEinrichtung.tooltip = 'Zugeteilt an: -';
        table[index].status.value = 'ZURUECKGESTELLT';
        table[index].status.tooltip = 'Status: ZURUECKGESTELLT';
        table[index].bemerkung = action.payload.refuseReason;
        table[index].reclineUndo.possible = true;
        table[index].reclineUndo.oldState = oldState;

        changed = changed.filter(x => x !== newState[index].id);
        changed.push(newState[index].id);

        let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
        if(i >= 0){
          projection[i].zugeteilteEinrichtung.value = null;
          projection[i].zugeteilteEinrichtung.id = null;
          projection[i].zugeteilteEinrichtung.tooltip = 'Zugeteilt an: -';
          projection[i].status.value = 'ZURUECKGESTELLT';
          projection[i].status.tooltip = 'Status: ZURUECKGESTELLT';
          projection[i].bemerkung = action.payload.refuseReason;
          projection[i].reclineUndo.possible = true;
          projection[i].reclineUndo.oldState = oldState;
          return {
            ...state,
            assigned: newState,
            tableFull: table,
            tableProjection: projection,
            changed: changed
          }
        }
      }
      return {
          ...state
      }
  },
  [RECLINE_UNDO]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = state.assigned.findIndex(x => action.payload.id === x.id);
    if(index >= 0){
      newState[index].zugeteilteEinrichtung = action.payload.oldState.zugeteilteEinrichtung;
      newState[index].status = action.payload.oldState.status;
      newState[index].bemerkung = action.payload.oldState.bemerkung;

      table[index].zugeteilteEinrichtung.value = action.payload.oldState.zugeteilteEinrichtungName;
      table[index].zugeteilteEinrichtung.id = action.payload.oldState.zugeteilteEinrichtung;
      table[index].zugeteilteEinrichtung.tooltip = action.payload.oldState.zugeteilteEinrichtungTooltip;
      table[index].status.value = action.payload.oldState.status;
      table[index].status.tooltip = action.payload.oldState.statusTooltip;
      table[index].bemerkung = action.payload.oldState.bemerkung;
      table[index].reclineUndo.possible = false;
      table[index].reclineUndo.oldState = {};

      changed = changed.filter(x => x !== newState[index].id);
      //changed.push(newState[index].id);

      let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
      if(i >= 0){
        projection[i].zugeteilteEinrichtung.value = action.payload.oldState.zugeteilteEinrichtungName;
        projection[i].zugeteilteEinrichtung.id = action.payload.oldState.zugeteilteEinrichtung;
        projection[i].zugeteilteEinrichtung.tooltip = action.payload.oldState.zugeteilteEinrichtungTooltip;
        projection[i].status.value = action.payload.oldState.status;
        projection[i].status.tooltip = action.payload.oldState.statusTooltip;
        projection[i].bemerkung = action.payload.oldState.bemerkung;
        projection[i].reclineUndo.possible = false;
        projection[i].reclineUndo.oldState = {};
        return {
          ...state,
          assigned: newState,
          tableFull: table,
          tableProjection: projection,
          changed: changed
        }
      }
    }
    return {
        ...state
    }
  },
  [SELECT_ALL]: (state, action) => {
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let selected = Array.from(state.selected);
      projection.forEach(item => {
        item.downloadSelected = action.payload.selectAll;
        let index = table.findIndex(x => x.id === item.id);
        if(index >= 0){
          table[index].downloadSelected = action.payload.selectAll;
          if(table[index].downloadSelected && !selected.includes(table[index].id)){
            selected.push(table[index].id);
          }
          if(!table[index].downloadSelected){
            selected = selected.filter(x => x !== table[index].id);
          }
        }
      })
      return {
        ...state,
        tableFull: table,
        tableProjection: projection,
        selected: selected
    };
  },
  [SELECT_ONE]: (state, action) => {
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let selected = Array.from(state.selected);
      let index = table.findIndex(x => x.id === action.payload.id);
      if(index >= 0){
        table[index].downloadSelected = !table[index].downloadSelected;
        let i = projection.findIndex(x => x.id === action.payload.id);
        if(i >= 0){
          projection[i].downloadSelected = !projection[i].downloadSelected;
          if(projection[i].downloadSelected){
            selected.push(projection[i].id);
          } else {
            selected = selected.filter(x => x !== projection[i].id);
          }
          return {
            ...state,
            tableFull: table,
            tableProjection: projection,
            selected: selected
          }
        }
      }
      return { ...state };
  },
  [fetchPDFEpic.START]: (state, action) => {
      return {
          ...state,
          pdfLoading: true,
      }
  },
  [fetchPDFEpic.FAIL]: (state, action) => {
      return {
          ...state,
          pdfLoading: false,
      }
  },
  [fetchPDFEpic.SUCCESS]: (state, action) => {
      return {
          ...state,
          isFetching: false,
          pdf: action.payload.response.pdf,
          pdfLoading: false
      }
  },
  [MESSAGE_MANUALLY]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = state.assigned.findIndex(x => action.payload.id === x.id);
    if(index >= 0){
      let oldState = {
        sendStatus: table[index].sendStatus.value,
        sendStatusTooltip: table[index].sendStatus.tooltip
      }
      newState[index].benachrichtigungsstatus = 'BENACHRICHTIGT';
      table[index].sendStatus.value = "BENACHRICHTIGT";
      table[index].sendStatus.tooltip = 'Benachrichtigungsstatus: BENACHRICHTIGT';
      table[index].manualNotificationUndo.possible = true;
      table[index].manualNotificationUndo.oldState = oldState;

      changed = changed.filter(x => x !== newState[index].id);
      changed.push(newState[index].id);

      let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
      if(i >= 0){
        projection[i].sendStatus.value = "BENACHRICHTIGT";
        projection[i].sendStatus.tooltip = 'Benachrichtigungsstatus: BENACHRICHTIGT';
        projection[i].manualNotificationUndo.possible = true;
        projection[i].manualNotificationUndo.oldState = oldState;
        return {
          ...state,
          assigned: newState,
          tableFull: table,
          tableProjection: projection,
          changed: changed
        }
      }
    }
    return {
        ...state
    }
  },
  [MESSAGE_MANUALLY_UNDO]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = state.assigned.findIndex(x => action.payload.id === x.id);
    if(index >= 0){
      newState[index].benachrichtigungsstatus = action.payload.oldState.sendStatus;
      table[index].sendStatus.value = action.payload.oldState.sendStatus;
      table[index].sendStatus.tooltip = action.payload.oldState.sendStatusTooltip;
      table[index].manualNotificationUndo.possible = false;
      table[index].manualNotificationUndo.oldState = {};

      changed = changed.filter(x => x !== newState[index].id);

      let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
      if(i >= 0){
        projection[i].sendStatus.value = action.payload.oldState.sendStatus;
        projection[i].sendStatus.tooltip = action.payload.oldState.sendStatusTooltip;
        projection[i].manualNotificationUndo.possible = false;
        projection[i].manualNotificationUndo.oldState = {};
        return {
          ...state,
          assigned: newState,
          tableFull: table,
          tableProjection: projection,
          changed: changed
        }
      }
    }
    return {
        ...state
    }
  },
  [RESET_PDF]: (state, action) => {
    return {
      ...state,
      pdf: null
    }
  },
  [ADD_DECISION]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = newState.findIndex(x => x.id === action.payload.id);
    if(index >= 0){
      newState[index].entscheidung = action.payload.reason;
      table[index].decision.value = action.payload.reason;
      let p = projection.findIndex(x => x.id === action.payload.id);
      if(p >= 0){
        projection[p].decision.value = action.payload.reason;
        changed = changed.filter(x => x !== newState[index].id);
        changed.push(newState[index].id);
        return {
          ...state,
          assigned: newState,
          tableFull: table,
          tableProjection: projection,
          changed: changed
        }
      }
    }
    return {
      ...state
    }
  },
  [SEARCH]: (state, action) => {
      return {
          ...state
      }
  },
  [RESET]: (state, action) => {
      let projection = state.tableFull.slice(0, state.tableFull.length)
      return {
          ...state,
          tableProjection: projection
      }
  },
  [SELECT_TO_MESSAGE]: (state, action) => {
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let messageSelected = Array.from(state.messageSelected);
      let index = table.findIndex(x => x.id === action.payload.id);
      if(index >= 0){
        table[index].messageSelected = !table[index].messageSelected;
        let i = projection.findIndex(x => x.id === action.payload.id);
        if(i >= 0){
          projection[i].messageSelected = !projection[i].messageSelected;
          if(projection[i].messageSelected){
            messageSelected.push(projection[i].id);
          } else {
            messageSelected = messageSelected.filter(x => x !== projection[i].id);
          }
          return {
            ...state,
            tableFull: table,
            tableProjection: projection,
            messageSelected: messageSelected
          }
        }
      }
      return { ...state };
  },
  [SELECT_ALL_MESSAGES]: (state, action) => {
      let table = JSON.parse(JSON.stringify(state.tableFull));
      let projection = JSON.parse(JSON.stringify(state.tableProjection));
      let messageSelected = Array.from(state.messageSelected);
      projection.forEach(item => {
        item.messageSelected = action.payload.selectAll;
        let index = table.findIndex(x => x.id === item.id);
        if(index >= 0){
          table[index].messageSelected = action.payload.selectAll;
          if(table[index].messageSelected && !messageSelected.includes(table[index].id)){
            messageSelected.push(table[index].id);
          }
          if(!table[index].messageSelected){
            messageSelected = messageSelected.filter(x => x !== table[index].id);
          }
        }
      })
      return {
        ...state,
        tableFull: table,
        tableProjection: projection,
        messageSelected: messageSelected
    };
  },
  [SAVE_DATE_ANNOTATION]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = state.assigned.findIndex(x => action.payload.id === x.id);
    if(index >= 0){

      newState[index].bemerkungAlternativesAufnahmedatum = action.payload.annotation;
      if(newState[index].alternativesAufnahmedatum === null){
        newState[index].alternativesAufnahmedatum = {};
      }
      newState[index].alternativesAufnahmedatum.von = action.payload.suggestionDate;
      newState[index].alternativesAufnahmedatum.bis = null;
      table[index].acceptanceDateAnnotation = action.payload.annotation;
      table[index].acceptanceDateSuggestion = action.payload.suggestionDate;

      changed = changed.filter(x => x !== newState[index].id);
      changed.push(newState[index].id);

      if(table.length === projection.length){
        projection[index].acceptanceDateAnnotation = action.payload.annotation;
        projection[index].acceptanceDateSuggestion = action.payload.suggestionDate;
      } else {
        let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
        if(i >= 0){
          projection[i].acceptanceDateAnnotation = action.payload.annotation;
          projection[i].acceptanceDateSuggestion = action.payload.suggestionDate;
        }
      }
      return {
        ...state,
        assigned: newState,
        tableFull: table,
        tableProjection: projection,
        changed: changed
      }
    }
    return {
        ...state
    }
  },
  [DELETE_DATE_ANNOTATION]: (state, action) => {
    let newState = JSON.parse(JSON.stringify(state.assigned));
    let table = JSON.parse(JSON.stringify(state.tableFull));
    let projection = JSON.parse(JSON.stringify(state.tableProjection));
    let changed = Array.from(state.changed);

    let index = state.assigned.findIndex(x => action.payload.id === x.id);
    if(index >= 0){

      newState[index].bemerkungAlternativesAufnahmedatum = null;
      newState[index].alternativesAufnahmedatum = null;
      table[index].acceptanceDateAnnotation = null;
      table[index].acceptanceDateSuggestion = null;

      changed = changed.filter(x => x !== newState[index].id);
      changed.push(newState[index].id);

      if(table.length === projection.length){
          projection[index].acceptanceDateAnnotation = null;
          projection[index].acceptanceDateSuggestion = null;
      } else {
        let i = state.tableProjection.findIndex(x => action.payload.id === x.id);
        if(i >= 0){
          projection[i].acceptanceDateAnnotation = null;
          projection[i].acceptanceDateSuggestion = null;
        }
      }
      return {
        ...state,
        assigned: newState,
        tableFull: table,
        tableProjection: projection,
        changed: changed
      }
    }
    return {
        ...state
    }
  },
  [SELECT_MULTIPLE_BY_INDEX]: (state, action) => {
    let tableProj = [];
    for(let i = 0; i < action.payload.indices.length; ++i){
      tableProj.push(state.tableFull[action.payload.indices[i]]);
    }
    return {
        ...state,
        tableProjection: tableProj
    }
  },
}

export const actions = {
    fetchAssigned,
    fetchEinrichtungen,
    clearStore,
    filter,
    sendBedarfsanmeldungen,
    reclineRegistration,
    reclineUndo,
    sendNotifications,
    fetchPDF,
    selectAll,
    selectOne,
    messageManually,
    messageManuallyUndo,
    resetPDF,
    addDecision,
    startSearch,
    resetTable,
    selectToMessage,
    selectAllMessages,
    saveDateAnnotation,
    deleteDateAnnotation,
    selectMultipleByIndex
};

export const epics = [
  fetchAssignedEpic.epic,
  fetchEinrichtungenEpic.epic,
  sendBedarfsanmeldungEpic.epic,
//  sendReloadEpic.epic,
  sendNotificationsReloadEpic.epic,
  sendNotificationsEpic.epic,
  fetchPDFEpic.epic
]

const initialState = {
    assigned: [],
    einrichtungen: [],
    tableFull: [],
    tableProjection: [],
    isFetching: false,
    fetched: false,
    isSending: false,
    pdf: null,
    changed: [],
    selected: [],
    pdfLoading: false,
    messageSelected: [],
    names: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
