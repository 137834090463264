<template>
<div>
  <AddKitaDialog ref="addKitaDialog" @kitaSelected="changeKita">
  </AddKitaDialog>
  <v-list-item @click="assignRegistration" :disabled="tileProps.disabled">
    <v-list-item-action v-if="tileProps.iconName !== 'block'">
        <v-icon color="primary" medium>
            {{ tileProps.iconName }}
        </v-icon>
    </v-list-item-action>
    <v-list-item-action v-else>
      <Button icon @click.stop="openAddKitaDialog" color="primary">
        <v-icon medium>
            search
        </v-icon>
      </Button>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
          {{ tileProps.headerText }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ tileProps.subheaderText }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <Button icon ripple @click.stop="emitOpenExplanation(`Nicht priorisierte Einrichtung`, `Es
        ist möglich die Zuteilung an eine, von den Vertretern nicht priorisierte Einrichtung,
        durchzuführen. Bitte beachten Sie, dass es aufgrund der Nicht-Priorisierung
         zur Ablehnung der Vertreter kommen könnte.`)">
        <v-icon color="grey darken-1">
            info
        </v-icon>
      </Button>
    </v-list-item-action>
  </v-list-item>
</div>
</template>
<script>
import AddKitaDialog from '../dialogs/AddKitaDialog'
import Button from '@s/views/general_components/components/Button'

/**
 * The additional kita component is able to assign registrations to non prioritised facilities.
 * In the menu and list component only the facilities the parents prioritised
 * get displayed (registration.priorities). But for an user of role KV it should
 * be possible assigning a registration to a non prioritised facility.
 */
export default {
    name: "additional-kita-component",
    data() {
        return {
          selected: null,
          addKitaDialog: null,
          icon: 'home',
          header: 'Andere KITA',
          subheader: 'Einer Kita zuteilen, die nicht von den Eltern priorisiert wurde.',
          subsub : '',
          showButton: true,
        }

    },
    props: {
    /**
     * All facilities of a user.
     */
      einrichtung: {
        type: Object,
        required: false
      },
      /**
       * The properties of a list tile.
       */
      tileProps: {
        type: Object,
        default: () => {
          return {
            iconName: '',
            iconColor: '',
            backgroundColor: '',
            headerText: '',
            subheaderText: '',
            disabled: false,
            prio: {
              type: Number,
              required: false
            }
          }
        }
      },
    /**
     * The priorities of a registration.
     */
      priorities: {
        type: Array,
        required: false
      }
    },
    methods: {
      openAddKitaDialog(){
        this.$refs.addKitaDialog.open(this.priorities);
      },
      changeKita(einrichtung){
        this.showButton = false;
        this.$emit('assignRegistration', einrichtung, true);
      },
      assignRegistration(){
        if(this.einrichtung !== null){
        /**
          * Triggers when assign registration was clicked.
          *
          * @property {object} facility The title the explanation dialog is supposed to have.
          * @property {boolean} noPrio True if the facility was non prioritised.
          */
          this.$emit('assignRegistration', this.einrichtung, true);
        } else {
          this.openAddKitaDialog();
        }
      },
      emitOpenExplanation(title, text){
      /**
        * Triggers when the explanation icon on the menu was clicked
        *
        * @property {string} title The title the explanation dialog is supposed to have.
        * @property {string} explanation The explanation to display.
        */
        this.$emit('emitExplanation', title, text);
      }
    },
    components: {
      AddKitaDialog,
      Button
    }
}
</script>
