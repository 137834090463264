import {
  noop,
  compareLexicographical
        } from '../../util/helper'
import {
  GETepicFactory,
  POSTepicFactory,
  DELETEepicFactory,
  PUTepicFactory,
  mergeEpicFactory
} from '../../util/epicFactories';
import einrichtungFields from './einrichtungFields';

const prefix = `ADMIN`;

export const FETCH_EINRICHTUNGEN = `${prefix}/FETCH_EINRICHTUNGEN`;
export const FETCH_EINRICHTUNG = `${prefix}/FETCH_EINRICHTUNG`;
export const DELETE_EINRICHTUNG = `${prefix}/DELETE_EINRICHTUNG`;
export const RESET_STORE = `${prefix}/RESET_STORE`;
export const CREATE_NEW_EINRICHTUNG = `${prefix}/CREATE_NEW_EINRICHTUNG`;
export const CHANGE_EINRICHTUNG = `${prefix}/CHANGE_EINRICHTUNG`;

export const fetchEinrichtungenEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNGEN`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/names`
})
export const sendNewEinrichtungEpic = POSTepicFactory(`${prefix}/SEND_NEW_EINRICHTUNG`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen`,
    body: (action) => action.payload
}, () => true, 201)
export const fetchEinrichtungEpic = GETepicFactory(`${prefix}/FETCH_EINRICHTUNG`, {
    url: (action) => `/api/v1/kita/einrichtungen/${action.payload.id}`
})
export const deleteEinrichtungEpic = DELETEepicFactory(`${prefix}/DELETE_EINRICHTUNG`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen/${action.payload.id}`
})
export const changeEinrichtungEpic = PUTepicFactory(`${prefix}/CHANGE_EINRICHTUNG`, {
    url: (action) => `/api/v1/kita/admin/einrichtungen`,
    body: (action) => action.payload
});

export const fetchEinrichtungen = (success = noop, error = noop) => fetchEinrichtungenEpic.run({},{success, error});
export const sendNewEinrichtung = (einrichtung, headerKey, headerVal, success = noop, error = noop) => sendNewEinrichtungEpic.run(
  {
    payload: einrichtung,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const deleteEinrichtung = (id, headerKey, headerVal, success = noop, error = noop) => deleteEinrichtungEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const fetchEinrichtung = (id, success = noop, error = noop) => fetchEinrichtungEpic.run({id: id}, {success, error});
export const changeEinrichtung = (einrichtung, headerKey, headerVal, success = noop, error = noop) => changeEinrichtungEpic.run(
  {
    payload: einrichtung,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});
export const resetStore = () => ({type: RESET_STORE});
export const createNewEinrichtung = () => ({type: CREATE_NEW_EINRICHTUNG});

const sendReloadEpic = mergeEpicFactory(sendNewEinrichtungEpic.SUCCESS, () => fetchEinrichtungenEpic.run())
const changeReloadEpic = mergeEpicFactory(changeEinrichtungEpic.SUCCESS, () => fetchEinrichtungenEpic.run())
const deleteReloadEpic = mergeEpicFactory(deleteEinrichtungEpic.SUCCESS, () => fetchEinrichtungenEpic.run())

const ACTION_HANDLERS = {
  ...sendNewEinrichtungEpic.ACTION_HANDLERS,
  ...fetchEinrichtungenEpic.ACTION_HANDLERS,
  [fetchEinrichtungenEpic.SUCCESS]: (state, action) => {
      action.payload.response.sort(compareLexicographical);
      return {
        ...state,
        einrichtungen: action.payload.response,
        isFetching: false
      }
  },
  ...fetchEinrichtungEpic.ACTION_HANDLERS,
  [fetchEinrichtungEpic.SUCCESS]: (state, action) => {

    // Replace null with object accordingly
    Object.keys(einrichtungFields).forEach(key => {
      if(typeof einrichtungFields[key] === 'object' && action.payload.response[key] === null){
        action.payload.response[key] = einrichtungFields[key];
      } else {
        if(key === "betreuungszeit"){
          Object.keys(einrichtungFields.betreuungszeit).forEach(day => {
            if(action.payload.response.betreuungszeit[day] === null){
              action.payload.response.betreuungszeit[day] = einrichtungFields.betreuungszeit[day];
            }
          })
        }
      }
    })

    // Fix einrichtungsart capacity bug introduced in v1.6.4 
    if(action.payload.response.plaetzeProEinrichtungsart 
      && action.payload.response.plaetzeProEinrichtungsart !== null
      && action.payload.response.plaetzeProEinrichtungsart.length > 0) {
        action.payload.response.plaetzeProEinrichtungsart = action.payload.response.plaetzeProEinrichtungsart.filter(x => x.einrichtungsart !== null);
      }
      return {
        ...state,
        einrichtung: action.payload.response,
        fetched: true,
        isFetching: false
      }
  },
  [RESET_STORE]: (state, action) => {
      return {
        ...initialState
      }
  },
  [CREATE_NEW_EINRICHTUNG]: (state, action) => {
      return {
        ...state,
        einrichtung: einrichtungFields,
        fetched: true
      }
  },
  ...deleteEinrichtungEpic.ACTION_HANDLERS,
  ...changeEinrichtungEpic.ACTION_HANDLERS,
  [changeEinrichtungEpic.SUCCESS]: (state, action) => {
      return {
        ...state,
        fetched: false
      }
  }
}

export const actions = {
    sendNewEinrichtung,
    fetchEinrichtungen,
    fetchEinrichtung,
    resetStore,
    createNewEinrichtung,
    deleteEinrichtung,
    changeEinrichtung
};

export const epics = [
  sendNewEinrichtungEpic.epic,
  fetchEinrichtungenEpic.epic,
  fetchEinrichtungEpic.epic,
  deleteEinrichtungEpic.epic,
  changeEinrichtungEpic.epic,
  sendReloadEpic.epic,
  changeReloadEpic.epic,
  deleteReloadEpic.epic]

const initialState = {
    einrichtungen: [],
    einrichtung: {},
    isFetching: false,
    fetched: false,
    isDeleting: false,
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
