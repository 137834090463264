import {noop} from '../../util/helper'
import {
  POSTepicFactory,
  PUTepicFactory,
  DELETEepicFactory,
  GETepicFactory,
  mergeEpicFactory
} from '../../util/epicFactories';

const prefix = `MANDANTPARAMETER`;

export const FETCH_MANDANTPARAMETER = `${prefix}/FETCH_MANDANTPARAMETER`;
export const SEND_MANDANTPARAMETER = `${prefix}/SEND_MANDANTPARAMETER`;
export const CHANGE_MANDANTPARAMETER = `${prefix}/CHANGE_MANDANTPARAMETER`;
export const DELETE_MANDANTPARAMETER = `${prefix}/DELETE_MANDANTPARAMETER`;
export const CLEAR_MANDANTPARAMETER = `${prefix}/CLEAR_MANDANTPARAMETER`;

export const fetchMandantparameterEpic = GETepicFactory(`${prefix}/FETCH_MANDANTPARAMETER`, {
    url: (action) => `/api/v1/kita/mandantparameter?page=0&size=2147483647&ags=99101000`
})

export const sendMandantparameterEpic = POSTepicFactory(`${prefix}/SEND_MANDANTPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/mandantparameter`,
    body: (action) => action.payload
}, () => true, 201)

export const changeMandantparameterEpic = PUTepicFactory(`${prefix}/CHANGE_MANDANTPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/mandantparameter`,
    body: (action) => action.payload
});

export const deleteMandantparameterEpic = DELETEepicFactory(`${prefix}/DELETE_MANDANTPARAMETER`, {
    url: (action) => `/api/v1/kita/admin/mandantparameter/${action.payload.id}`
})

export const fetchMandantparameter = (success = noop, error = noop) => fetchMandantparameterEpic.run({}, {success, error});

export const sendMandantparameter = (mandantparameter, headerKey, headerVal, success = noop, error = noop) => sendMandantparameterEpic.run(
  {
    payload: mandantparameter,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const changeMandantparameter = (mandantparameter, headerKey, headerVal, success = noop, error = noop) => changeMandantparameterEpic.run(
  {
    payload: mandantparameter,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const deleteMandantparameter = (id, headerKey, headerVal, success = noop, error = noop) => deleteMandantparameterEpic.run(
  {
    id: id,
    headerKey: headerKey,
    headerVal: headerVal
  }, {success, error});

export const clearMandantparameter = () => ({
    type: CLEAR_MANDANTPARAMETER
});

const sendReloadEpic = mergeEpicFactory(sendMandantparameterEpic.SUCCESS, () => fetchMandantparameterEpic.run())
const changeReloadEpic = mergeEpicFactory(changeMandantparameterEpic.SUCCESS, () => fetchMandantparameterEpic.run())
const deleteReloadEpic = mergeEpicFactory(deleteMandantparameterEpic.SUCCESS, () => fetchMandantparameterEpic.run())

const ACTION_HANDLERS = {
  ...fetchMandantparameterEpic.ACTION_HANDLERS,
  [fetchMandantparameterEpic.SUCCESS]: (state, action) => {
    action.payload.response.forEach(item => {
      if(item.ansprechpartner === null){
        item.ansprechpartner = {
          name: null,
          telefon: null,
          fax: null,
          email: null
        }
      }
    })
      return {
          ...state,
          mandantparameter: action.payload.response,
          isFetching: false
      }
  },
  [CLEAR_MANDANTPARAMETER]: (state, action) => {
      return {
          ...initialState
      }
  },
  ...sendMandantparameterEpic.ACTION_HANDLERS,
  ...changeMandantparameterEpic.ACTION_HANDLERS,
  ...deleteMandantparameterEpic.ACTION_HANDLERS,

}

export const actions = {
    fetchMandantparameter,
    sendMandantparameter,
    changeMandantparameter,
    deleteMandantparameter,
    clearMandantparameter
};

export const epics = [
  fetchMandantparameterEpic.epic,
  sendMandantparameterEpic.epic,
  changeMandantparameterEpic.epic,
  deleteMandantparameterEpic.epic,
  sendReloadEpic.epic,
  changeReloadEpic.epic,
  deleteReloadEpic.epic
]

const initialState = {
  isFetching: false,
  mandantparameter: []
};

export function reducer(state = initialState, action) {
    const handler = ACTION_HANDLERS[action.type];
    return handler ? handler(state, action) : state;
}

export default reducer;
